import GradsContainer from "./style";

import {
  SpaceSmall,
  PreHeading,
  Heading,
  SpaceMedium,
  CardParagraph,
  CustomButton,
} from "../../styles/StyledComponents";

import { ourGrads, HomeImages } from "../../constants";

const OurGrads = () => {
  return (
    <>
      <GradsContainer>
        <PreHeading>BOOTCAMP GRADS</PreHeading>
        <SpaceSmall />
        <Heading>
          Meet Some of Our <br />
          Bootcamp <span>Grads.</span>
        </Heading>
        <SpaceSmall className="mobile-space" />
        <div className="grads-list">
          {ourGrads.map((item) => {
            return (
              <>
                <div className="grad-card" key={item.id}>
                  <PreHeading>{item.bootcamp}</PreHeading>
                  <iframe
                    width="100%"
                    height="315"
                    src={item.youtube}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>

                  <Heading fontSize={"16px"} className="grad-heading">
                    {item.name}
                  </Heading>
                  <CardParagraph>{item.story}</CardParagraph>
                  <a href={item.path}>
                    <CustomButton className="button-arrow grad-button">
                      Watch {item.name.split(" ")[0]}'s story{" "}
                      <img
                        src={HomeImages.gradArrow}
                        className="grad-arrow"
                        alt="arrow"
                      />
                    </CustomButton>
                  </a>
                </div>
              </>
            );
          })}
        </div>

        <SpaceSmall />
        <div className="grads-button-container">
          <CustomButton className="more-button">
            <a href="https://youtube.com/playlist?list=PLKce0dI322aqUb6K-Q9-u3e8KWnl2Z7j4">
              <div className="button-arrow ">
                Watch more stories
                <img
                  src={HomeImages.whiteArrow}
                  className="white-arrow"
                  alt="arrow"
                />
              </div>
            </a>
          </CustomButton>
        </div>
        <SpaceMedium className="mobile-space" />
      </GradsContainer>
    </>
  );
};

export default OurGrads;
