import { JoinContainer } from "./style";
import { Spin } from "antd";
import React from "react";
import {
  CardParagraph,
  CustomButton,
  Heading,
  PreHeading,
  SpaceSmall,
  TextareaField,
} from "../../styles/StyledComponents";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import joinService from "../../service/joinNiyo.service";

const JoinFamily = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [reasonForJoining, setReasonForJoining] = useState("");

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = {
        fullName,
        email,
        jobRole,
        companyName,
        country,
        reasonForJoining,
      };
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzjtKsp8pIgbL6vnnx7x2vIQ_V_en4SeP2VZtNNex12JjTm61hfdrybF1MV6mUb78SkEQ/exec",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      setIsLoading(false);
      setSuccess(true);

      toast.success("Success", {
        position: toast.POSITION.TOP_CENTER,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return response.ok;
    } catch (error) {
      setIsLoading(false);
      setSuccess(false);
      console.log(error, "ee")

      toast.error(
        "An error occurred while processing your request, please make sure to fill all fields correctly and try again.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };
  return (
    <>
      <JoinContainer>
        {!success && (
          <>
            <ToastContainer />
            <SpaceSmall />

            <SpaceSmall />
            <form className="join-form" onSubmit={handleForm}>
              <Heading className="join-title">
                Join Niyo <span>Family</span>
              </Heading>
              <CardParagraph className="join-text" fontSize="18px">
                Be the first to know about all things at Niyo Group
              </CardParagraph>
              <SpaceSmall />
              <div className="form-double">
                <div>
                  <input
                    className="join-email join-button"
                    type="text"
                    placeholder="Name"
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <input
                    className="join-email join-button"
                    type="text"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-double">
                <div>
                  <input
                    className="join-email join-button"
                    type="text"
                    placeholder="Job Role"
                    value={jobRole}
                    onChange={(e) => {
                      setJobRole(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <input
                    className="join-email join-button"
                    type="text"
                    placeholder="Company name"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-double">
                <div>
                  <input
                    className="join-email join-button"
                    type="text"
                    placeholder="What country are you based in?"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <TextareaField
                    className="join-email join-button"
                    type="text"
                    placeholder="Why would you like to join Niyo?"
                    value={reasonForJoining}
                    onChange={(e) => {
                      setReasonForJoining(e.target.value);
                    }}
                  ></TextareaField>
                </div>
              </div>
              <br />
              <div className="">
                <CustomButton
                  className="join-submit "
                  type="submit"
                  value="Sign Up"
                  height="50px"
                >
                  {isLoading ? <Spin spinning /> : "Join Niyo Family"}
                </CustomButton>
              </div>
              <SpaceSmall />
            </form>
          </>
        )}
        {success && (
          <>
            <div className="wrapper">
              <Heading className="join-title" fontSize="40px">
                Welcome to Niyo
              </Heading>
              <div
                className="body"
                style={{
                  border: "1px solid #999",
                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <div>
                  <div className="image">
                    <FaWhatsapp size="100px" />
                  </div>
                  <div>
                    <PreHeading className="join-text" fontSize="18px">
                      WhatsApp Community
                    </PreHeading>
                    <CardParagraph className="join-text" fontSize="18px">
                      Join our WhatsApp Community to meet our community of
                      techies and professionals.
                    </CardParagraph>
                  </div>
                </div>
                <SpaceSmall />
                <a href="https://chat.whatsapp.com/BikOghd2Wi490bHs3J8ENq">
                  <CustomButton>Join Now</CustomButton>
                </a>
              </div>
            </div>
          </>
        )}
      </JoinContainer>
    </>
  );
};

export default JoinFamily;
