import styled from "styled-components";
import { HomeImages } from "../../constants";
import breakpointSizes from "../../constants/breakpointSizes";

export const HomeContainer = styled.div`
  background-color: #fffcf4;

  .button-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    .white-arrow {
      width: 20px;
      margin: 0 0 0 4%;
    }
  }

  .ant-modal-content {
    padding: none !important;
  }

  .hero-container {
    min-height: 100vh;
    display: flex;
    flex-direction: row;

    .hero-left {
      width: 55%;
      height: auto;
      margin: 0 2% 0 0;
      padding: 5% 0 0 0;
      justify-content: center;
      .hero-heading {
        color: #000 !important;
        span {
          color: #000 !important;
        }
      }
      .slide {
        .word-ani {
          .word {
            color: #fbaf00 !important;
          }
        }
      }

      .hero-paragraph {
        padding: 4% 0 0 0;
      }

      .button-1 {
        margin: 0 4% 0 0;
      }

      .button-2 {
        background: #fff;
        border: 1px solid #fbaf00;
        border-radius: 0;
        color: #fbaf00;
        height: 56px;
        width: 204px;
        font-family: "Regular";
        font-size: "16px";
      }
    }

    .hero-right {
      width: 45%;
      height: auto;
      padding: 5% 0 0 0;
      display: flex;
      flex-direction: row;

      .image-col-1 {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: auto;
        margin: 0 0 0 10%;
      }

      .image-col-2 {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: auto;
        margin: 14% 0 0 0;
      }

      .hero-image {
        width: 90%;
        height: 240px;
        margin: 6% 0;
        display: inline-block;
        position: relative;

        .hero-tag {
          width: 120px;
          height: 50px;
          border-radius: 20px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          font-family: Medium;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #070909;
          background-color: #fff;
        }

        .tag-1 {
          position: absolute;
          top: 14%;
          left: -24%;
        }

        .tag-2 {
          position: absolute;
          top: 30%;
          right: -24%;
        }

        .tag-3 {
          position: absolute;
          bottom: 14%;
          left: -24%;
        }

        .tag-4 {
          position: absolute;
          bottom: 14%;
          right: -24%;
        }
      }

      .image-left {
        border-radius: 80px 0px 80px;
      }

      .image-right {
        border-radius: 0px 80px 0px;
      }

      .image-1 {
        background-image: url(${HomeImages.hero1});
        background-size: cover;
      }

      .image-2 {
        background-image: url(${HomeImages.hero2});
        background-size: cover;
      }

      .image-3 {
        background-image: url(${HomeImages.hero3});
        background-size: cover;
      }

      .image-4 {
        background-image: url(${HomeImages.hero4});
        background-size: cover;
      }
    }
  }

  .mission-section {
    text-align: center;
    background-color: #fffcf4;

    .mission-heading {
      padding: 1% 0 0 0;
    }
  }

  .partners-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fffcf4;

    .partners-text {
      padding: 1% 0 0 0;
    }

    .partners-video {
      width: 850px;
      height: 400px;
      border-radius: 15px;
      align-self: center;
    }

    .logo-container {
      padding: 1% 0 0 0;

      .logo-individual {
        width: 160px;
        padding: 10%;
      }
    }
  }

  .start-career {
    background-color: #fffcf4;

    .career-text-blocks {
      display: flex;

      .career-left {
        width: 55%;
        padding: 0;
        margin: 0 2% 0 0;
      }

      .career-right {
        width: 45%;
        padding: 0;
      }
    }
  }

  .bootcamps-section {
    background-color: #fffcf4;

    .bootcamp-card-section {
      padding: 0;

      .bootcamps-carousel {
        padding: 0 1% 10% 1%;

        .bootcamp-card {
          width: 90%;
          height: 500px;
          position: relative;
          box-shadow: 0px 30px 40px rgba(251, 175, 0, 0.04);

          .bootcamp-image {
            width: 100%;
            margin: 0;
            padding: 0;
          }

          .bootcamp-text {
            padding: 4% 6%;
          }

          .bootcamp-button {
            font-size: 14px;
            margin: 0;
            width: 180px;
            text-align: left;
            border: 1px solid;
            bottom: 30px;
            position: absolute;
          }
        }

        .react-multiple-carousel__arrow--left {
          left: 85%;
          bottom: 20px;
          background-color: #fbaf00;
          z-index: 1;
        }

        .react-multiple-carousel__arrow--right {
          bottom: 20px;
          background-color: #ffedc2;
          color: #ffe099;
          z-index: 1;
        }
      }
    }

    .bootcamps-list-container {
      display: none;
    }
  }

  .grads-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    .grad-card {
      margin: 0;
      width: 32%;
      height: auto;
      padding: 0;

      .grad-image {
        width: 100%;
        padding: 4% 0 0 0;
      }

      .grad-button {
        background-color: #fffcf4;
        color: #fbaf00;
        text-align: left;
        font-size: 14px;
        width: 100%;
        justify-content: left;
        padding: 5% 0 0 0;
        margin: 0;
        font-family: bold;

        .grad-arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fbaf00;
          width: 20px;
          margin: 0 0 0 2%;
        }
      }
    }
  }

  .grads-button-container {
    display: flex;
    justify-content: center;
  }

  .image-container {
    background-image: url(${HomeImages.homeBackground1});
    background-size: cover;
    height: 600px;
  }

  .why-niyo {
    background-color: #f4f4f4;

    .support-blocks {
      display: flex;
      align-content: flex-start;
      gap: 4%;
      flex-wrap: wrap;
      padding: 0;

      .support-card {
        width: 30%;
        padding: 0 0 6% 0;

        .support-logo {
          width: 15%;
          padding: 0 0 4% 0;
        }

        .card-heading {
          padding: 0 0 4% 0;
        }
      }
    }
  }

  @media ${breakpointSizes.mobile} {
    .mobile-space {
      height: 40px;
    }

    .hidden-space {
      display: none;
    }

    .hero-container {
      flex-direction: column;

      .hero-left {
        width: 100%;
        height: auto;

        br {
          display: none;
        }

        .button-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .button-1,
          .button-2 {
            margin: 0 0 8% 0%;
            width: 100%;
            font-family: "Regular";
            font-size: "16px";
          }
        }
      }

      .hero-right {
        display: none;
      }
    }

    .mission-section {
      text-align: left;
      br {
        display: none;
      }
    }

    .partners-section {
      display: flex;
      align-items: flex-start;

      br {
        display: none;
      }

      .partners-video {
        width: 100%;
        height: 220px;
        iframe {
          height: 220px;
        }
      }

      .button-container {
        align-items: flex-start;

        .partner-button {
          align-self: flex-start;
          justify-content: left;
          align-items: left;
        }
      }

      .logo-container {
        .logo-individual {
          width: 120px;
          padding: 4% 10%;
        }
      }
    }

    .start-career {
      br {
        display: none;
      }

      .career-text-blocks {
        flex-direction: column;

        .career-left {
          width: 100%;
          margin: 0 0 10% 0;
        }

        .career-right {
          width: 100%;

          .career-button {
            width: 100%;
          }
        }
      }
    }

    .bootcamps-section {
      .bootcamp-card-section {
        display: none;
      }

      .bootcamps-list-container {
        display: block;
        padding: 0;

        .bootcamp-card {
          margin: 0 0 10% 0;
          width: 100%;

          .bootcamp-image {
            width: 100%;
            margin: 0;
            padding: 0;
          }

          .bootcamp-title {
            font-size: 14px;
            line-height: 26px;
          }

          .bootcamp-text {
            padding: 0 4%;

            .bootcamp-ready {
              display: none;
            }
          }

          .bootcamp-button {
            border: 1px solid;
          }
        }
      }
    }

    .grads-section {
      .grads-list {
        flex-direction: column;

        .grad-card {
          width: 100%;
          padding: 0 0 20% 0;

          .grad-heading {
            font-size: 20px;
            line-height: 26px;
            padding: 4% 0;
          }
        }

        .grad-card:last-child {
          padding: 0;
        }
      }
    }

    .image-container {
      height: 500px;
      background-position: center;
    }

    .why-niyo {
      .support-blocks {
        padding: 16% 0 0 0;
        flex-direction: column;

        .support-card {
          width: 100%;
          padding: 0 4% 20% 4%;

          .card-heading {
            font-size: 15px;
            line-height: 22px;
          }

          .card-text {
            font-size: 13px;
            line-height: 22px;
          }
        }
      }
    }

    .ready-section {
    }
  }

  @media ${breakpointSizes.desktop} {
    .hero-container {
      height: 100vh;

      .hero-left {
        width: 50%;
        height: 100%;
        padding: 1% 0;

        .hero-heading {
          width: 80%;
        }

        .hero-paragraph {
          width: 70%;
        }

        .button-1 {
          margin: 0 5% 0 0%;
        }
      }

      .hero-right {
        width: 50%;
        height: 100%;

        .image-col-1 {
          margin: 0 0 0 4%;
          width: 40%;
        }

        .image-col-2 {
          margin: 5% 0 0 0;
        }

        .hero-image {
          height: 300px;
          width: 260px;
        }
      }
    }

    .mission-section {
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .partners-section {
      .partners-video {
        width: 100%;
        height: auto;
        .video {
          height: 900px;
        }
      }
    }

    .start-career {
      .career-text-blocks {
        .career-left {
          width: 50%;
        }

        .career-right {
          width: 50%;
          padding: 0 0 0 5%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .career-paragraph {
            width: 95%;

            br {
              display: none;
            }
          }
        }
      }
    }

    .bootcamps-section {
      .bootcamp-card-section {
        .bootcamps-carousel {
          padding: 0 1% 5% 1%;
          justify-content: center;

          .react-multi-carousel-item.react-multi-carousel-item--active {
            width: 200px;
          }

          .bootcamp-card {
            width: 70%;
            padding: 0;
            margin: 0;
          }

          .react-multiple-carousel__arrow--left {
            left: 88%;
            bottom: 10px;
          }

          .react-multiple-carousel__arrow--right {
            bottom: 10px;
          }
        }
      }

      .bootcamps-list-container {
        display: none;
      }
    }

    .image-container {
      height: 750px;
    }

    .why-niyo {
      .bespoke-title {
        width: 35%;

        br {
          display: none;
        }
      }

      .support-blocks {
        .support-card {
          width: 22%;
          padding: 0 0 3% 0;
          
        }
       
      }
    }
  }
`;

export default HomeContainer;
