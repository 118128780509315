import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { SoftwareTestingInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const SoftwareTestingCourse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let { id } = useParams();
  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={SoftwareTestingInfo.courseTitle}
        schedule={SoftwareTestingInfo.schedule}
        courseRating={SoftwareTestingInfo.courseRating}
        applicationLink={SoftwareTestingInfo.applicationLink}
        headingDescription={SoftwareTestingInfo.headingDescription}
        heroImage={SoftwareTestingInfo.heroImage}
        cardTitle={SoftwareTestingInfo.cardTitle}
        overviewHeading={SoftwareTestingInfo.overviewHeading}
        overviewText={SoftwareTestingInfo.overviewText}
        overviewVideo={SoftwareTestingInfo.overviewVideo}
        syllabusText={SoftwareTestingInfo.syllabusText}
        syllabusLessons={SoftwareTestingInfo.syllabusLessons}
        benefits={SoftwareTestingInfo.benefits}
        postBenefits={SoftwareTestingInfo.postBenefits}
        eventsText={SoftwareTestingInfo.eventsText}
        eventsList={SoftwareTestingInfo.eventsList}
        requirementsText={SoftwareTestingInfo.requirementsText}
        requirementsList={SoftwareTestingInfo.requirementsList}
        tutors={SoftwareTestingInfo.tutors}
        tutorsParagraph={SoftwareTestingInfo.tutorsParagraph}
      />
    </>
  );
};

export default SoftwareTestingCourse;
