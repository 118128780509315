import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { FoundhersInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from 'react-router-dom'

const FoundhersCourse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let { id } = useParams()
  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={FoundhersInfo.courseTitle}
        schedule={FoundhersInfo.schedule}
        courseRating={FoundhersInfo.courseRating}
        applicationLink={FoundhersInfo.applicationLink}
        headingDescription={FoundhersInfo.headingDescription}
        heroImage={FoundhersInfo.heroImage}
        cardTitle={FoundhersInfo.cardTitle}
        overviewHeading={FoundhersInfo.overviewHeading}
        overviewText={FoundhersInfo.overviewText}
        overviewVideo={FoundhersInfo.overviewVideo}
        syllabusText={FoundhersInfo.syllabusText}
        syllabusLessons={FoundhersInfo.syllabusLessons}
        benefits={FoundhersInfo.benefits}
        eventsText={FoundhersInfo.eventsText}
        eventsList={FoundhersInfo.eventsList}
        requirementsText={FoundhersInfo.requirementsText}
        requirementsList={FoundhersInfo.requirementsList}
        tutorsParagraph={FoundhersInfo.tutorsParagraph}
        tutors={FoundhersInfo.tutors}
        tutorsList={FoundhersInfo.tutorsList}
      />
    </>
  );
};

export default FoundhersCourse;
