import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { VRInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const VRCourse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let { id } = useParams();

  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={VRInfo.courseTitle}
        schedule={VRInfo.schedule}
        courseRating={VRInfo.courseRating}
        applicationLink={VRInfo.applicationLink}
        headingDescription={VRInfo.headingDescription}
        heroImage={VRInfo.heroImage}
        cardTitle={VRInfo.cardTitle}
        overviewHeading={VRInfo.overviewHeading}
        overviewText={VRInfo.overviewText}
        overviewVideo={VRInfo.overviewVideo}
        syllabusText={VRInfo.syllabusText}
        syllabusLessons={VRInfo.syllabusLessons}
        benefits={VRInfo.benefits}
        eventsText={VRInfo.eventsText}
        eventsList={VRInfo.eventsList}
        requirementsText={VRInfo.requirementsText}
        requirementsList={VRInfo.requirementsList}
        tutors={VRInfo.tutors}
        tutorsParagraph={VRInfo.tutorsParagraph}
      />
    </>
  );
};

export default VRCourse;
