import { http } from "../utils/http";

class BootcampService {
  constructor(http) {
    this.request = http;
  }

  async getAllBootcamps(
    { limit = 3, cat, field='category,metaData' }
  ) {
    return await this.request.get(`bootcamp?category=${cat}&fields=${field}&limit=${limit}`);
  }

  async getSinglePartners({ id }) {
    return await this.request.get(`bootcamp/fetch?title=${id}`);
  }

  async getUsersDetails({ regCode }) {
    return await this.request.get(`bootcamp/registration/eligibility/${regCode}`);
  }
}

export default new BootcampService(http);
