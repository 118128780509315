import OurGrads from "../../../components/Grads";
import Upskilling from "../../../components/Upskilling";
import FAQS from "../../../components/FAQS";
import {
  Web2BootcampsIndividual,
  Web2BootcampsComingSoon,
  BootcampFAQS,
} from "../../../constants";

import {
  HeroContainer,
  PreHeading,
  SpaceSmall,
  SpaceMedium,
  Heading,
  Paragraph,
  Container,
  CardParagraph,
  CustomButton,
} from "../../../styles/StyledComponents";

import Web2Container from "./style";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Web2Page = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);


  return (
    <>
      <Web2Container>
        <HeroContainer height={"auto"} className="hero-container">
          <div className="hero-left">
            <PreHeading className="hero-preheading">TECHNICAL BOOTCAMPS</PreHeading>
            <SpaceSmall />
            <Heading className="hero-heading">
              <span>Explore</span> our Bootcamps to see the various courses.
            </Heading>
            <SpaceSmall />
            <Paragraph>
              Are you looking for a pathway into having a career in tech, or a
              high impact industry? Learn with a growing community of 
              people in tech, passionate about changing the narrative too!
            </Paragraph>
          </div>
          <div className="hero-right">
            <div className="hero-image"></div>
          </div>
        </HeroContainer>

        <Container className="cards-section">
          <PreHeading className="preheading">TECHNOLOGY BOOTCAMP</PreHeading>
          <SpaceSmall className="mobile-hidden" />
          <Heading className="top-heading">
            Start your journey in and of our <span>Technical Bootcamps</span>
          </Heading>
          <SpaceMedium className="mobile-hidden" />

          <div className="card-container">
            {Web2BootcampsIndividual.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <Heading className="card-title" color={item.color}>
                    {item.title}
                  </Heading>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.description}
                  </CardParagraph>
                 
                  <CardParagraph className="card-text" color={item.color}>
                    {item.schedule}
                  </CardParagraph>
                  {item.externalLink ? (
                    <a
                      href={item.externalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.externalLink ? (
                        <a
                          href={item.externalLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <CustomButton
                            className="card-button"
                            border={"2px solid #E09D00"}
                            background={item.background}
                            color="#E09D00"
                          >
                            {item.buttonInfo}
                          </CustomButton>
                        </a>
                      ) : (
                        <Link to={`${item.path}/${item?.title}`}>
                        <CustomButton
                          className="card-button"
                          border={"2px solid #E09D00"}
                          background={item.background}
                          color="#E09D00"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                        </Link>
                      )}
                    </a>
                  ) : (
                    <Link to={`${item.path}/${item?.title}`}>
                    <CustomButton
                      className="card-button"
                      border={"2px solid #E09D00"}
                      background={item.background}
                      color="#E09D00"
                    >
                      {item.buttonInfo}
                    </CustomButton>
                    </Link>
                  )}
                  <SpaceSmall />
                </Container>
              );
            })}
          </div>

          <Heading className="coming-soon">Coming Soon</Heading>

          <div className="card-container">
            {Web2BootcampsComingSoon.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <Heading className="card-title" color={item.color}>
                    {item.title}
                  </Heading>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.description}
                  </CardParagraph>
                  
                  <CardParagraph className="card-text" color={item.color}>
                    {item.schedule}
                  </CardParagraph>
                  {item.externalLink ? (
                    <a
                      href={item.externalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CustomButton
                        className="card-button"
                        border="2px solid #E09D00"
                        background={item.background}
                        color="#E09D00"
                        
                      >
                        {item.buttonInfo}
                      </CustomButton>
                    </a>
                  ) : (
                    <CustomButton
                      default
                      className="card-button"
                      border={"2px solid #E09D00"}
                      background={item.background}
                      color="#E09D00"
                    >
                      <Link to={item.path}>{item.buttonInfo}</Link>
                    </CustomButton>
                  )}

                  <SpaceSmall />
                </Container>
              );
            })}
          </div>

          <div className="mobile-swipe-container desktop-hidden">
            <Swiper
              slidesPerView={1.1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {Web2BootcampsIndividual.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        />
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        {item.externalLink ? (
                          <a
                            href={item.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CustomButton
                              className="card-button"
                              border={"2px solid #E09D00"}
                              background={item.background}
                              color="#E09D00"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </a>
                        ) : (
                          <Link to={`${item.path}/${item?.title}`}>
                          <CustomButton
                            className="card-button"
                            border={"2px solid #E09D00"}
                            background={item.background}
                            color="#E09D00"
                          >
                            {item.buttonInfo}
                          </CustomButton>
                          </Link>
                        )}

                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>

          <SpaceSmall className="desktop-hidden" />

          <PreHeading className="desktop-hidden">COMING SOON</PreHeading>
          <div className="mobile-swipe-container desktop-hidden">
            <Swiper
             slidesPerView={1.1}
             spaceBetween={10}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {Web2BootcampsComingSoon.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        />
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        {item.externalLink ? (
                          <a
                            href={item.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CustomButton
                              className="card-button"
                              border={"2px solid #E09D00"}
                              background={item.background}
                              color="#E09D00"
                              disabled={true}
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </a>
                        ) : (
                          <CustomButton
                            className="card-button"
                            border={"2px solid #E09D00"}
                            background={item.background}
                            color="#E09D00"
                            disabled={true}
                          >
                            <Link to={item.path}>{item.buttonInfo}</Link>
                          </CustomButton>
                        )}

                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </Container>

        <Upskilling />

        <OurGrads />

        <FAQS FAQList={BootcampFAQS} />
      </Web2Container>
    </>
  );
};

export default Web2Page;
