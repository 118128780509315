import axios from 'axios';
 
const client = axios.create();

const token = localStorage.getItem('userToken')
? localStorage.getItem('userToken')
: null;

if (token) {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
}

// client.interceptors.response.use(response => response, error => {
//     const { status } = error?.response || {};
//   if (status === 403 || status === 401) {
//     // Log user out
//     localStorage.removeItem('userToken');
//     delete client.defaults.headers.common.Authorization;
//   }

//   return Promise.reject(error);
// });

export default client;
