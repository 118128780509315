import { createAsyncThunk } from '@reduxjs/toolkit'
import client from '../utils/client'
import { errorHandler } from '../utils/errorHandler'
import { BASE_URL } from './config'

export const createEligibleCode = createAsyncThunk(
  'eligibility/createeligiblecode',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.post(
        `${BASE_URL}bootcamp/registration/check-eligibility`,
        
          payload,
      )
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error?.response?.data?.message) {
        // errorHandler(error?.response?.data?.message)
        return rejectWithValue(error?.response?.data?.message)
      } else {
        return rejectWithValue(error?.response?.data?.message)
      }
    }
  }
)
