import { createSlice } from '@reduxjs/toolkit'
import { registration } from '../../api/registration'


const initialState = {
  loading: false,
  data: null,
  disable: false,
  regSuccess: false,
}

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
  },
  extraReducers: {
    [registration.pending]: (state) => {
      state.loading = true
      state.disable = true
    },
      [registration.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.regSuccess = true
      state.disable = false
    },
    [registration.rejected]: (state, action) => {
      state.loading = false
      state.regError = action.payload
      state.regSuccess = false
      state.disable = false
    },
  },
})
export default registrationSlice.reducer
