import { SuccessContainer } from "./style";
import {
  HeroContainer,
  Heading,
  CardParagraph,
  Container,
  CustomButton,
  ButtonSpace,
} from "../../../styles/StyledComponents";
import { Link } from "react-router-dom";
import { JobsImages } from "../../../constants";
import { useEffect } from "react";

const Success = ({ chosenPackage }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <SuccessContainer>
      <HeroContainer className="hero-container">
        <Container className="hero-left">
          <Heading fontSize="24px" lineHeight="26px" className="padding">
            Order Summary - £{chosenPackage.price}
          </Heading>

          <CardParagraph fontSize="18px" className="padding">
            Premium Package {chosenPackage.number}
          </CardParagraph>

          <div className="individual-package">
            <CardParagraph fontSize="18px">What you'll get</CardParagraph>

            <ul>
              {chosenPackage.list.map((listItem, index) => {
                return <li key={index}>{listItem}</li>;
              })}
            </ul>
          </div>
        </Container>

        <Container className="hero-right">
          <img
            src={JobsImages.progressFull}
            alt="progress bar"
            className="progress-bar"
          />
          <img src={JobsImages.wallet} alt="wallet" className="wallet" />
          <Heading fontSize="24px" lineHeight="26px" className="padding">
            Payment successful
          </Heading>
          <CardParagraph fontSize="16px">
            Thanks for purchasing our Premium {chosenPackage.number}
            Package. Your order has been successful, Your order will be posted
            after three working days. If rejected, be assured you’ll get an
            email for a refund.
          </CardParagraph>
          <ButtonSpace />
          <CustomButton>
            <Link to="/jobs">Back to Jobs Page</Link>
          </CustomButton>
        </Container>
      </HeroContainer>
    </SuccessContainer>
  );
};

export default Success;
