import { JoinContainer } from "./style";
import { Spin } from "antd";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  CardParagraph,
  CustomButton,
  Heading,
  SpaceSmall,
} from "../../styles/StyledComponents";

const JoinFamily = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = {
        fullName,
        email,
        jobRole,
        companyName,
      };
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzoYjv-WJJGoRR4UexxsKcjEJHp32pbYbw6Lu1-eGlLHAfNNESJRUABvqtJFhB3sg88/exec",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      setIsLoading(false);
      setSuccess(true);

      toast.success("Success", {
        position: toast.POSITION.TOP_CENTER,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return response.ok;
    } catch (error) {
      setIsLoading(false);
      setSuccess(false);
      console.log(error, "ee");

      toast.error(
        "An error occurred while processing your request, please make sure to fill all fields correctly and try again.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };
  return (
    <JoinContainer>
      {!success && (
        <>
          <ToastContainer />
          <SpaceSmall />

          <SpaceSmall />
          <form className="join-form" onSubmit={handleForm}>
            <Heading className="join-title">Impact Report Form</Heading>
            <CardParagraph className="join-text" fontSize="18px">
              Please fill this form so we can get the information of people
              interested in reading our impact report.
            </CardParagraph>
          
            <SpaceSmall />
            <div className="form-double">
              <div>
                <input
                  className="join-email join-button"
                  type="text"
                  placeholder="Name"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
              </div>

              <div>
                <input
                  className="join-email join-button"
                  type="text"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-double">
              <div>
                <input
                  className="join-email join-button"
                  type="text"
                  placeholder="Company name"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>
              <div>
                <input
                  className="join-email join-button"
                  type="text"
                  placeholder="Job Title"
                  value={jobRole}
                  onChange={(e) => {
                    setJobRole(e.target.value);
                  }}
                />
              </div>
            </div>
            <br />
            <div className="">
              <CustomButton
                className="join-submit "
                type="submit"
                value="Sign Up"
                height="50px"
              >
                {isLoading ? <Spin spinning /> : "Submit"}
              </CustomButton>
            </div>
            <SpaceSmall />
          </form>
        </>
      )}
      {success && (
        <div className="wrapper">
          <Heading className="join-title" fontSize="40px">
            Thank you for your interest!
          </Heading>
          <div
            className="body"
            style={{
              border: "1px solid #999",
              borderRadius: "15px",
              padding: "20px",
            }}
          >
            <a href="https://www.niyo.co/static/media/report.2b64a16f3558cb57650f.pdf">
              <CustomButton> Download our Impact report</CustomButton>
            </a>
          </div>
        </div>
      )}
    </JoinContainer>
  );
};

export default JoinFamily;
