import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { WebDevInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const WebDev = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let { id } = useParams();

  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={WebDevInfo.courseTitle}
        schedule={WebDevInfo.schedule}
        courseRating={WebDevInfo.courseRating}
        applicationLink={WebDevInfo.applicationLink}
        headingDescription={WebDevInfo.headingDescription}
        heroImage={WebDevInfo.heroImage}
        cardTitle={WebDevInfo.cardTitle}
        overviewHeading={WebDevInfo.overviewHeading}
        overviewText={WebDevInfo.overviewText}
        overviewVideo={WebDevInfo.overviewVideo}
        syllabusText={WebDevInfo.syllabusText}
        syllabusLessons={WebDevInfo.syllabusLessons}
        benefits={WebDevInfo.benefits}
        postBenefits={WebDevInfo.postBenefits}
        eventsText={WebDevInfo.eventsText}
        eventsList={WebDevInfo.eventsList}
        requirementsText={WebDevInfo.requirementsText}
        requirementsList={WebDevInfo.requirementsList}
        tutors={WebDevInfo.tutors}
        tutorsParagraph={WebDevInfo.tutorsParagraph}
      />
    </>
  );
};

export default WebDev;
