import { http } from "../utils/http.js";

class JoinService {
  constructor(http) {
    this.request = http;
  }

  async joinNiyo(data) {
    return await this.request.post("join-niyo", data);
  }

}

export default new JoinService(http);
