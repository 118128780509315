import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

const UpskillingContainer = styled.div`
  width: 100%;
  padding: 20px 10%;
  display: flex;
  color: ${({ color }) => (color ? color : "#070909")};

  .upskilling-left {
    width: 50%;
    padding: 5% 0;
    margin: 0;
  }

  .upskilling-right {
    width: 50%;
    padding: 5% 2%;
    margin: 0;

    .upskilling-text {
      color: ${({ color }) => (color ? color : "#070909")};
    }
  }

  @media ${breakpointSizes.mobile} {
    flex-direction: column;
    padding: 20px;

    .upskilling-left {
      width: 100%;
    }

    .upskilling-right {
      width: 100%;

      .upskilling-text {
        font-family: Regular;
      }
    }
  }

  @media ${breakpointSizes.desktop} {
    padding: 20px 350px;
  }
  @media ${breakpointSizes.large} {
    padding:20px 650px
  }
`;

export default UpskillingContainer;
