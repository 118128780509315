import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { HairInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from 'react-router-dom'

const HairCourse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let { id } = useParams()
  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={HairInfo.courseTitle}
        schedule={HairInfo.schedule}
        courseRating={HairInfo.courseRating}
        applicationLink={HairInfo.applicationLink}
        headingDescription={HairInfo.headingDescription}
        heroImage={HairInfo.heroImage}
        cardTitle={HairInfo.cardTitle}
        overviewHeading={HairInfo.overviewHeading}
        overviewText={HairInfo.overviewText}
        overviewVideo={HairInfo.overviewVideo}
        syllabusText={HairInfo.syllabusText}
        syllabusLessons={HairInfo.syllabusLessons}
        benefits={HairInfo.benefits}
        postBenefits={HairInfo.postBenefits}
        eventsText={HairInfo.eventsText}
        eventsList={HairInfo.eventsList}
        requirementsText={HairInfo.requirementsText}
        requirementsList={HairInfo.requirementsList}
        tutors={HairInfo.tutors}
        tutorsParagraph={HairInfo.tutorsParagraph}
      />
    </>
  );
};

export default HairCourse;
