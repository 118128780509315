import {
  Container,
  CustomButton,
  Heading,
  HeroContainer,
  InputField,
  Paragraph,
  SelectField,
  SpaceLarge,
  SpaceSmall,
  TextareaField,
} from "../../../styles/StyledComponents";
import partnersService from "../../../service/partners.service";
import { useState, useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Spin } from "antd";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FormContainer } from "./style";

const PartnersForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [noOfEmployee, setNoOfEmployee] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [shortCode, setShortCode] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [jobLevel, setJobLevel] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handlePhoneNumber(value, country, event, formattedValue) {
    setDialCode(country.dialCode);
    setName(country.name);
    setPhoneNumber(formattedValue.replace('+', ''))
    setShortCode(country.countryCode);
  }

  const handleForm = async(e) => {
    e.preventDefault();

    setIsLoading(true);


    if (!firstName) {
      NotificationManager.error(
        "First name is required",
        "An Error Occurred",
        5000
      );
      setIsLoading(false);
      return;
    }

    if (!lastName) {
      NotificationManager.error(
        "Last name is required",
        "An Error Occurred",
        5000
      );
      setIsLoading(false);
      return;
    }

    if (!message) {
      NotificationManager.error(
        "A message is required",
        "An Error Occurred",
        5000
      );
      setIsLoading(false);
      return;
    }

    if (!noOfEmployee) {
      NotificationManager.error(
        "Number of Employees is required",
        "An Error Occurred",
        5000
      );
      setIsLoading(false);
      return;
    }

    if (!companyName) {
      NotificationManager.error(
        "Company Name is required",
        "An Error Occurred",
        5000
      );
      setIsLoading(false);
      return;
    }

    if (!phoneNumber) {
      NotificationManager.error(
        "Phone Number is required",
        "An Error Occurred",
        5000
      );
      setIsLoading(false);
      return;
    }

    if (!jobTitle) {
      NotificationManager.error(
        "Job Title is required",
        "An Error Occurred",
        5000
      );
      setIsLoading(false);
      return;
    }

    if (!businessEmail) {
      NotificationManager.error(
        "Business Email is required",
        "An Error Occurred",
        5000
      );
      setIsLoading(false);
      return;
    }

    if (!jobLevel) {
      NotificationManager.error(
        "Job Level is required",
        "An Error Occurred",
        5000
      );
      return;
    }

    const data = {
      firstName: firstName,
      lastName: lastName,
      message: message,
      noOfEmployee: noOfEmployee,
      companyName: companyName,
      number: phoneNumber,
      jobTitle: jobTitle,
      businessEmail: businessEmail,
      jobLevel: jobLevel,
    };
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxoGW4ufQf-IzoLOPvizWYYkyjGtUdXdCwKc_8rrldtag6nlEnryY3iYjrXeo6C-VqC/exec",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      setIsLoading(false);
      setFirstName("");
      setLastName("")
      setNoOfEmployee("")
      setCompanyName("")
      setPhoneNumber("")
      setJobTitle("")
      setBusinessEmail("")
      setJobLevel("")
      setMessage("")
      NotificationManager.success("Form Submitted Succesfully", "Success");
      return response.ok;
    } catch (error) {
      setIsLoading(false);
      NotificationManager.error(
        "An error occurred while processing your request, please try again later.",
        "Internal Server Error",
        5000
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

 

  return (
    <>
      <FormContainer>
        <NotificationContainer />
        <HeroContainer height={"auto"}>
          <Heading className="partner-heading">Become a Partner</Heading>
          <Paragraph>Complete the form to become a partner</Paragraph>
          <SpaceSmall />

          <form method="POST">
            <Container className="form-container">
              <div className="form-row">
                <InputField
                  type="text"
                  id="first-name"
                  className="form-box left"
                  placeholder="First Name"
                  width="49%"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  required
                />
                <InputField
                  type="text"
                  id="last-name"
                  className="form-box"
                  placeholder="Last Name"
                  width="49%"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="form-row">
                <InputField
                  type="text"
                  id="company-name"
                  className="form-box left"
                  placeholder="Company Name"
                  width="49%"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                  required
                />
                <InputField
                  type="email"
                  id="business-email"
                  className="form-box"
                  placeholder="Business Email"
                  width="49%"
                  value={businessEmail}
                  onChange={(e) => {
                    setBusinessEmail(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="form-row phone-row">
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  country={"gb"}
                  enableSearch={true}
                  className="form-phone"
                />

                <InputField
                  type="text"
                  id="job-title"
                  className="form-box"
                  placeholder="Job Title"
                  width="49%"
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitle(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="form-row ">
                <SelectField
                  id="job-level"
                  className="form-box left"
                  width="49%"
                  value={jobLevel}
                  onChange={(e) => {
                    setJobLevel(e.target.value);
                  }}
                  required
                >
                  <option defaultValue={jobLevel}>Job Level</option>
                  <option value="Individual Contributor">
                    Individual Contributor
                  </option>
                  <option value="Manager">Manager</option>
                  <option value="Director"> Director</option>
                  <option value="VP+">VP+ </option>
                  <option value="Executive">Executive </option>
                </SelectField>

                <SelectField
                  id="number-employees"
                  className="form-box"
                  width="49%"
                  value={noOfEmployee}
                  onChange={(e) => {
                    setNoOfEmployee(e.target.value);
                  }}
                  required
                >
                  <option defaultValue={noOfEmployee}>
                    Number of Employees
                  </option>
                  <option value="1-10">1 - 10</option>
                  <option value="11-50">11 - 50</option>
                  <option value="51-300"> 51 - 300</option>
                  <option value="301-100">301 - 1000 </option>
                  <option value="1000+">1000+ </option>
                </SelectField>
              </div>
              <div className="info-box">
                <TextareaField
                  type="text"
                  id="more-info"
                  className="form-box-info"
                  placeholder="Tell us more of your needs"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  required
                />
              </div>
              <SpaceSmall />
              <CustomButton
                
                onClick={handleForm}
                type="submit"
              >
                {isLoading ? <><Spin tip="" spinning/></> : 'Send'}
              </CustomButton>

            </Container>
          </form>

          <SpaceLarge />
        </HeroContainer>
      </FormContainer>
    </>
  );
};

export default PartnersForm;
