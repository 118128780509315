import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

const IndividualContainer = styled.div`
  .hero-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: auto;

    .hero-left {
      width: 52%;
      margin: 0;
      padding: 5% 0 0 0;

      .rating {
        height: 25px;
        margin: 3% 0;
      }
      .funding-logo{
        display: flex;
        img{
          height:35px;
          margin-top: -10px;
          margin-left: 10px;

        }
      }
    }


    .hero-right {
      width: 48%;
      margin: 0;
      padding: 2% 0 0 3%;
      display: flex;
      justify-content: center;

      .hero-image {
        width: 500px;
        height: 90%;
        margin: 0;
        padding: 0;
      }
    }
  }

  .section-container {
    background-color: #fffcf4;
    display: flex;
    flex-direction: row;

    .section {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    .section-left {
      width: 30%;

      .details-box {
        position: sticky;
        top: 20%;
        height: 76vh;
        margin: 0;
        display: flex;
        flex-direction: column;
        padding:30px;
        color: #636969;
        background-color: #fff;
        box-shadow: 2px 4px 14px rgba(99, 105, 105, 0.22);
        font-size: 40px;

        .divider-line {
          border-bottom: 1px solid #636969;
          margin: 6% 0;
        }

        .text {
          color: #636969;
          padding: 0 0 7% 0;
          font-size: 13px;
          font-family: Regular;

          a {
            text-decoration: none;
            color: #636969;
          }
        }
      }
    }

    .section-right {
      width: 65%;
      margin: 0 0 0 5%;

      .course-items {
        padding: 0;
        margin: 0;

        .overview-section {
          .overview-video {
            width: 100%;
          }
        }

        .syllabus-section {
        }

        .benefits-section {
        }

        .events-section {
        }

        .requirements-section {
        }

        .tutors-section {
        }
      }

      .overview-section {
        .overview-video {
          width: 100%;
          height: 450px;
        }
      }

      .syllabus-section {
        .accordion-section {
        }

        .syllabus-item {
          margin: 0 0 3% 0;
          background-color: #fff;

          .syllabus-heading {
            .syllabus-top {
              display: flex;
              align-items: center;
              padding: 2%;

              .week-tag {
                width: 16%;
                height: 35px;
                font-family: Medium;
                border: 1px solid #ffd470;
                background-color: #fff9eb;
                color: #fbaf00;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .week-title {
                width: 80%;
                height: 10;
                padding: 0 0 0 5%;
              }

              .syllabus-icon {
                width: 30px;
              }
            }
          }
        }
      }

      .tutors-section {
        .tutors-cards {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top:20px;

          .padding {
            padding: 0 0 3% 0;
          }

          .individual-tutor {
            height: 460px;
            width: 48%;
            margin: 0 0 5% 0;

            .tutor-image {
              width: 100%;
              height: 300px;
              background-size:cover;

              box-shadow: 1.77703px 3.55405px 12.4392px rgba(99, 105, 105, 0.22);
              margin: 0 0 4% 0;
            }
          }
        }
      }

      ul {
        padding: 2% 0 0 2%;

        li {
          padding: 0 0 10px 10px;
          font-size: 20px;
          font-family: "Light";
        }

        li::marker {
          color: #fbaf00;
          content: "■";
          font-size: 11px;
        }
      }
    }
  }

  .grad-reviews-container {
    padding: 0;
    display: flex;

    .grad-review {
      width: 46%;

      .name-section {
        display: flex;
        align-items: center;
        height: 60px;

        .initials {
          background-color: #fbaf00;
          color: #fff;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Medium;
          margin: 0 2% 0 0;
        }
      }
    }

    .grad-review:nth-child(1n) {
      margin: 0 4% 0 0;
    }
  }

  @media ${breakpointSizes.mobile} {
    .mobile-hidden {
      display: none;
    }

    .hero-container {
      flex-direction: column;
      height: auto;
      width: 100%;
      padding: 150px 20px 20px 20px;

      .hero-left {
        width: 100%;
      }

      .hero-right {
        width: 100%;
        padding: 0px;

        .hero-image {
          width: 100%;
          height: auto;
          padding: 10% 0;
        }
      }
    }

    .section-container {
      flex-direction: column;

      .mobile-hidden {
        display: none;
      }

      .mobile-heading {
        font-size: 28px;
        line-height: 30px;
      }

      .mobile-text {
        font-size: 18px;
      }

      .section-left {
        width: 100%;
        position: sticky;
        top: 10%;
        height: 23vh;

        .details-box {
          height: 180px;
          width: 100%;
          padding: 2%;
          margin: 0;

          .text {
            padding: 0;
            margin: 0 0 0.5% 0;
            height: 20px;
            font-size: 14px;
            font-family: Light;
          }
        }

        .card-title,
        .divider-line {
          display: none;
        }
      }

      .section-right {
        margin: 0;
        width: 100%;

        .syllabus-section {
          .syllabus-item {
            width: 100%;
            margin: 0 0 8% 0;
            border: 1px solid #eaebeb;

            .syllabus-heading {
              .syllabus-top {
                .week-tag {
                  display: none;
                }

                .week-title {
                  width: 90%;
                  font-size: 20px;
                  line-height: 24px;
                  height: 10;
                  padding: 0 0 0 5%;
                }

                .syllabus-icon {
                  width: 30px;
                }
              }
            }
          }
        }
      }
    }

    .grads-section {
      .grads-heading {
        font-size: 30px;
        line-height: 32px;
      }

      .grad-reviews-container {
        flex-direction: column;

        .grad-review {
          width: 100%;
          padding: 0 0 10% 0;
        }

        .grad-review:last-child {
          padding: 0 0 5% 0;
        }
      }
    }
  }

  @media (max-width: 1150px) {
    :where(.css-dev-only-do-not-override-ixblex).ant-modal.ant-modal-content {
      padding: none !important;
    }
    .heading-section{
      padding:0px 0px;
      img {
        width: 40px !important;
      }
    }
  }

  @media ${breakpointSizes.desktop} {
   
  }
`;

export default IndividualContainer;
