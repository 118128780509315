import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { DataInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from 'react-router-dom'

const DataCourse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  let { id } = useParams()


  return (
    <>
      <IndividualBootcampLayout
          title={id}
        heroHeading={DataInfo.courseTitle}
        schedule={DataInfo.schedule}
        courseRating={DataInfo.courseRating}
        headingDescription={DataInfo.headingDescription}
        heroImage={DataInfo.heroImage}
        cardTitle={DataInfo.cardTitle}
        overviewHeading={DataInfo.overviewHeading}
        overviewText={DataInfo.overviewText}
        overviewVideo={DataInfo.overviewVideo}
        syllabusText={DataInfo.syllabusText}
        applicationLink={DataInfo.applicationLink}
        syllabusLessons={DataInfo.syllabusLessons}
        benefits={DataInfo.benefits}
        postBenefits={DataInfo.postBenefits}
        eventsText={DataInfo.eventsText}
        eventsList={DataInfo.eventsList}
        requirementsText={DataInfo.requirementsText}
        requirementsList={DataInfo.requirementsList}
        tutorsParagraph={DataInfo.tutorsParagraph}
        tutors={DataInfo.tutors}
      />
    </>
  );
};

export default DataCourse;
