import styled from "styled-components";
import { ourStoryImages } from "../../constants";
import breakpointSizes from "../../constants/breakpointSizes";

export const OurStoryContainer = styled.div`
  background-color: #fffcf4;

  .hidden-space {
    display: none;
  }

  .story-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    height: 800px;

    .image-container {
      position: absolute;
      background-image: url(${ourStoryImages.storyHero});
      background-size: cover;
      background-position: left;
      z-index: 4;
      width: 80%;
      height: 500px;
      bottom: -30%;
    }
  }

  .about-us {
    display: flex;

    .about-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding: 0;

      .about-img-1 {
        background-image: url(${ourStoryImages.aboutImage1});
        background-size: cover;
        width: 80%;
        height: 250px;
        margin: 0 0 6% 0;
      }
      .about-img-2 {
        background-image: url(${ourStoryImages.aboutImage2});
        background-size: cover;
        width: 80%;
        height: 250px;
      }
    }

    .about-right {
      width: 50%;
      padding: 0;
    }
  }

  .upskilling-section {
    .upskill-cards {
      display: flex;
      flex-direction: row;
      padding: 0;

      .individual-upskill {
        width: 25%;
        padding: 0 2% 0 0;

        .individual-logo {
          width: 20%;
        }

        .individual-heading {
          font-size: 20px;
          line-height: 26px;
          padding: 5% 0;
        }
      }
    }
  }

  .impact-section {
    .impact-cards-container {
      padding: 0%;
      display: flex;
      flex-direction: row;

      .individual-stat-card {
        width: 33%;
        padding: 0 2% 0 0;

        .stat-logo {
          width: 24%;
        }

        .stat-heading {
          font-size: 36px;
        }
      }
    }
  }

  .bootcamp-team {
    .bootcamp-team-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;

      .team-member-card {
        width: 20%;
        margin: 0 4% 3% 0;
        z-index: 1;
        background-size: cover;
        height: 200px;
        border: 1px solid #fbaf00;

        .member-bio {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          background: linear-gradient(
            0deg,
            rgba(205, 205, 205, 0.68),
            rgba(205, 205, 205, 0.68)
          );
          width: 100%;
          display: none;
          height: inherit;
          padding: 15px 10px;
          transition: 0.2s ease-in-out;
          z-index: 10;
          position: relative;

          a,
          a:visited,
          a:hover,
          a:active {
            text-decoration: none;
          }

          .member-name {
            font-family: AtypDisplay-Bold;
            font-size: 15px;
            color: #000 !important;
            margin-bottom: 0;
          }

          .member-role {
            font-family: Light;
            font-size: 12px;
            margin: 0;
          }

          .linkedin-logo {
            height: 30px;
            width: 30px;
            position: absolute;
            bottom: 10px;
          }
        }

        .team-image {
          object-fit: cover;
          object-position: top;
        }

        .team-name {
          font-size: 14px;
          line-height: 30px;
          padding: 0;
        }

        .team-title {
          font-size: 12px;
        }
      }

      .team-member-card:hover {
        .member-bio {
          display: block;
          z-index: 10;
        }
      }
    }
  }

  .news-container {
    height: auto;
    padding: 80px 10% 20px 10%;

    .cards-container {
      padding: 0;

      .news-carousel {
        padding: 0 1% 10% 0%;

        .news-card {
          background-color: #fff;
          padding: 2% 6%;
          margin: 0 4% 0 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .card-logo {
            width: 70px;
            padding: 5% 0 4% 0;
          }

          .card-headline {
            font-size: 22px;
            line-height: 34px;
          }

          .press-button {
            background-color: #fff;
            color: #070909;
            text-decoration: underline;
            text-align: left;
          }
        }

        .react-multiple-carousel__arrow--left {
          left: 42%;
          bottom: 20px;
          background-color: #fbaf00;
          z-index: 10;
        }

        .react-multiple-carousel__arrow--right {
          bottom: 20px;
          right: 42%;
          background-color: #fbaf00;
          color: #ffe099;
          z-index: 10;
        }
      }
    }
  }

  @media ${breakpointSizes.mobile} {
    .mobile-hidden {
      display: none;
    }

    .story-hero {
      text-align: left;
      height: 680px;
    }

    .about-us {
      flex-direction: column;

      .about-left {
        order: 2;
        width: 100%;
        padding: 10% 0 0 0;

        .about-img {
          width: 100%;
        }
      }
      .about-right {
        order: 1;
        width: 100%;
        padding: 10% 0 0 0;
      }
    }

    .upskilling-section {
      .upskill-cards {
        flex-direction: column;

        .individual-upskill {
          width: 100%;

          .individual-logo {
            padding: 10% 0 0 0;
          }

          .individual-heading {
            padding: 2% 0;
          }
        }
      }
    }

    .impact-section {
      .impact-cards-container {
        flex-direction: column;

        .individual-stat-card {
          width: 100%;

          .stat-logo {
            width: 15%;
            padding: 12% 0 0 0;
          }
        }
      }
    }

    .bootcamp-team {
      .bootcamp-team-cards {
        flex-direction: column;

        .team-member-card {
          width: 100%;
          height: 300px;
        }
      }

      
    }

    .news-container {
      display: none;
    }
  }

  @media ${breakpointSizes.desktop} {
    .story-hero {
      height: 100vh;

      .image-container {
        height: 700px;
      }
    }

    .about-us {
      .about-right {
        .about-paragraph {
          width: 90%;
        }
      }
    }

    .impact-section {
      .impact-cards-container {
        .individual-stat-card {
          padding: 0 2%;

          .stat-text {
            width: 90%;
          }
        }
      }
    }

    .bootcamp-team {
      .bootcamp-team-cards {
        .team-member-card {
          padding: 0 1%;
        }
      }
    }

    .news-container {
      padding: 20px 350px;
      .cards-container {
       
        .news-carousel {
          padding: 0;

          .news-card {
            width: 80%;
            margin: 0;

            .card-logo {
              width: 60px;
            }
          }

          .react-multiple-carousel__arrow--left {
            left: 42%;
            bottom: 20px;
          }

          .react-multiple-carousel__arrow--right {
            bottom: 20px;
            right: 42%;
          }
        }

        .react-multi-carousel-item,
        .react-multi-carousel-item--active {
          margin: 0;
        }
      }
    }
  }
`;
