import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { FashionInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from 'react-router-dom'

const FashionCourse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let { id } = useParams()
  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={FashionInfo.courseTitle}
        schedule={FashionInfo.schedule}
        courseRating={FashionInfo.courseRating}
        applicationLink={FashionInfo.applicationLink}
        headingDescription={FashionInfo.headingDescription}
        heroImage={FashionInfo.heroImage}
        cardTitle={FashionInfo.cardTitle}
        overviewHeading={FashionInfo.overviewHeading}
        overviewText={FashionInfo.overviewText}
        overviewVideo={FashionInfo.overviewVideo}
        syllabusText={FashionInfo.syllabusText}
        syllabusLessons={FashionInfo.syllabusLessons}
        benefits={FashionInfo.benefits}
        postBenefits={FashionInfo.postBenefits}
        eventsText={FashionInfo.eventsText}
        eventsList={FashionInfo.eventsList}
        requirementsText={FashionInfo.requirementsText}
        requirementsList={FashionInfo.requirementsList}
        tutorsParagraph={FashionInfo.tutorsParagraph}
        tutors={FashionInfo.tutors}
        tutorsList={FashionInfo.tutorsList}
      />
    </>
  );
};

export default FashionCourse;
