import { PaymentContainer } from "./style";
import {
  HeroContainer,
  Heading,
  Container,
  CardParagraph,
  SpaceSmall,
  ButtonSpace,
  CustomButton,
  SpaceLarge,
  InputField,
} from "../../../styles/StyledComponents";
import { JobsImages } from "../../../constants";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spin } from "antd";

const Payment = ({ chosenPackage }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // const [paymentType, setPaymentType] = useState("");
  const [loading, setLoading] = useState(false);

  const selectPaymentMethod = (e) => {
    // setPaymentType(e.target.id);
  };

  const makePayment = (e) => {
    e.preventDefault();
    setLoading(true);
  };

  return (
    <PaymentContainer>
      <HeroContainer className="hero-container">
        <Container className="hero-left">
          <Heading fontSize="24px" lineHeight="26px" className="padding">
            Order Summary - £{chosenPackage.price}
          </Heading>

          <CardParagraph fontSize="18px" className="padding">
            Premium Package {chosenPackage.number}
          </CardParagraph>

          <div className="individual-package">
            <CardParagraph fontSize="18px">What you'll get</CardParagraph>

            <ul>
              {chosenPackage.list.map((listItem, index) => {
                return <li key={index}>{listItem}</li>;
              })}
            </ul>
          </div>
        </Container>

        <Container className="hero-right">
          <img
            src={JobsImages.progressHalf}
            alt="progress bar"
            className="progress-bar"
          ></img>
          <Heading fontSize="24px" lineHeight="26px" className="padding">
            Payment Details
          </Heading>
          <CardParagraph>
            Complete your purchase by providing your payment details
          </CardParagraph>
          <SpaceSmall />

          <div className="form-line">
            <label>
              Full Name
              <InputField
                id="full-name"
                placeholder="Your Full Name"
              ></InputField>
            </label>
          </div>

          <div className="form-line">
            <label>
              Email
              <InputField id="email" placeholder="Your Email"></InputField>
            </label>
          </div>

          <div className="form-line">
            <label>
              Company Name
              <InputField
                id="company-name"
                placeholder="Company Name"
              ></InputField>
            </label>
          </div>

          <div className="form-line">
            <label>
              Country
              <InputField id="Country" placeholder="Country"></InputField>
            </label>
          </div>

          <div className="form-line form-double">
            <label>
              Post Code / ZIP Code
              <InputField
                type="text"
                placeholder="Post Code / Zip Code"
                id="post-code"
              ></InputField>
            </label>

            <label>
              County / State
              <InputField
                type="text"
                placeholder="County / State"
                id="county"
              ></InputField>
            </label>
          </div>

          <div className="payment-links">
            <Heading
              fontSize="24px"
              lineHeight="26px"
              className="payment-heading"
            >
              Payment Method
            </Heading>
            <CardParagraph>
              Please choose one of the payment methods below. If you are having issues paying, you can contact us at <a style={{fontFamily:"Medium", color:"#FBAF01"}} href="mailto:partnerships@niyo.co">partnerships@niyo.co</a>
            </CardParagraph>
            <SpaceSmall />
            <div className="payment-logos">
              <Container className="payment-left">
                <CustomButton
                  className="payment-button"
                  width="48%"
                  height="75px"
                  onClick={selectPaymentMethod}
                >
                  <img
                    src={JobsImages.stripe}
                    alt="Stripe payment method"
                    id="stripe-payment"
                    className="button-image-1"
                  />
                </CustomButton>

                <CardParagraph fontFamily="Regular">
                  Pay with Stripe
                </CardParagraph>
              </Container>

              <Container className="payment-right">
                <CustomButton
                  className="payment-button"
                  width="48%"
                  height="75px"
                  background="#F4F5F5"
                  onClick={selectPaymentMethod}
                >
                  <img
                    src={JobsImages.goCardless}
                    alt="GoCardless payment method"
                    id="gocardless-payment"
                    className="button-image-2"
                  />
                </CustomButton>
                <CardParagraph fontFamily="Regular">
                  Pay with GoCardless
                </CardParagraph>
              </Container>
            </div>
          </div>

          <ButtonSpace />
          <CustomButton width="100%">
            <Link to="/jobs/success" onClick={makePayment}>
              Pay £{chosenPackage.price}
            </Link>
          </CustomButton>

          <Container className="spin">
            <Spin tip="Processing Your Payment" spinning={loading} />
          </Container>

          <SpaceLarge />
        </Container>
      </HeroContainer>
    </PaymentContainer>
  );
};

export default Payment;
