import React from 'react'

import { Dropdown, Menu } from "antd";
import { Link } from 'react-router-dom';
import { CompanyContainer } from './style';
import { FaAngleDown } from "react-icons/fa";

const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <CompanyContainer>
             <li><Link to="/partners">Partner With Us</Link></li>
             <li><Link to="/jobs">Job Board</Link></li>
            </CompanyContainer>
          ),
        },
      ]}
    />
  );
const Companies = () => {
  return (
    <div>
        <Dropdown overlay={menu} placement="bottom" >
          <p
            onClick={(e) => e.preventDefault()}
            style={{
              color: "#000",
              fontSize: "16px",
              cursor: "pointer",
              fontFamily:"Medium",
              marginTop: "16px",
            }}
          >
            Companies <FaAngleDown />
          </p>
        </Dropdown>
    </div>
  )
}

export default Companies