import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

const EligibilityContainer = styled.div`
  form {
    .err{
      border: 2px solid red;
    }
    p{
    color: red;
    }
  }
font-family:Regular;
  .disabled {
    pointer-events: none;
  }
  .form-container {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;

    label,
    .entry {
      font-size: 14px;
    }
  }
  .mobile-heading {
    font-weight: 500 !important;
    font-size: 30px !important;
    text-align: center;
  }
  option,
  input:focus,
  .form-phone:focus {
    font-size: 16px;
    color: #070909;
    font-family: "Regular";
  }

  .form-column,
  .sc-gsnTZi,
  .etFMMm {
    padding: 0 !important;
    margin: 0 !important;
  }

  .form-column-2 {
    padding: 0 1%;
    width: 60%;
    margin: 0 10% 0 0;

    .form-line {
      width: 100%;
      padding: 1.5% 0 0 0;
      position: relative;

      font-size: 14px;

      label {
        font-size: 14px;
      }
    }

    .form-double {
      display: flex;
      justify-content: space-between;

      label {
        width: 48%;
      }
    }

    .form-button-row {
      display: flex;
      padding: 5% 0;
      align-items: center;

      .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;

        .button-icon {
          width: 30px;
          height: 40px;
        }
      }

      .upload-paragraph {
        padding: 0 5%;
      }

      .upload-button:nth-child(2) {
        margin: 0 5%;
      }
    }

    .form-links-row {
      padding: 0 0 10% 0;
      .link-paragraph {
        font-size: 13px;
        padding: 1% 0 0 0;
      }

      a {
        text-decoration: none;
        color: #070909;
        padding: 0 0 0 1%;
      }

      .radio-line {
        display: flex;
        align-items: center;
        font-size: 10px;
        height: 20px;
        padding: 4% 0 0 0;
        font-family: Regular;

        .radio {
          margin: 0 1% 0 0;
        }
      }
    }

    .button-container {
      width: 65%;
      display: flex;
      justify-content: space-between;
    }

    .react-tel-input,
    .form-phone,
    .phone-control {
      margin: 1% 0 2% 0;

      .form-control {
        width: 100%;
        border-radius: 0;
        height: 60px;
        border: 1px solid #b5baba;
        font-family: "Light";
        color: #000;
      }
    }
  }

  .form-column-3 {
    width: 30%;

    .list-container {
      background-color: #fffcf4;
      padding: 0 10%;
      margin: 4% 0 0 10%;

      li {
        padding: 10% 0 0 4%;
        font-size: 14px;
      }

      li::marker {
        color: #fbaf00;
        content: "■";
        font-size: 11px;
      }
    }
  }

  .heading-section{
    padding:30px 100px;
  }

  @media (max-width: 1150px) {
    .heading-section{
      padding:0px 0px;
      img {
        width: 40px !important;
      }
      
    }
  }
  @media ${breakpointSizes.mobile} {
    width: 100%;
    height: auto !important;
    overflow-x: none;
    .heading-section{
      padding:0px 0px;
      img {
        width: 40px !important;
      }
      
    }
    .mobile-heading {
      padding: 20px 0 4% 0;
      font-size:24px !important;
      line-height: 30px;;
    }

    .desktop-hidden {
      display: block;
    }

    .form-container {
      height: auto;
      width: 300px;
      width: 100%;

      .form-column-1,
      .form-column-3 {
        display: none;
      }

      .form-column-2 {
        width: 100%;
        padding: 0 0 0 4%;

        .form-double {
          flex-direction: column;
          width: 100%;
          padding: 0;
          margin: 0;

          .sc-papXJ,
          .hqQarW,
          .entry {
            height: 60px;
            width: 100%;
          }

          label {
            width: 100%;
          }
        }
      }
    }
  }

  @media ${breakpointSizes.desktop} {
    .form-container {
      .form-column-1 {
        .timeline-3 {
          height: 38%;
        }
      }
    }
  }
`;

export default EligibilityContainer;
