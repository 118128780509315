import { http } from "../utils/http";

class EligibilityService {
  constructor(http) {
    this.request = http;
  }

  async eligibilityCheck(data) {
    return await this.request.post("bootcamp/registration/check-eligibility", data);
  }
}

export default new EligibilityService(http);
