import RegistrationContainer from "../style";
import { DatePicker } from "antd";
import {
  Container,
  Heading,
  ButtonSpace,
  InputField,
  TextareaField,
  SelectField,
  CardParagraph,
  SpaceMedium,
  CustomDiv,
} from "../../../styles/StyledComponents";
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { ToastContainer, toast } from "react-toastify";
import {
  FormTimelineImages,
  AgeGroups,
  UploadButtons,
  SpecificDisability,
  SpecificBenefit,
  StudyLevel,
  RoleTitle,
  NoFoundher,
  TeamMembers,
  TeamMembersParticaption,
  BusinessStructure,
  FundingReceived,
  BusinessSector,
  BusinessStage,
  TechnologyUse,
  Subject,
  CurrentEmploymentStatus,
} from "../../../constants";
import { useState } from "react";
import individualBootcampService from "../../../service/individualBootcamp.service";
import { debounce } from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";

const AdditionalInformation = ({
  fieldData,
  setRegData,
  regData,
  bootcamp,
  values,
  handleChange,
  errors,
  touched,
}) => {
  const elgibileData = fieldData !== null ? fieldData : "";

  const [eligibility, setEligibility] = useState(elgibileData?.regCode);

  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [files, setFiles] = React.useState(null);
  const [files2, setFiles2] = React.useState(null);
  const [files3, setFiles3] = React.useState(null);
  const [files4, setFiles4] = React.useState(null);
  const [files1, setFiles1] = React.useState(null);
  const maxNumber = 69;

  const onChangeImage = (imageList, addUpdateIndex) => {
    setRegData({ ...regData, workEligibilityProof: imageList[0].file });
    setFiles(imageList[0].file);
  };

  const onErrorImage = (errors, files) => {
    toast.error("The maximum file upload is 5MB", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const onChangeImage1 = (imageList, addUpdateIndex) => {
    setRegData({ ...regData, medicalDiagnosisFile: imageList[0].file });
    setFiles1(imageList[0].file);
  };

  const onErrorImage1 = (errors, files) => {
    toast.error("The maximum file upload is 5MB", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const onChangeImage2 = (imageList, addUpdateIndex) => {
    setRegData({ ...regData, businessPitchDeck: imageList[0].file });
    setFiles2(imageList[0].file);
  };

  const onErrorImage2 = (errors, files) => {
    toast.error("The maximum file upload is 5MB", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const onChangeImage4 = (imageList, addUpdateIndex) => {
    setRegData({ ...regData, resumeFile: imageList[0].file });
    setFiles4(imageList[0].file);
  };

  const onErrorImage4 = (errors, files) => {
    toast.error("The maximum file upload is 5MB", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const onChange = (date, dateString) => {
    setRegData({
      ...regData,
      businessLaunch: dateString,
    });
  };

  // const setSearchCode = debounce((code) => {
  //   check(code)
  // }, 1000)

  return (
    <>
      <RegistrationContainer className="accordion-item">
        <ToastContainer />
        <AccordionItemHeading className="accordion-heading">
          <AccordionItemButton className="accordion-click">
            <Container id="form-section-two" className="form-section-two">
              <Container className="form-container">
                <Container className="form-column form-column-1">
                  <img
                    src={FormTimelineImages[0]}
                    className="number"
                    alt="timeline"
                  />

                  <div className="timeline timeline-1"></div>
                </Container>

                <Container className="form-column form-column-2">
                  <Heading className="form-heading">
                    Personal Information
                  </Heading>
                </Container>
              </Container>
            </Container>
          </AccordionItemButton>
        </AccordionItemHeading>

        <AccordionItemPanel className="accordion-item-panel">
          <Container className="form-container">
            <Container className="form-column form-column-1">
              <div className="timeline-1"></div>
            </Container>

            <Container className="form-column form-column-2 errorHandler">
              <label>
                Eligibility Code
                <InputField
                  type="text"
                  value={eligibility}
                  placeholder="Eligibility Code"
                  onChange={(e) => {
                    // setSearchCode(e.target.value)
                    setRegData({
                      ...regData,
                      eligibilityCode: e.target.value,
                    });
                  }}
                  id="eligibility-code"
                />
              </label>

              <label>
                Age Group
                <SelectField
                  value={values.ageGroup}
                  onChange={handleChange}
                  name="ageGroup"
                  className={
                    errors.ageGroup && touched.ageGroup ? "err" : "entry"
                  }
                >
                  <option selected disabled value="">
                    Select Age Group
                  </option>
                  {AgeGroups.map((item, index) => {
                    return (
                      <>
                        <option key={index} value={item}>
                          {item}
                        </option>
                      </>
                    );
                  })}
                </SelectField>
                {errors.ageGroup && touched.ageGroup && (
                  <p>ageGroup is Required</p>
                )}
              </label>

              <div className="form-line form-double">
                <label>
                  Do you have caring responsibilities for children or other
                  adults?
                  <SelectField
                    // id="caring-responsibilities"
                    value={regData.childCareAssistance}
                    onChange={(e) => {
                      setRegData({
                        ...regData,
                        childCareAssistance: e.target.value,
                      });
                    }}
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </SelectField>
                </label>

                {regData.childCareAssistance === "Yes" && (
                  <label>
                    Specific Care Responsibilities?
                    <SelectField
                      // id="Specific caring-responsibilities"
                      style={{ marginTop: "22px" }}
                      value={regData.childCareAssistance1}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          childCareAssistance1: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Select your option
                      </option>
                      <option value="Adult">Adult</option>
                      <option value="Children">Children</option>
                      <option value="Adult & Children">Adult & Children</option>
                    </SelectField>
                  </label>
                )}
                {(!regData?.childCareAssistance ||
                  regData.childCareAssistance === "No") && (
                  <label>
                    Do you have a disability / long-term health condition?
                    <SelectField
                      // id="disability"
                      value={regData.disability}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          disability: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Select your option
                      </option>
                      <option>Yes</option>
                      <option>No</option>
                    </SelectField>
                  </label>
                )}
              </div>

              {regData.childCareAssistance === "No" &&
                regData.disability === "Yes" && (
                  <>
                    <div className="form-line form-double">
                      <label>
                        Specify Disability
                        {regData.specificDisability === "Others" ? (
                          <InputField
                            type="text"
                            autoFocus
                            placeholder="Specific Other Disability"
                            className="entry"
                            value={regData.specificDisability1}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                specificDisability1: e.target.value,
                              });
                            }}
                            // required
                          ></InputField>
                        ) : (
                          <SelectField
                            id="universal-credit"
                            value={regData.specificDisability}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                specificDisability: e.target.value,
                              });
                            }}
                          >
                            <option selected disabled value="">
                              Please specify the disability you identify with
                            </option>
                            {SpecificDisability.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </SelectField>
                        )}
                      </label>

                      <label>
                        Will you require or are you already receiving any
                        support/reasonable adjustments or because of a physical
                        disability, learning difficulties or have additional
                        learning needs?
                        {regData.disabilitySupport === "Yes" ? (
                          <InputField
                            type="text"
                            autoFocus
                            placeholder="Specific Disability Support"
                            className="entry"
                            value={regData.disabilitySupport1}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                disabilitySupport1: e.target.value,
                              });
                            }}
                            // required
                          ></InputField>
                        ) : (
                          <SelectField
                            // id="childcare"
                            value={regData.disabilitySupport}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                disabilitySupport: e.target.value,
                              });
                            }}
                          >
                            <option selected disabled value="">
                              Disability Support/Reasonable Adjustments
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                          </SelectField>
                        )}
                      </label>
                    </div>
                    <div>
                      <label>
                        Please upload a proof of being diagnosed of this/these
                        disabilities
                        <ImageUploading
                          // multiple
                          value={files1}
                          onChange={onChangeImage1}
                          onError={onErrorImage1}
                          maxNumber={maxNumber}
                          allowNonImageType={true}
                          maxFileSize={5000000}
                          dataURLKey="data_url"
                          // acceptType={["jpg"]}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="form-line form-button-row">
                              <CustomDiv
                                className="upload-button"
                                background="#fff"
                                color="#636969"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <img
                                  className="upload-button button-icon"
                                  src={UploadButtons[0]}
                                  alt="attach icon"
                                />
                                <i>Attach file</i>
                              </CustomDiv>
                              <p className="upload-paragraph">or</p>
                              <CustomDiv
                                // id="upload-button"
                                className="upload-button"
                                background="#fff"
                                color="#636969"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <img
                                  className="button-icon"
                                  src={UploadButtons[1]}
                                  alt="Drop File Icon"
                                />
                                <i>Drop file here</i>
                              </CustomDiv>
                              &nbsp;
                              {files1 !== null && (
                                <p className="upload-paragraph">
                                  File Uploaded Succesfully
                                </p>
                              )}
                            </div>
                          )}
                        </ImageUploading>
                      </label>
                    </div>
                  </>
                )}

              {regData.childCareAssistance === "Yes" && (
                <>
                  <label>
                    Do you have a disability / long-term health condition?
                    <SelectField
                      // id="disability"
                      value={regData.disability}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          disability: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Select your option
                      </option>
                      <option>Yes</option>
                      <option>No</option>
                    </SelectField>
                  </label>

                  {regData.disability === "Yes" && (
                    <>
                      <div className="form-line form-double">
                        <label>
                          Specify Disability
                          {regData.specificDisability === "Others" ? (
                            <InputField
                              type="text"
                              autoFocus
                              placeholder="Specific Other Disability"
                              className="entry"
                              value={regData.specificDisability1}
                              onChange={(e) => {
                                setRegData({
                                  ...regData,
                                  specificDisability1: e.target.value,
                                });
                              }}
                              // required
                            ></InputField>
                          ) : (
                            <SelectField
                              // id="universal-credit"
                              value={regData.specificDisability}
                              onChange={(e) => {
                                setRegData({
                                  ...regData,
                                  specificDisability: e.target.value,
                                });
                              }}
                            >
                              <option selected disabled value="">
                                Please specify the disability you identify with
                              </option>
                              {SpecificDisability.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </SelectField>
                          )}
                        </label>

                        <label>
                          Will you require or are you already receiving any
                          support/reasonable adjustments or because of a
                          physical disability, learning difficulties or have
                          additional learning needs?
                          {regData.disabilitySupport === "Yes" ? (
                            <InputField
                              type="text"
                              autoFocus
                              placeholder="Specific Disability Support"
                              className="entry"
                              value={regData.disabilitySupport1}
                              onChange={(e) => {
                                setRegData({
                                  ...regData,
                                  disabilitySupport1: e.target.value,
                                });
                              }}
                              // required
                            ></InputField>
                          ) : (
                            <SelectField
                              // id="childcare"
                              value={regData.disabilitySupport}
                              onChange={(e) => {
                                setRegData({
                                  ...regData,
                                  disabilitySupport: e.target.value,
                                });
                              }}
                            >
                              <option selected disabled value="">
                                Disability Support/Reasonable Adjustments
                              </option>
                              <option>Yes</option>
                              <option>No</option>
                            </SelectField>
                          )}
                        </label>
                      </div>
                      <div>
                        <label>
                          Please upload a proof of being diagnosed of this/these
                          disabilities
                          <ImageUploading
                            // multiple
                            value={files1}
                            onChange={onChangeImage1}
                            maxNumber={maxNumber}
                            onError={onErrorImage1}
                            allowNonImageType={true}
                            maxFileSize={5000000}
                            dataURLKey="data_url"
                            // acceptType={["jpg"]}
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                            }) => (
                              // write your building UI
                              <div className="form-line form-button-row">
                                <CustomDiv
                                  className="upload-button"
                                  background="#fff"
                                  color="#636969"
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <img
                                    className="upload-button button-icon"
                                    src={UploadButtons[0]}
                                    alt="attach icon"
                                  />
                                  <i>Attach file</i>
                                </CustomDiv>
                                <p className="upload-paragraph">or</p>
                                <CustomDiv
                                  // id="upload-button"
                                  className="upload-button"
                                  background="#fff"
                                  color="#636969"
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <img
                                    className="button-icon"
                                    src={UploadButtons[1]}
                                    alt="Drop File Icon"
                                  />
                                  <i>Drop file here</i>
                                </CustomDiv>
                                &nbsp;
                                {files1 !== null && (
                                  <p className="upload-paragraph">
                                    File Uploaded Succesfully
                                  </p>
                                )}
                              </div>
                            )}
                          </ImageUploading>
                        </label>
                      </div>
                    </>
                  )}
                </>
              )}

              <label>
                NIN (National Insurance Number)
                <InputField
                  type="text"
                  placeholder="NIN Number"
                  name="ninNo"
                  className={errors.ninNo && touched.ninNo ? "err" : "entry"}
                  value={regData.ninNo}
                  onChange={handleChange}
                  // required
                ></InputField>
              </label>

              <div className="form-line form-double">
                <label>
                  Current Employment Status
                  {regData.employmentStatus === "Others" ? (
                    <InputField
                      type="text"
                      autoFocus
                      value={regData.employmentStatus}
                      placeholder="Specify if Others"
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          employmentStatus: e.target.value,
                        });
                      }}
                      // required
                    ></InputField>
                  ) : (
                    <SelectField
                      defaultValue="select"
                      value={regData.employmentStatus}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          employmentStatus: e.target.value,
                        });
                      }}
                      // required
                    >
                      <option selected disabled value="">
                        select an option
                      </option>
                      {CurrentEmploymentStatus?.map((item, index) => {
                        return (
                          <>
                            <option
                              className="dropdown-option"
                              key={index}
                              value={item}
                            >
                              {item}
                            </option>
                          </>
                        );
                      })}
                    </SelectField>
                  )}
                </label>

                <label>
                  If you are employed, what is the name of your current
                  employer? (Name of company)
                  <InputField
                    type="text"
                    autoFocus
                    name="employerName"
                    placeholder="Name of company"
                    className={
                      errors.employerName && touched.employerName
                        ? "err"
                        : "entry"
                    }
                    value={regData.employerName}
                    onChange={(e) => {
                      setRegData({
                        ...regData,
                        employerName: e.target.value,
                      });
                    }}
                    // required
                  ></InputField>
                  {/* {formik.errors.employerName && touched.employerName && (
                    <p>employerName is Required</p>
                  )} */}
                </label>
              </div>

              <div className="form-line form-double">
                <label>
                  What is the postcode of your main workplace? (Employer
                  postcode)
                  <InputField
                    type="text"
                    autoFocus
                    placeholder="Employer
                          postcode"
                    name="employerPostcode"
                    className={
                      errors.employerPostcode && touched.employerPostcode
                        ? "err"
                        : "entry"
                    }
                    value={regData.employerPostcode}
                    onChange={(e) => {
                      setRegData({
                        ...regData,
                        employerPostcode: e.target.value,
                      });
                    }}
                    // required
                  ></InputField>
                </label>

                <label>
                  Have you applied to participate in the Bootcamp through your
                  current employer?
                  <SelectField
                    name="throughEmployer"
                    className={
                      errors.throughEmployer && touched.throughEmployer
                        ? "err"
                        : "entry"
                    }
                    value={regData.throughEmployer}
                    onChange={(e) => {
                      setRegData({
                        ...regData,
                        throughEmployer: e.target.value,
                      });
                    }}
                  >
                    <option selected disabled value="">
                      Select an option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </SelectField>
                </label>
              </div>

              <div className="form-line form-double">
                <label>
                  Are you currently claiming Universal Credit?
                  <SelectField
                    // id="universal-credit"
                    value={regData.universalCredit}
                    style={{ marginTop: "22px" }}
                    onChange={(e) => {
                      setRegData({
                        ...regData,
                        universalCredit: e.target.value,
                      });
                    }}
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </SelectField>
                </label>
                {regData.universalCredit === "Yes" && (
                  <>
                    <label>
                      If your answer to the previous question is yes, please
                      select which benefit you are in receipt of
                      {regData.universalCredit1 === "Other Benefits" ? (
                        <InputField
                          type="text"
                          autoFocus
                          placeholder="Specify your answer"
                          // id="full-name"
                          className="entry"
                          value={regData.otherUniversalCredit1}
                          onChange={(e) => {
                            setRegData({
                              ...regData,
                              otherUniversalCredit1: e.target.value,
                            });
                          }}
                          // required
                        ></InputField>
                      ) : (
                        <SelectField
                          // id="universal-credit"
                          value={regData.universalCredit1}
                          style={{ marginTop: "22px" }}
                          onChange={(e) => {
                            setRegData({
                              ...regData,
                              universalCredit1: e.target.value,
                            });
                          }}
                        >
                          <option selected disabled value="">
                            Select your option
                          </option>
                          {SpecificBenefit.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </SelectField>
                      )}
                    </label>
                  </>
                )}

                {(!regData.universalCredit ||
                  regData.universalCredit === "No") && (
                  <label>
                    If selected on the programme, will you require assistance
                    with childcare?
                    <SelectField
                      // id="childcare"
                      value={regData.childCareAssistance2}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          childCareAssistance2: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Select your option
                      </option>
                      <option>Yes</option>
                      <option>No</option>
                    </SelectField>
                  </label>
                )}
              </div>

              {regData.universalCredit === "Yes" && (
                <div className="form-line">
                  <label>
                    If selected on the programme, will you require assistance
                    with childcare?
                    <SelectField
                      // id="childcare"
                      value={regData.childCareAssistance2}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          childCareAssistance2: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Select your option
                      </option>
                      <option>Yes</option>
                      <option>No</option>
                    </SelectField>
                  </label>
                </div>
              )}

              <div className="form-line form-double">
                <label>
                  Do you have 5 GCSEs A-C, 9-4 or the equivalent?
                  <SelectField
                    name="schoolCert"
                    value={values.schoolCert}
                    onChange={handleChange}
                    className={
                      errors.schoolCert && touched.schoolCert ? "err" : "entry"
                    }
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </SelectField>
                  {errors.schoolCert && touched.schoolCert && (
                    <p>schoolCert is Required</p>
                  )}
                </label>
                <label>
                  If selected on the programme, do you have a laptop that you
                  can use for the course?
                  <SelectField
                    name="ownLaptop"
                    value={values.ownLaptop}
                    onChange={handleChange}
                    className={
                      errors.ownLaptop && touched.ownLaptop ? "err" : "entry"
                    }
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </SelectField>
                  {errors.ownLaptop && touched.ownLaptop && (
                    <p>ownLaptop is Required</p>
                  )}
                </label>
              </div>

              <div className="form-line form-double">
                {/* <label>
                  Have you participated in any Niyo Bootcamp in the past?
                  <SelectField
                    name="pastBootcamp"
                    value={values.pastBootcamp}
                    onChange={handleChange}
                    className={
                      errors.pastBootcamp && touched.pastBootcamp
                        ? "err"
                        : "entry"
                    }
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </SelectField>
                  {errors.pastBootcamp && touched.pastBootcamp && (
                    <p>pastBootcamp is Required</p>
                  )}
                </label> */}

                <label>
                  Do you have any previous experience that will help you with
                  completing the Bootcamp?
                  <InputField
                    name="prevExperience"
                    value={values.prevExperience}
                    onChange={handleChange}
                    className={
                      errors.prevExperience && touched.prevExperience
                        ? "err"
                        : "input-field"
                    }
                    placeholder="My previous experience"
                  ></InputField>
                  {errors.prevExperience && touched.prevExperience && (
                    <p>prevExperience is Required</p>
                  )}
                </label>

                <label>
                  What is the highest level of study that you have completed or
                  on track to complete?
                  <SelectField
                    value={regData.studyLevel}
                    onChange={(e) => {
                      setRegData({ ...regData, studyLevel: e.target.value });
                    }}
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    {StudyLevel.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </SelectField>
                </label>
              </div>

              {regData?.studyLevel?.includes("Level 8") && (
                <div className="form-line">
                  <label>
                    If you have completed Level 6 qualification or above please
                    select which subject.
                    <SelectField
                      // id="gcses"
                      value={regData.studySubject}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          studySubject: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Select your option
                      </option>
                      {Subject.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </SelectField>
                  </label>
                </div>
              )}

              {regData?.studyLevel?.includes("Level 6") && (
                <div className="form-line">
                  <label>
                    If you have completed Level 6 qualification or above please
                    select which subject.
                    <SelectField
                      // id="gcses"
                      value={regData.studySubject}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          studySubject: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Select your option
                      </option>
                      {Subject.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </SelectField>
                  </label>
                </div>
              )}

              {regData?.studyLevel?.includes("Level 7") && (
                <div className="form-line">
                  <label>
                    If you have completed Level 6 qualification or above please
                    select which subject.
                    <SelectField
                      // id="gcses"
                      value={regData.studySubject}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          studySubject: e.target.value,
                        });
                      }}
                    >
                      <option selected disabled value="">
                        Select your option
                      </option>
                      {Subject.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </SelectField>
                  </label>
                </div>
              )}

              {location === "Yes" && (
                <>
                  <div className="form-line">
                    <label>
                      Are you eligible to work in the United Kingdom?
                      <SelectField name="location" value={location}>
                        <option selected disabled value="">
                          Yes (Answer gotten from eligibility form data)
                        </option>
                      </SelectField>
                    </label>
                  </div>

                  <div>
                    <label>
                      Attach Proof of Work eligibility.
                      <ImageUploading
                        // multiple
                        value={files}
                        onChange={onChangeImage}
                        maxNumber={maxNumber}
                        onError={onErrorImage}
                        allowNonImageType={true}
                        maxFileSize={5000000}
                        dataURLKey="data_url"
                        // acceptType={["jpg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="form-line form-button-row">
                            <CustomDiv
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="upload-button button-icon"
                                src={UploadButtons[0]}
                                alt="attach icon"
                              />
                              <i>Attach file</i>
                            </CustomDiv>
                            <p className="upload-paragraph">or</p>
                            <CustomDiv
                              // id="upload-button"
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="button-icon"
                                src={UploadButtons[1]}
                                alt="Drop File Icon"
                              />
                              <i>Drop file here</i>
                            </CustomDiv>
                            &nbsp;
                            {files !== null && (
                              <p className="upload-paragraph">
                                File Uploaded Succesfully
                              </p>
                            )}
                          </div>
                        )}
                      </ImageUploading>
                    </label>
                  </div>
                </>
              )}

              <div>
                <label>
                  Please attach your CV
                  <ImageUploading
                    // multiple
                    value={files4}
                    onChange={onChangeImage4}
                    onError={onErrorImage4}
                    maxNumber={maxNumber}
                    allowNonImageType={true}
                    maxFileSize={5000000}
                    dataURLKey="data_url"
                    // acceptType={["jpg"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="form-line form-button-row">
                        <CustomDiv
                          className="upload-button"
                          background="#fff"
                          color="#636969"
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <img
                            className="upload-button button-icon"
                            src={UploadButtons[0]}
                            alt="attach icon"
                          />
                          <i>Attach file</i>
                        </CustomDiv>
                        <p className="upload-paragraph">or</p>
                        <CustomDiv
                          // id="upload-button"
                          className="upload-button"
                          background="#fff"
                          color="#636969"
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <img
                            className="button-icon"
                            src={UploadButtons[1]}
                            alt="Drop File Icon"
                          />
                          <i>Drop file here</i>
                        </CustomDiv>
                        &nbsp;
                        {files4 !== null && (
                          <p className="upload-paragraph">
                            File Uploaded Succesfully
                          </p>
                        )}
                      </div>
                    )}
                  </ImageUploading>
                </label>
              </div>

              {bootcamp === "Tech Bootcamp for Foundhers" && (
                <>
                  <div className="form-line form-double">
                    <label style={{ marginTop: "60px" }}>
                      Founhers (Role Title)
                      {regData.roleTitle === "Others" ? (
                        <InputField
                          type="text"
                          placeholder="Role is Other, please specify"
                          // id="email"
                          className="entry"
                          value={regData.roleTitle1}
                          onChange={(e) => {
                            setRegData({
                              ...regData,
                              roleTitle1: e.target.value,
                            });
                          }}
                          // required
                        ></InputField>
                      ) : (
                        <SelectField
                          // id="gcses"
                          value={regData.roleTitle}
                          onChange={(e) => {
                            setRegData({
                              ...regData,
                              roleTitle: e.target.value,
                            });
                          }}
                        >
                          <option selected disabled value="">
                            Select an Option
                          </option>
                          {RoleTitle.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </SelectField>
                      )}
                    </label>

                    <label>
                      How many founders are involved in your company? Please
                      note only a max of TWO representatives will be able to
                      participate on the bootcamp
                      <SelectField
                        // id="laptop"
                        value={regData.noFoundher}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            noFoundher: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {NoFoundher.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label style={{ marginTop: "38px" }}>
                      How many people are in your team (excluding founders)?
                      <SelectField
                        // id="gcses"
                        value={regData.teamMembers}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            teamMembers: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select an Option
                        </option>
                        {TeamMembers.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>

                    <label>
                      Which members of your team will participate in the
                      programme if your application is successful? Please note
                      only a max of TWO representatives will be able to
                      participate on the bootcamp
                      <SelectField
                        // id="laptop"
                        value={regData.teamMembersParticaption}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            teamMembersParticaption: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {TeamMembersParticaption.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      Is your business a registered company?
                      <SelectField
                        // id="gcses"
                        value={regData.busReg}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            busReg: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select an Option
                        </option>
                        <option>Yes</option>
                        <option>No</option>
                      </SelectField>
                    </label>

                    {regData.busReg === "Yes" && (
                      <label>
                        What is your Company Registration number?
                        <InputField
                          type="text"
                          placeholder="Company Number"
                          className="entry"
                          value={regData.busReg1}
                          onChange={(e) => {
                            setRegData({
                              ...regData,
                              busReg1: e.target.value,
                            });
                          }}
                          // required
                        ></InputField>
                      </label>
                    )}
                    {(regData.busReg === "No" || !regData.busReg) && (
                      <label>
                        What is your business structure?
                        {regData.businessStructure === "Others" ? (
                          <InputField
                            type="text"
                            placeholder="Other, please specify"
                            // id="email"
                            className="entry"
                            value={regData.businessStructure1}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                businessStructure1: e.target.value,
                              });
                            }}
                            // required
                          ></InputField>
                        ) : (
                          <SelectField
                            // id="gcses"
                            value={regData.businessStructure}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                businessStructure: e.target.value,
                              });
                            }}
                          >
                            <option selected disabled value="">
                              Select an Option
                            </option>
                            {BusinessStructure.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </SelectField>
                        )}
                      </label>
                    )}
                  </div>
                  {regData.busReg === "Yes" && (
                    <div className="form-line ">
                      <label>
                        What is your business structure?
                        {regData.businessStructure === "Others" ? (
                          <InputField
                            type="text"
                            placeholder="Other, please specify"
                            // id="email"
                            className="entry"
                            value={regData.businessStructure1}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                businessStructure1: e.target.value,
                              });
                            }}
                            // required
                          ></InputField>
                        ) : (
                          <SelectField
                            // id="gcses"
                            value={regData.businessStructure}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                businessStructure: e.target.value,
                              });
                            }}
                          >
                            <option selected disabled value="">
                              Select an Option
                            </option>
                            {BusinessStructure.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </SelectField>
                        )}
                      </label>
                    </div>
                  )}

                  <div className="form-line">
                    <label>
                      How much funding have you received on your business
                      journey so far?
                      <SelectField
                        // id="laptop"
                        value={regData.fundingReceived}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            fundingReceived: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {FundingReceived.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      What is your business/company name? If not registered,
                      please put the name of your idea or business venture
                      <InputField
                        type="text"
                        placeholder="Company Name"
                        // id="text"
                        className="entry"
                        value={regData.companyName}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            companyName: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                    </label>

                    <label style={{ marginTop: "21px" }}>
                      What Sector/Industry does your business/company operate
                      within?
                      <SelectField
                        // id="laptop"
                        value={regData.businessSector}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            businessSector: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {BusinessSector.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label style={{ marginTop: "20px" }}>
                      Company Website(If you do not have any, simply put N/A)
                      <InputField
                        type="text"
                        placeholder="Company Website"
                        className="entry"
                        value={regData.companyWebsite}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            companyWebsite: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                    </label>

                    <label>
                      Business/Company Social Media Pages/handles. LinkedIn,
                      Instagram, Twitter, Facebook If you do not have any,
                      simply put N/A
                      <InputField
                        type="text"
                        placeholder="Socila Links"
                        className="entry"
                        value={regData.socialHandles}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            socialHandles: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      Where in the UK is your company/business based?
                      <InputField
                        type="text"
                        placeholder="Company Location"
                        className="entry"
                        value={regData.companyLocation}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            companLocation: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                    </label>

                    <label>
                      When did you/are you planning to launch your business?
                      <div style={{ paddingTop: "5px" }}>
                        <DatePicker onChange={onChange} />
                      </div>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label style={{ marginTop: "38px" }}>
                      What stage is your business at currently?
                      <SelectField
                        // id="gcses"
                        value={regData.businessStage}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            businessStage: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select an Option
                        </option>
                        {BusinessStage.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>

                    <label>
                      How frequent is the use of technology in your business
                      when delivering your product and/or solutions?
                      <SelectField
                        // id="laptop"
                        value={regData.technologyUse}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            technologyUse: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {TechnologyUse.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div>
                    <label>
                      Business Pitch Video: Please upload a 5-minute video
                      youtub link explaining your business idea. Video
                      Instructions: 1. Your video should not exceed 5mins and
                      should detail your idea/business pitch. 2. Please make
                      sure your video is a youtube link. Assessment Rubrics:
                      Applications will be marked on the following criteria: 1.
                      Business Scalability: Will this person be able to scale
                      their idea? 2. Go to market: Will they be able to go to
                      market after 6 months or increase in sales at scale after
                      6 months? 3. Innovation: How disruptive is the idea? Does
                      it make use of Tech or software to innovate OR is this
                      idea a software/hardware that can disrupt in its unique
                      industry?
                      <InputField
                        type="text"
                        placeholder="Picth Video Link"
                        className="entry"
                        value={regData.businessPitchVideoUrl}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            businessPitchVideoUrl: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                      {/* <ImageUploading
                        // multiple
                        value={files3}
                        onChange={onChangeImage3}
                        maxNumber={maxNumber}
                        allowNonImageType={true}
                        dataURLKey="data_url"
                        // acceptType={["jpg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="form-line form-button-row">
                            <CustomDiv
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="upload-button button-icon"
                                src={UploadButtons[0]}
                                alt="attach icon"
                              />
                              <i>Attach file</i>
                            </CustomDiv>
                            <p className="upload-paragraph">or</p>
                            <CustomDiv
                              id="upload-button"
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="button-icon"
                                src={UploadButtons[1]}
                                alt="Drop File Icon"
                              />
                              <i>Drop file here</i>
                            </CustomDiv>
                            &nbsp;
                            {files3 !== null && (
                              <p className="upload-paragraph">
                                File Uploaded Succesfully
                              </p>
                            )}
                          </div>
                        )}
                      </ImageUploading> */}
                    </label>
                  </div>

                  <div className="form-line form-links-row">
                    <label>
                      Business Pitch Deck Please upload your business/company
                      pitch deck in a presentation slide. If you don't have a
                      pitch deck, that's fine, please download the slides on the
                      link below. Complete it, and upload it for your pitch
                      deck:
                      <CardParagraph className="link-paragraph">
                        <a
                          style={{ overflowWrap: "break-word" }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/presentation/d/1TLVzjrCwMt0n6I11DZcU3teZOpSRldOs/edit?usp=sharing&ouid=115523401313808643343&rtpof=true&sd=true"
                        >
                          https://docs.google.com/presentation/d/1TLVzjrCwMt0n6I11DZcU3teZOpSRldOs/edit?usp=sharing&ouid=115523401313808643343&rtpof=true&sd=true
                        </a>
                      </CardParagraph>
                      <ImageUploading
                        // multiple
                        value={files2}
                        onChange={onChangeImage2}
                        onError={onErrorImage2}
                        maxNumber={maxNumber}
                        maxFileSize={5000000}
                        allowNonImageType={true}
                        dataURLKey="data_url"
                        // acceptType={["jpg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="form-line form-button-row">
                            <CustomDiv
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="upload-button button-icon"
                                src={UploadButtons[0]}
                                alt="attach icon"
                              />
                              <i>Attach file</i>
                            </CustomDiv>
                            <p className="upload-paragraph">or</p>
                            <CustomDiv
                              // id="upload-button"
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="button-icon"
                                src={UploadButtons[1]}
                                alt="Drop File Icon"
                              />
                              <i>Drop file here</i>
                            </CustomDiv>
                            &nbsp;
                            {files2 !== null && (
                              <p className="upload-paragraph">
                                File Uploaded Succesfully
                              </p>
                            )}
                          </div>
                        )}
                      </ImageUploading>
                    </label>
                  </div>
                </>
              )}

              {bootcamp === "Marketing for Digital Entrepreneurship" && (
                <>
                  <div className="form-line form-double">
                    <label style={{ marginTop: "60px" }}>
                      Founhers (Role Title)
                      {regData.roleTitle === "Others" ? (
                        <InputField
                          type="text"
                          placeholder="Role is Other, please specify"
                          // id="email"
                          className="entry"
                          value={regData.roleTitle1}
                          onChange={(e) => {
                            setRegData({
                              ...regData,
                              roleTitle1: e.target.value,
                            });
                          }}
                          // required
                        ></InputField>
                      ) : (
                        <SelectField
                          // id="gcses"
                          value={regData.roleTitle}
                          onChange={(e) => {
                            setRegData({
                              ...regData,
                              roleTitle: e.target.value,
                            });
                          }}
                        >
                          <option selected disabled value="">
                            Select an Option
                          </option>
                          {RoleTitle.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </SelectField>
                      )}
                    </label>

                    <label>
                      How many founders are involved in your company? Please
                      note only a max of TWO representatives will be able to
                      participate on the bootcamp
                      <SelectField
                        // id="laptop"
                        value={regData.noFoundher}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            noFoundher: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {NoFoundher.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label style={{ marginTop: "38px" }}>
                      How many people are in your team (excluding founders)?
                      <SelectField
                        // id="gcses"
                        value={regData.teamMembers}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            teamMembers: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select an Option
                        </option>
                        {TeamMembers.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>

                    <label>
                      Which members of your team will participate in the
                      programme if your application is successful? Please note
                      only a max of TWO representatives will be able to
                      participate on the bootcamp
                      <SelectField
                        // id="laptop"
                        value={regData.teamMembersParticaption}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            teamMembersParticaption: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {TeamMembersParticaption.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      Is your business a registered company?
                      <SelectField
                        // id="gcses"
                        value={regData.busReg}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            busReg: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select an Option
                        </option>
                        <option>Yes</option>
                        <option>No</option>
                      </SelectField>
                    </label>

                    {regData.busReg === "Yes" && (
                      <label>
                        What is your Company Registration number?
                        <InputField
                          type="text"
                          placeholder="Company Number"
                          className="entry"
                          value={regData.busReg1}
                          onChange={(e) => {
                            setRegData({
                              ...regData,
                              busReg1: e.target.value,
                            });
                          }}
                          // required
                        ></InputField>
                      </label>
                    )}
                    {(regData.busReg === "No" || !regData.busReg) && (
                      <label>
                        What is your business structure?
                        {regData.businessStructure === "Others" ? (
                          <InputField
                            type="text"
                            placeholder="Other, please specify"
                            // id="email"
                            className="entry"
                            value={regData.businessStructure1}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                businessStructure1: e.target.value,
                              });
                            }}
                            // required
                          ></InputField>
                        ) : (
                          <SelectField
                            // id="gcses"
                            value={regData.businessStructure}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                businessStructure: e.target.value,
                              });
                            }}
                          >
                            <option selected disabled value="">
                              Select an Option
                            </option>
                            {BusinessStructure.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </SelectField>
                        )}
                      </label>
                    )}
                  </div>
                  {regData.busReg === "Yes" && (
                    <div className="form-line ">
                      <label>
                        What is your business structure?
                        {regData.businessStructure === "Others" ? (
                          <InputField
                            type="text"
                            placeholder="Other, please specify"
                            // id="email"
                            className="entry"
                            value={regData.businessStructure1}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                businessStructure1: e.target.value,
                              });
                            }}
                            // required
                          ></InputField>
                        ) : (
                          <SelectField
                            // id="gcses"
                            value={regData.businessStructure}
                            onChange={(e) => {
                              setRegData({
                                ...regData,
                                businessStructure: e.target.value,
                              });
                            }}
                          >
                            <option selected disabled value="">
                              Select an Option
                            </option>
                            {BusinessStructure.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </SelectField>
                        )}
                      </label>
                    </div>
                  )}

                  <div className="form-line">
                    <label>
                      How much funding have you received on your business
                      journey so far?
                      <SelectField
                        // id="laptop"
                        value={regData.fundingReceived}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            fundingReceived: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {FundingReceived.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      What is your business/company name? If not registered,
                      please put the name of your idea or business venture
                      <InputField
                        type="text"
                        placeholder="Company Name"
                        // id="text"
                        className="entry"
                        value={regData.companyName}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            companyName: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                    </label>

                    <label style={{ marginTop: "21px" }}>
                      What Sector/Industry does your business/company operate
                      within?
                      <SelectField
                        // id="laptop"
                        value={regData.businessSector}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            businessSector: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {BusinessSector.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label style={{ marginTop: "20px" }}>
                      Company Website(If you do not have any, simply put N/A)
                      <InputField
                        type="text"
                        placeholder="Company Website"
                        className="entry"
                        value={regData.companyWebsite}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            companyWebsite: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                    </label>

                    <label>
                      Business/Company Social Media Pages/handles. LinkedIn,
                      Instagram, Twitter, Facebook If you do not have any,
                      simply put N/A
                      <InputField
                        type="text"
                        placeholder="Socila Links"
                        className="entry"
                        value={regData.socialHandles}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            socialHandles: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      Where in the UK is your company/business based?
                      <InputField
                        type="text"
                        placeholder="Company Location"
                        className="entry"
                        value={regData.companyLocation}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            companLocation: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                    </label>

                    <label>
                      When did you/are you planning to launch your business?
                      <div style={{ paddingTop: "5px" }}>
                        <DatePicker onChange={onChange} />
                      </div>
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label style={{ marginTop: "38px" }}>
                      What stage is your business at currently?
                      <SelectField
                        // id="gcses"
                        value={regData.businessStage}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            businessStage: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select an Option
                        </option>
                        {BusinessStage.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>

                    <label>
                      How frequent is the use of technology in your business
                      when delivering your product and/or solutions?
                      <SelectField
                        // id="laptop"
                        value={regData.technologyUse}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            technologyUse: e.target.value,
                          });
                        }}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        {TechnologyUse.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectField>
                    </label>
                  </div>

                  <div>
                    <label>
                      Business Pitch Video: Please upload a 5-minute video
                      youtub link explaining your business idea. Video
                      Instructions: 1. Your video should not exceed 5mins and
                      should detail your idea/business pitch. 2. Please make
                      sure your video is a youtube link. Assessment Rubrics:
                      Applications will be marked on the following criteria: 1.
                      Business Scalability: Will this person be able to scale
                      their idea? 2. Go to market: Will they be able to go to
                      market after 6 months or increase in sales at scale after
                      6 months? 3. Innovation: How disruptive is the idea? Does
                      it make use of Tech or software to innovate OR is this
                      idea a software/hardware that can disrupt in its unique
                      industry?
                      <InputField
                        type="text"
                        placeholder="Picth Video Link"
                        className="entry"
                        value={regData.businessPitchVideoUrl}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            businessPitchVideoUrl: e.target.value,
                          });
                        }}
                        // required
                      ></InputField>
                      {/* <ImageUploading
                        // multiple
                        value={files3}
                        onChange={onChangeImage3}
                        maxNumber={maxNumber}
                        allowNonImageType={true}
                        dataURLKey="data_url"
                        // acceptType={["jpg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="form-line form-button-row">
                            <CustomDiv
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="upload-button button-icon"
                                src={UploadButtons[0]}
                                alt="attach icon"
                              />
                              <i>Attach file</i>
                            </CustomDiv>
                            <p className="upload-paragraph">or</p>
                            <CustomDiv
                              id="upload-button"
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="button-icon"
                                src={UploadButtons[1]}
                                alt="Drop File Icon"
                              />
                              <i>Drop file here</i>
                            </CustomDiv>
                            &nbsp;
                            {files3 !== null && (
                              <p className="upload-paragraph">
                                File Uploaded Succesfully
                              </p>
                            )}
                          </div>
                        )}
                      </ImageUploading> */}
                    </label>
                  </div>

                  <div className="form-line form-links-row">
                    <label>
                      Business Pitch Deck Please upload your business/company
                      pitch deck in a presentation slide. If you don't have a
                      pitch deck, that's fine, please download the slides on the
                      link below. Complete it, and upload it for your pitch
                      deck:
                      <CardParagraph className="link-paragraph">
                        <a
                          style={{ overflowWrap: "break-word" }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/presentation/d/1TLVzjrCwMt0n6I11DZcU3teZOpSRldOs/edit?usp=sharing&ouid=115523401313808643343&rtpof=true&sd=true"
                        >
                          https://docs.google.com/presentation/d/1TLVzjrCwMt0n6I11DZcU3teZOpSRldOs/edit?usp=sharing&ouid=115523401313808643343&rtpof=true&sd=true
                        </a>
                      </CardParagraph>
                      <ImageUploading
                        // multiple
                        value={files2}
                        onChange={onChangeImage2}
                        onError={onErrorImage2}
                        maxNumber={maxNumber}
                        maxFileSize={5000000}
                        allowNonImageType={true}
                        dataURLKey="data_url"
                        // acceptType={["jpg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="form-line form-button-row">
                            <CustomDiv
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="upload-button button-icon"
                                src={UploadButtons[0]}
                                alt="attach icon"
                              />
                              <i>Attach file</i>
                            </CustomDiv>
                            <p className="upload-paragraph">or</p>
                            <CustomDiv
                              // id="upload-button"
                              className="upload-button"
                              background="#fff"
                              color="#636969"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="button-icon"
                                src={UploadButtons[1]}
                                alt="Drop File Icon"
                              />
                              <i>Drop file here</i>
                            </CustomDiv>
                            &nbsp;
                            {files2 !== null && (
                              <p className="upload-paragraph">
                                File Uploaded Succesfully
                              </p>
                            )}
                          </div>
                        )}
                      </ImageUploading>
                    </label>
                  </div>
                </>
              )}

              {bootcamp === "Afro Hair with Digital Entrepreneurship" && (
                <>
                  <div className="form-line form-double">
                    <label>
                      Briefly highlight what you done to upskill yourself as a
                      hairstylist?
                      <TextareaField
                        height={"100px"}
                        type="text"
                        autoFocus
                        placeholder="Past Experience"
                        // id="full-name"
                        className="entry"
                        value={regData.previousHairstylingUpskill}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            previousHairstylingUpskill: e.target.value,
                          });
                        }}
                        // required
                      ></TextareaField>
                    </label>

                    <label>
                      Briefly explain how this Bootcamp will help you achieve
                      your personal goals?
                      <TextareaField
                        height={"100px"}
                        type="text"
                        autoFocus
                        placeholder="Bootcamp Benefits"
                        // id="full-name"
                        className="entry"
                        value={regData.bootcampAndPersonalGoalAlignment}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            bootcampAndPersonalGoalAlignment: e.target.value,
                          });
                        }}
                        // required
                      ></TextareaField>
                    </label>
                  </div>

                  <div className="form-line">
                    <label>
                      Why are you interested in doing this bootcamp
                      specifically?
                      <TextareaField
                        height={"100px"}
                        type="text"
                        autoFocus
                        placeholder="Bootcamp Interest"
                        // id="full-name"
                        className="entry"
                        value={regData.interestInBootcamp}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            interestInBootcamp: e.target.value,
                          });
                        }}
                        // required
                      ></TextareaField>
                    </label>
                  </div>
                </>
              )}
            </Container>

            <Container className="form-column form-column-3">
              <ul className="list-container">
                <li key="1">
                  Type in your eligibility code to get started. Your eligibility
                  code is in the email sent after completing the eligibility
                  check <br />
                  <br />
                  If you do not have it, please reach out to
                  admissionsupport@niyo.co. But before you do, check your
                  spam/junk/promotional folders.
                </li>
                <li key="2">
                  Our application only takes 30 minutes to fill out
                </li>
                <li key="3">
                  Our application is in two categories, please carefully fill
                  all inputs in both categories
                </li>
                <li key="4">
                  Application is only for candidates based in England.
                </li>
                <SpaceMedium />
              </ul>
            </Container>
          </Container>

          <ButtonSpace />
        </AccordionItemPanel>
      </RegistrationContainer>
    </>
  );
};

export default AdditionalInformation;
