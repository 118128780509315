import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

export const CommunityContainer = styled.div`
  padding: 0;
  margin: 0;
  width:90%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .community-preheading {
    padding: 0 0 0 11.5%;
  }

  .community-cards {
    display: flex;
    justify-content: space-evenly;
    margin: 0 0 0 0;

    .individual-card {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0;
      margin: 0;
      width: 23%;
      height: 450px;

      .card-image {
        height: auto;
        width: 100%;
        padding: 0;
        margin: 0;
        object-fit: cover;
      }

      .card-title {
        height: 10%;
        font-size: 25px;
      }

      .card-description {
        height: 20%;
        font-size: 13px;
      }

      .card-button {
        height: 10%;
      }
    }
  }

  @media ${breakpointSizes.mobile} {
    .community-preheading {
      text-align: center;
      padding: 0;
    }
    .community-cards {
      padding: 0;
      flex-wrap: wrap;

      .individual-card {
        width: 46%;
        margin: 0 0 10% 0;
        height: 310px;
        justify-content: unset;
        position: relative;

        .card-image {
          height: 130px;
          margin-bottom:10px;
        }

        .card-title {
          font-size: 20px;
          line-height: 20px;
        }

        .card-description {
          font-size: 11px;
        }
       
        .mobile-button {
          width: 110px;
          height: 34px;
          font-size: 12px;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }

  @media ${breakpointSizes.desktop} {
    .community-cards {
      .individual-card {
        width: 22%;
        height: 600px;

        .card-image {
          height: 60%;
          width: 100%;
        }

        .card-title {
          height: 10%;
        }

        .card-description {
          height: 15%;
        }
      }
    }
  }
`;


export const CompanyContainer = styled.div`
    li{
      margin-bottom:20px;
      a{
        color:#000;
        font-family:Medium;
      }
      a:hover{
        color:#FBAF00;
      }
    }
`

