import styled from "styled-components";

export const DetailsContainer = styled.div`
  .hero-container {
    min-height: 100vh;
    height: auto;
    background-image: none !important;
    z-index: -10;
    display: flex;

    .hero-right,
    .hero-left {
      padding: 0;
    }

    .hero-left {
      width: 45%;

      .individual-package {
        height: auto;
        color: #454a4a;

        ul {
          padding: 0 0 0 2%;
          width: 60%;

          li {
            padding: 0 0 10px 5%;
            color: #636969;
          }

          li::marker {
            color: #fbaf00;
            content: "■";
            font-size: 11px;
          }
        }
      }
    }

    .hero-right {
      width: 55%;
      padding: 0 2%;

      .form-line {
        width: 100%;
        padding: 2% 0;
        position: relative;

        label {
          font-size: 14px;
        }
      }

      .form-button-row {
        display: flex;
        padding: 5% 0;
        align-items: center;

        .upload-button {
          display: flex;
          justify-content: center;
          align-items: center;

          .button-icon {
            width: 30px;
            height: 40px;
          }
        }

        .upload-paragraph {
          padding: 0 5%;
        }

        .upload-button:nth-child(2) {
          margin: 0 5%;
        }
      }
    }

    .padding {
      padding: 0 0 3% 0;
    }
  }
`;
