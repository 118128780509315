import { Container, Heading, Paragraph } from "../../styles/StyledComponents";
import UpskillingContainer from "./style";

const Upskilling = () => {
  return (
    <>
      <UpskillingContainer>
        <Container className="upskilling-left">
          <Heading className="upskilling-heading">
            Upskilling people in <span>Tech</span>
          </Heading>
        </Container>
        <Container className="upskilling-right">
          <Paragraph className="upskilling-text">
            Niyo Bootcamps (previously known as Niyo Network) is our training
            ground. It aims to use skilled bootcamps in technology to equip and
            upskill unemployed individuals, at risk of unemployment, or in
            low-paid jobs. <br />
            <br /> Niyo creates opportunities that cater directly to their
            purposeful paths; whether it be employed in a high-impact industry
            or running their own disruptive business.
          </Paragraph>
        </Container>
      </UpskillingContainer>
    </>
  );
};

export default Upskilling;
