import styled from 'styled-components'
import { JobsImages } from '../../constants'
import breakpointSizes from '../../constants/breakpointSizes'

export const JobsContainer = styled.div`
  .hero-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: auto;

    .hero-left {
      width: 52%;
      margin: 0;
      padding: 5% 0 0 0;
    }

    .hero-right {
      width: 48%;
      margin: 0;
      padding: 0 0 5% 3%;
      display: flex;
      justify-content: center;

      .hero-image {
        background-image: url(${JobsImages.jobsHero});
        background-size: cover;
        width: 500px;
        height: 540px;
        margin: 0;
        padding: 0;
        border-radius: 30% 0px 30% 0px;
      }
    }
  }

  ul {
    padding: 0 0 0 2%;

    li {
      padding: 0 0 10px 10px;
      color: #636969;
      font-size: 17px;
      font-family: 'Light';
    }

    li::marker {
      color: #fbaf00;
      content: '■';
      font-size: 11px;
    }
  }

  .search-section {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #fff;
    position: relative;
    background-color: #fffcf4;

    .search-container {
      width: 80%;
      margin-top: 10px;
      height: 65px;
      background-color: #fff;
      position: absolute;
      display: flex;
      align-items: center;
      top: -30px;

      .option-bold {
        font-family: 'Medium';
        color: #636969;
      }

      .search-input {
        display: flex;
        flex-direction: row;
        width: 70%;
        height: 10px;
        margin-top:20px;
        align-items: center;
        border: none;
      }

      .filters {
        margin-top: 30px;
        position: absolute;
        right: 0;
        top: -34px;
      }
      .search-select {
        width: 25%;
        height: 50px;
        border: none;
        cursor: pointer;
        font-size: 12px;
        color: #070909;
        //
        font-family: 'Medium';
      }
    }
  }

  .jobs-section {
    display: flex;
    position: relative;

    .post-jobs {
      position: sticky;
      top: 20%;
      height: 76vh;
      width: 30%;
      padding: 2%;
      box-shadow: 2px 4px 14px rgba(99, 105, 105, 0.22);
      margin: 0 10% 0 0;

      li {
        font-size: 15px;
      }
    }

    .latest-jobs {
      width: 60%;
      padding: 0;
      margin: 0;

      .card-section {
        height: 100px;
        padding: 0 2% 0 0;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        justify-content: center;
      }

      .middle-section {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 75%;
        padding: 0 4%;

        .heading {
          align-self: flex-start;
        }

        .info-summary {
          display: flex;
          flex-direction: row;
          color: #454a4a;
          width: 100%;
          padding: 2% 0 0 0;

          .text {
            color: #454a4a;
          }

          .padding {
            border-right: 1px solid #454a4a;
            padding: 0 5% 0 0;
            margin: 0 2% 0 0;
          }
        }
      }

      .job-card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #ffedc2;
        padding: 12% 0 5% 0;
        margin: 1% 0;
        height: 90px;

        .niyo-logo {
          height: 45px;
          width: 45px;
        }
      }

      .job-card:first-child {
        border-top: 1px solid #ffedc2;
      }
    }
  }

  @media ${breakpointSizes.mobile} {
    .search-container {
      width: 80%;
      height: 65px;
      background-color: green;
      position: absolute;
      display: flex;
      align-items: center;
      top: -30px;

      .filters {
        margin-top: 30px;
        position: absolute;
        left: 0 !important;
        z-index: 500;
        top: 48px !important;
      }
      span {
        padding-top: 19px;
        padding-bottom: 19px;
      }
    }
    .search-select {
      width: 23%;

      background-color: #fff;
      min-height: 50px;
      border: none;
      cursor: pointer;
      font-size: 12px;
      color: #070909;
      font-family: 'Medium';
    }
    .hero-container {
      flex-direction: column;

      .hero-left {
        width: 100%;
        padding-bottom: 10%;

        .hero-button {
          width: 100%;
        }
      }

      .hero-right {
        width: 100%;
        padding: 0 0 10% 0;
      }
    }

    .jobs-section {
      margin-top: 40px;
      flex-direction: column;

      .post-jobs {
        width: 100%;
        margin: 0 0 10% 0;
      }

      .latest-jobs {
        width: 100%;
        position: relative;

        .mobile-hidden {
          display: none;
        }

        .job-card {
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          height: 130px;

          .card-section {
            width: 100%;
            padding: 0;
            align-items: flex-start;
          }

          .niyo-logo {
            height: 26px;
            width: 26px;
            position: absolute;
            left: 0;
          }

          .middle-section {
            .heading {
              font-size: 15px;
              line-height: 20px;
              text-align: left;
              left: 0;
            }

            .text {
              font-size: 11px;
              text-align: left;
              width: 28%;
            }
          }
        }
      }
    }

    .jobs-button {
      display: flex;
      justify-content: center;
    }
  }

  @media ${breakpointSizes.desktop} {
  }
`
