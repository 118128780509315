import {
  Heading,
  Container,
  CardParagraph,
  InputField,
  SelectField,
  SpaceMedium,
  CustomButton,
  ButtonSpace,
  Paragraph,
} from '../../styles/StyledComponents'

import { DatePicker } from 'antd'
import {
  CurrentEmploymentStatus,
  CurrentSalary,
  StudyLevel,
  Ethnicities,
  Industry,
  ChooseJobRole,
  ContinueWork,
  HoursWorked,
  Subject,
  web2BootcampInfo,
} from '../../constants'

import EligibilityContainer from './style'
import { SpaceSmall } from '../../styles/StyledComponents'

import eligibilityService from '../../service/eligibility.service'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { NotificationManager } from 'react-notifications'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Spin } from 'antd'
import sent from '../../assets/bootcamps/email.png'

import { useState } from 'react'
import { useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { createEligibleCode } from '../../api/eligibility'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const validationSchema = yup.object({
  lastName: yup.string().required('lastName is required'),
  firstName: yup.string().required('firstName is required'),
  ethnicity: yup.string().required('ethnicity is required'),
  accessToPublicFunds: yup.string().required('accessToPublicFunds is required'),
  email: yup.string().required('email is required'),
  postalCode: yup.string().required('postalCode is required'),
  areYouBasedInWestMidlands: yup
    .string()
    .required('areYouBasedInWestMidlands is required'),
  higherEduLevel: yup.string().required('higherEduLevel is required'),
  eligibleToWorkUk: yup
    .string()
    .required('Eligible to work in the United Kingdom is required'),
  participatedInPrevBootcamps: yup
    .string()
    .required('participatedInPrevBootcamps is required'),
  whereDidYouHearAboutUs: yup
    .string()
    .required('whereDidYouHearAboutUs is required'),
  studyCompletion: yup.string().required('studyCompletion is required'),
})

const Eligibility = ({ handleCancel, applicationLink }) => {
  const { loading, disable, data, success, error } = useSelector(
    (state) => state.eligibility,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      if (error === 'Error verifying NIN') {
        toast.error(
          'Error on NIN field, please recheck NIN field and make sure your using a right NIN',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (error.includes('Invalid postcode')) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        toast.error(
          'Error on postalCode field, please recheck field and make sure your using a right postal code',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        return
      }
      if (error === 'Error verifying postcode') {
        toast.error(
          'Error on postalCode field, please recheck field and make sure your using a right postal code',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      const errorMessage = error?.map((data) => data.name)

      if (errorMessage?.includes('firstName')) {
        toast.error('Error on first Name field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (errorMessage?.includes('lastName')) {
        toast.error('Error on last Name field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (errorMessage?.includes('email')) {
        toast.error('Error on email field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (errorMessage?.includes('leaveInTheUk')) {
        toast.error('Error on based in england field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (errorMessage?.includes('participatedInPrevBootcamps')) {
        toast.error(
          'Error on participated In Prev Bootcamps field, please recheck field',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (errorMessage?.includes('whereDidYouHearAboutUs')) {
        toast.error(
          'Error on Where did you hear the course field, please recheck',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      if (errorMessage?.includes('studyCompletion')) {
        toast.error(
          'Error on studyCompletion In Prev Bootcamps field, please recheck field',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (errorMessage?.includes('higherEduLevel')) {
        toast.error(
          'Error on higher Level of Study field, please recheck field',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      if (errorMessage?.includes('eligibleToWorkUk')) {
        toast.error('Error on work UK Eligiblity field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      if (errorMessage?.includes('ethnicity')) {
        toast.error('Error on ethnicity field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      if (errorMessage?.includes('accessToPublicFunds')) {
        toast.error(
          'Error on accessToPublicFunds field, please recheck field',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      if (errorMessage?.includes('dateOfBirth')) {
        toast.error('Error on date Of Birth field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      if (errorMessage?.includes('postalCode')) {
        toast.error('Error on Postal Code field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (errorMessage?.includes('phoneNumber')) {
        toast.error('Error on Phone number field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      if (errorMessage?.includes('gender')) {
        toast.error('Error on Gender field, please recheck field', {
          position: toast.POSITION.TOP_CENTER,
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      if (error) {
        toast.error(
          'Please recheck all fields and make sure all fields are appropriately filled',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
    }
  }, [error])

  const [phoneNumber, setPhoneNumber] = useState('')
  const [gender, setGender] = useState('')
  const [gender1, setGender1] = useState('')
  const [dialCode, setDialCode] = useState('')
  const [shortCode, setShortCode] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState(null)
  const [countryName, setCountryName] = useState('')
  // const [leaveInTheUk, setLeaveInTheUk] = useState(null);
  // const [areYouBasedInEngland, setAreYouBasedInEngland] = useState("");

  const handlePhoneNumber = (value, country, event, formattedValue) => {
    setDialCode(country.dialCode)
    setCountryName(country.name)
    setPhoneNumber(formattedValue)
    setShortCode(country.countryCode)
  }

  const onSubmit = (values) => {
    const { eligibleToWorkUk, areYouBasedInWestMidlands, ...others } = values

    const payload = {
      phoneNumber: {
        code: dialCode,
        number: phoneNumber,
        country: {
          name: countryName,
          shortCode,
        },
      },
      gender: {
        selectedGender: gender,
        // otherSelfSpecified: gender1,
      },
      locationData: {
        areYouBasedInWestMidlands: areYouBasedInWestMidlands,
      },
      eligibleToWorkUk: eligibleToWorkUk,
      applicationLink,
      dateOfBirth,
      ...others,
    }

    dispatch(createEligibleCode(payload))
  }

  const onChange = (date, dateString) => {
    setDateOfBirth(dateString)
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      postalCode: '',
      higherEduLevel: '',
      studyCompletion: '',
      whereDidYouHearAboutUs: '',
      ethnicity: '',
      accessToPublicFunds: '',
      eligibleToWorkUk: '',
      participatedInPrevBootcamps: '',
      areYouBasedInWestMidlands: '',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  })

  const { handleChange, handleSubmit, values, touched, errors } = formik

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const navigate = useNavigate()

  const { id } = useParams()

  const check = data?.data.data.continueRegistration

  const [time, setTime] = useState(4)

  useEffect(() => {
    if (check === undefined) {
      return
    }
    if (check) {
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [check])

  useEffect(() => {
    if (time === 0) {
      navigate(`/registration/${id}`)
    }
  }, [time])

  return (
    <>
      {check === undefined ? (
        <EligibilityContainer>
          <ToastContainer />
          {/* {!success && ( */}
          <>
            <Heading
              fontSize="38px"
              lineHeight="54px"
              className="mobile-heading"
            >
              Thank you for considering Niyo Bootcamps for your upskill journey.
            </Heading>
            <CardParagraph
              className="form-column"
              style={{ textAlign: 'center' }}
            >
              Because of the funding we receive for our Bootcamps, we need to
              ensure all applicants meet the eligibility requirements to apply
              for this Bootcamp.{' '}
              <span style={{ fontFamily: 'Medium' }}>
                Please note that your data is safe with us and we will not be
                sharing it with any other third party except the WMCA.
              </span>
            </CardParagraph>
            <SpaceSmall />
            <Container className="form-container">
              <Container className="form-column form-column-2">
                <form onSubmit={() => formik.onSubmit()}>
                  <div className="form-line form-double">
                    <label>
                      First Name
                      <InputField
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        className={
                          errors.firstName && touched.firstName
                            ? 'err'
                            : 'entry'
                        }
                        value={values.firstName}
                        onChange={handleChange}
                      ></InputField>
                      {formik.errors.firstName && touched.firstName && (
                        <p>First Name is Required</p>
                      )}
                    </label>

                    <label>
                      Last Names
                      <InputField
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        className={
                          errors.lastName && touched.lastName ? 'err' : 'entry'
                        }
                        value={values.lastName}
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   setFullName(e.target.value)
                        // }}
                        // required
                      ></InputField>
                      {formik.errors.lastName && touched.lastName && (
                        <p>Last Name is Required</p>
                      )}
                    </label>
                  </div>
                  <div className="form-line form-double">
                    <label>
                      Phone Number
                      <PhoneInput
                        placeholder="Phone number"
                        value={phoneNumber}
                        onChange={handlePhoneNumber}
                        country={'gb'}
                        enableSearch={true}
                        className="form-phone entry"
                        // required
                      />
                    </label>

                    <label>
                      Email
                      <InputField
                        type="text"
                        placeholder="Email"
                        name="email"
                        className={
                          errors.email && touched.email ? 'err' : 'entry'
                        }
                        value={values.email}
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   setEmail(e.target.value)
                        // }}
                        // required
                      ></InputField>
                      {formik.errors.email && touched.email && (
                        <p>email is Required</p>
                      )}
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      Date of Birth
                      <div style={{ paddingTop: '5px' }}>
                        <DatePicker onChange={onChange} />
                      </div>
                      {/* {loading && dateOfBirth && <p>dateOfBirth is Required</p>} */}
                    </label>
                    <label>
                      Gender
                      {gender === 'Others' ? (
                        <InputField
                          type="text"
                          autoFocus
                          placeholder="Specific Other Gender"
                          id="full-name"
                          className="entry"
                          value={gender1}
                          // onChange={handleChange}
                          onChange={(e) => {
                            setGender1(e.target.value)
                          }}
                          // required
                        ></InputField>
                      ) : (
                        <SelectField
                          id="gender"
                          className="entry"
                          value={gender}
                          onChange={(e) => {
                            setGender(e.target.value)
                          }}
                          // required
                        >
                          <option disabled value="">
                            Gender
                          </option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                          <option value="Others">Self Specify</option>
                        </SelectField>
                      )}
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      Ethnicity
                      <SelectField
                        // id="ethnicity"
                        className={
                          errors.ethnicity && touched.ethnicity
                            ? 'err'
                            : 'entry'
                        }
                        name="ethnicity"
                        value={values.ethnicity}
                        onChange={handleChange}
                      >
                        <option selected>Select your option</option>
                        {Ethnicities?.map((item, index) => {
                          return <option key={index}>{item}</option>
                        })}
                      </SelectField>
                      {formik.errors.ethnicity && touched.ethnicity && (
                        <p>ethnicity is Required</p>
                      )}
                    </label>
                    <label>
                      Postal Code
                      <InputField
                        type="text"
                        placeholder="Postal Code"
                        name="postalCode"
                        className={
                          errors.postalCode && touched.postalCode
                            ? 'err'
                            : 'entry'
                        }
                        value={values.postalCode}
                        onChange={handleChange}
                        // required
                      ></InputField>
                      {formik.errors.postalCode && touched.postalCode && (
                        <p>postalCode is Required</p>
                      )}
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      Are you eligible to work in the United Kingdom?
                      <SelectField
                        name="eligibleToWorkUk"
                        className={
                          errors.eligibleToWorkUk && touched.eligibleToWorkUk
                            ? 'err'
                            : 'entry'
                        }
                        value={values.eligibleToWorkUk}
                        onChange={handleChange}
                        // required
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        <option>Yes</option>
                        <option>No</option>
                      </SelectField>
                      {formik.errors.eligibleToWorkUk &&
                        touched.eligibleToWorkUk && (
                          <p>
                            Eligible to work in the United Kingdom is required
                          </p>
                        )}
                    </label>

                    <label>
                      If you are not a british citizen, do you have access to
                      public funds?
                      <SelectField
                        id="accessToPublicFunds"
                        className={
                          errors.accessToPublicFunds &&
                          touched.accessToPublicFunds
                            ? 'err'
                            : 'entry'
                        }
                        name="accessToPublicFunds"
                        value={values.accessToPublicFunds}
                        onChange={handleChange}
                      >
                        <option selected>Select your option</option>
                        <option>Yes</option>
                        <option>No</option>
                        <option>I am a citizen</option>
                      </SelectField>
                      {formik.errors.accessToPublicFunds &&
                        touched.accessToPublicFunds && (
                          <p>accessToPublicFunds is Required</p>
                        )}
                    </label>
                  </div>

                  <div className="form-line form-double">
                    <label>
                      Location (Are you based in the West Midlands?)
                      <SelectField
                        name="areYouBasedInWestMidlands"
                        className={
                          errors.areYouBasedInWestMidlands &&
                          touched.areYouBasedInWestMidlands
                            ? 'err'
                            : 'entry'
                        }
                        value={values.areYouBasedInWestMidlands}
                        onChange={handleChange}
                      >
                        <option disabled value="">
                          Select your level
                        </option>
                        <option>Yes</option>
                        <option>No</option>
                      </SelectField>
                      {/* <InputField
                        type="text"
                        placeholder="are You Based In WestMidlands"
                        name="areYouBasedInWestMidlands"
                        className={
                          errors.areYouBasedInWestMidlands && touched.areYouBasedInWestMidlands
                            ? "err"
                            : "entry"
                        }
                        value={values.areYouBasedInWestMidlands}
                        onChange={handleChange}
                        // required
                      ></InputField>
                      {formik.errors.areYouBasedInWestMidlands && touched.areYouBasedInWestMidlands && (
                        <p>areYouBasedInWestMidlands is Required</p>
                      )} */}
                    </label>

                    <label>
                      What is the highest level of study that you have completed
                      or on track to complete?
                      <SelectField
                        name="higherEduLevel"
                        className={
                          errors.higherEduLevel && touched.higherEduLevel
                            ? 'err'
                            : 'entry'
                        }
                        value={values.higherEduLevel}
                        onChange={handleChange}
                      >
                        <option disabled value="">
                          Select your level
                        </option>
                        {StudyLevel?.map((item, index) => {
                          return (
                            <>
                              <option key={index} value={item}>
                                {item}
                              </option>
                            </>
                          )
                        })}
                      </SelectField>
                      {formik.errors.higherEduLevel &&
                        touched.higherEduLevel && (
                          <p>Education Level is Required</p>
                        )}
                    </label>
                  </div>

                  <div className="form-line form-double">
                    {(values?.higherEduLevel?.includes('Level 6') ||
                      values?.higherEduLevel?.includes('Level 7') ||
                      values?.higherEduLevel?.includes('Level 8')) && (
                      <>
                        <label>
                          If you have completed Level 6 qualification or above
                          please select which subject.
                          <SelectField
                            name="levelSixAbove"
                            className="entry"
                            value={values.levelSixAbove}
                            onChange={handleChange}
                          >
                            <option selected disabled value="">
                              Select your option
                            </option>
                            {Subject.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              )
                            })}
                          </SelectField>
                        </label>
                        <label>
                          Have you already completed this study?
                          <SelectField
                            name="studyCompletion"
                            className={
                              errors.studyCompletion && touched.studyCompletion
                                ? 'err'
                                : 'entry'
                            }
                            value={values.studyCompletion}
                            onChange={handleChange}
                          >
                            <option selected disabled value="">
                              Select your option
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                          </SelectField>
                          {formik.errors.studyCompletion &&
                            touched.studyCompletion && (
                              <p>studyCompletion is Required</p>
                            )}
                        </label>
                      </>
                    )}
                  </div>
                  {(values?.higherEduLevel?.includes('Level 1') ||
                    values?.higherEduLevel?.includes('Level 2') ||
                    values?.higherEduLevel?.includes('Level 3') ||
                    values?.higherEduLevel?.includes('Level 4') ||
                    values?.higherEduLevel?.includes('Level 5')) && (
                    <div className="form-line">
                      <label>
                        Have you already completed this study?
                        <SelectField
                          name="studyCompletion"
                          className={
                            errors.studyCompletion && touched.studyCompletion
                              ? 'err'
                              : 'entry'
                          }
                          value={values.studyCompletion}
                          onChange={handleChange}
                        >
                          <option selected disabled value="">
                            Select your option
                          </option>
                          <option>Yes</option>
                          <option>No</option>
                        </SelectField>
                        {formik.errors.studyCompletion &&
                          touched.studyCompletion && (
                            <p>studyCompletion is Required</p>
                          )}
                      </label>
                    </div>
                  )}

                  <div className="form-line">
                    <label>
                      Have you participated in any Niyo Bootcamp in the past?
                      <SelectField
                        name="participatedInPrevBootcamps"
                        className={
                          errors.participatedInPrevBootcamps &&
                          touched.participatedInPrevBootcamps
                            ? 'err'
                            : 'entry'
                        }
                        value={values.participatedInPrevBootcamps}
                        onChange={handleChange}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        <option>Yes</option>
                        <option>No</option>
                      </SelectField>
                      {formik.errors.participatedInPrevBootcamps &&
                        touched.participatedInPrevBootcamps && (
                          <p>Previous Bootcamps participation is Required</p>
                        )}
                    </label>
                  </div>

                  <div className="form-line">
                    <label>
                      Where did you hear about the course?
                      <SelectField
                        name="whereDidYouHearAboutUs"
                        className={
                          errors.whereDidYouHearAboutUs &&
                          touched.whereDidYouHearAboutUs
                            ? 'err'
                            : 'entry'
                        }
                        value={values.whereDidYouHearAboutUs}
                        onChange={handleChange}
                      >
                        <option selected disabled value="">
                          Select your option
                        </option>
                        <option>The National Careers Service</option>
                        <option>FE college or training provider</option>
                        <option>Current employer</option>
                        <option>Job Centre Plus / Work Coach / DWP</option>
                        <option>Family, friend or colleague</option>
                        <option>Gov.UK website</option>
                        <option>Social Media</option>
                        <option>Newspaper/magazine article</option>
                        <option>University</option>
                        <option>Job Sites (e.g. Indeed, Reed, etc.)</option>
                        <option>Other</option>
                      </SelectField>
                      {formik.errors.whereDidYouHearAboutUs &&
                        touched.whereDidYouHearAboutUs && (
                          <p>Hear course is Required</p>
                        )}
                    </label>
                  </div>

                  <ButtonSpace />

                  <CustomButton
                    type="button"
                    className={`${disable ? 'disabled' : 'mobile-button'}`}
                    onClick={() => handleSubmit()}
                  >
                    {loading ? <Spin spinning /> : 'Confirm your eligibility'}
                  </CustomButton>
                </form>
                <SpaceSmall />
              </Container>

              <Container className="form-column form-column-3">
                <ul className="list-container">
                  <li key="11">
                    Please confirm your eligibility for scholarship before you
                    proceed
                  </li>
                  <li key="12">
                    Application is only for candidates based in England.
                  </li>
                  <SpaceMedium />
                </ul>
              </Container>
            </Container>
          </>
          {/* )} */}

          {/* {success && (
          <>
            <div className="heading-section" style={{ textAlign: "center" }}>
              <img src={sent} alt="Email sent" style={{ width: "100px" }} />
              <Paragraph className="heading-paragraph">
                Eligbility form filled successfuly
              </Paragraph>

              <SpaceSmall className="desktop-hidden" />
            </div>
          </>
        )} */}
        </EligibilityContainer>
      ) : check === true ? (
        <div className="heading-section" style={{ textAlign: 'center' }}>
          <img src={sent} alt="Email sent" style={{ width: '100px' }} />
          {/* <Paragraph className="heading-paragraph">
              Eligbility form filled successfuly
            </Paragraph> */}
          <Heading className="heading-paragraph">
            Congratulations you are eligbile !!!
          </Heading>
          <Paragraph className="heading-paragraph">
            This page will reroute to the application page in {time}
          </Paragraph>
          <SpaceSmall className="desktop-hidden" />
        </div>
      ) : (
        <div className="heading-section" style={{ textAlign: 'center' }}>
          <img src={sent} alt="Email sent" style={{ width: '100px' }} />
          <Paragraph className="heading-paragraph">
            {data?.data.message}
          </Paragraph>

          <SpaceSmall className="desktop-hidden" />
        </div>
      )}
    </>
  )
}

export default Eligibility
