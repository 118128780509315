import styled from "styled-components";
import breakpointSizes from "../../../constants/breakpointSizes";

export const FormContainer = styled.div`
  text-align: center;

  .partner-heading {
    padding: 0 0 2% 0;
  }

  .form-container {
    .left {
      margin: 0 2% 0 0;
    }
  }

  .phone-row {
    display: flex;
    justify-content: space-between;
  }

  .react-tel-input,
  .form-phone,
  .phone-control {
    width: 49%;
    margin: 1% 0 2% 0;

    .form-control {
      width: 100%;
      border-radius: 0;
      height: 60px;
      border: 1px solid #b5baba;
      font-family: "Light";
      color: #000;
    }
  }

  @media ${breakpointSizes.mobile} {
    .form-container {
      .form-row {
        display: flex;
        flex-direction: column;

        .form-box {
          width: 100%;
          margin: 0 0 8% 0;
        }
      }
    }
  }

  @media ${breakpointSizes.desktop} {
  }
`;
