import styled from "styled-components";

export const SuccessContainer = styled.div`
  .hero-container {
    min-height: 100vh;
    height: auto;
    background-image: none !important;
    z-index: -10;
    display: flex;

    .hero-right,
    .hero-left {
      padding: 0;
    }

    .hero-left {
      width: 45%;
      padding: 4% 0 0 0;

      .individual-package {
        height: auto;
        color: #454a4a;

        ul {
          padding: 0 0 0 2%;
          width: 60%;

          li {
            padding: 0 0 10px 5%;
            color: #636969;
          }

          li::marker {
            color: #fbaf00;
            content: "■";
            font-size: 11px;
          }
        }
      }
    }

    .hero-right {
      width: 55%;
      padding: 0 2%;

      .progress-bar {
        width: 100%;
        padding: 0 0 4% 0;
      }

      .wallet {
        height: 60px;
        width: 60px;
        margin: 5% 0;
      }

      .padding {
        padding: 0 0 3% 0;
      }
    }

    .padding {
      padding: 0 0 3% 0;
    }
  }
`;
