import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { EntrepreneurInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Entrepreneurship = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let { id } = useParams();

  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={EntrepreneurInfo.courseTitle}
        schedule={EntrepreneurInfo.schedule}
        courseRating={EntrepreneurInfo.courseRating}
        applicationLink={EntrepreneurInfo.applicationLink}
        headingDescription={EntrepreneurInfo.headingDescription}
        heroImage={EntrepreneurInfo.heroImage}
        cardTitle={EntrepreneurInfo.cardTitle}
        overviewHeading={EntrepreneurInfo.overviewHeading}
        overviewText={EntrepreneurInfo.overviewText}
        overviewVideo={EntrepreneurInfo.overviewVideo}
        syllabusText={EntrepreneurInfo.syllabusText}
        syllabusLessons={EntrepreneurInfo.syllabusLessons}
        benefits={EntrepreneurInfo.benefits}
        postBenefits={EntrepreneurInfo.postBenefits}
        eventsText={EntrepreneurInfo.eventsText}
        eventsList={EntrepreneurInfo.eventsList}
        requirementsText={EntrepreneurInfo.requirementsText}
        requirementsList={EntrepreneurInfo.requirementsList}
        tutors={EntrepreneurInfo.tutors}
        tutorsParagraph={EntrepreneurInfo.tutorsParagraph}
      />
    </>
  );
};

export default Entrepreneurship;
