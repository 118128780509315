import React from 'react'
import {
  HeroContainer,
  PreHeading,
  SpaceSmall,
  Heading,
  Paragraph,
  CustomButton,
  ButtonSpace,
  Container,
  CardParagraph,
  SelectField,
  InputField,
} from '../../styles/StyledComponents'
import { JobsContainer } from './style'
import jobCareerService from '../../service/career.service'
import { JobLevels, JobTypes, Locations } from '../../constants'
import { useEffect, useState } from 'react'

const Jobs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const [search, setSearch] = useState('')
  const [jobLocation, setJobLocation] = useState('')
  const [jobType, setJobType] = useState('')
  const [jobLevel, setJobLevel] = useState('')
  const [jobRoles, setJobRoles] = useState([])
  const [error, setError] = useState(null)
  const [visibleNumber, setVisibleNumber] = useState(5)
  const [visible, setVisible] = useState('more')

  useEffect(() => {
    jobCareerService
      .getAllJobCareers({ jobType, jobLocation, jobLevel })
      .then((response) => {
        const data = response.data
        setJobRoles(data.data)
      })
      .catch((error) => {
        setError(error.response.data)
      })
  }, [jobLocation, jobType, jobLevel])

  useEffect(() => {
    jobCareerService
      .getAllJobCareers({})
      .then((response) => {
        const data = response.data
        setJobRoles(data.data)
      })
      .catch((error) => {
        setError(error.response.data)
      })
  }, [])

  const handleLoadMore = (val) => {
    if (val === 'more') {
      setVisibleNumber(jobRoles.length)
      setVisible('less')
    } else if (val === 'less') {
      setVisibleNumber(5)
      setVisible('more')
    }
  }

  const handleDropDown = (e) => {}

  const defaultJobs = () => {
    setJobType('')
    setJobLevel('')
    setJobLocation('')
    jobCareerService
      .getAllJobCareers({})
      .then((response) => {
        const data = response.data
        setJobRoles(data.data)
      })
      .catch((error) => {
        setError(error.response.data)
      })
  }

  const sortedData = jobRoles?.sort((a, b) => {
    const aIsCWA = a.companyName === 'CMA' || a.companyName === 'CMAS'
    const bIsCWA = b.companyName === 'CMA' || b.companyName === 'CMAS'

    if (aIsCWA && !bIsCWA) {
      return -1 
    } else if (!aIsCWA && bIsCWA) {
      return 1 
    } else {
      return 0 
    }
  })

  const filteredCareers = sortedData?.filter((career) => {
    return career.jobRole?.toLowerCase().indexOf(search?.toLowerCase()) !== -1
  })
  return (
    <JobsContainer>
      <HeroContainer height={'auto'} className="hero-container">
        <div className="hero-left">
          <PreHeading className="mobile-hidden">DISCOVER A JOB</PreHeading>
          <Heading fontSize="52px" lineHeight="56px">
            Start your career move now!
          </Heading>
          <SpaceSmall />
          <Paragraph>
            Connecting talented people in tech, to recruiters, and
            companies globally.
          </Paragraph>
          <ButtonSpace />

          <a
            href="mailto:partnerships@niyo.co?subject=New%20jobs%20board%20posting%20enquiry&body=Hi%20Niyo%20team,%20
I'm%20interested%20in%20using%20your%20platform%20to%20promote%20job(s)%20opportunities%20which%20are%20available%20within%20my%20organisation.%20Can%20you%20please%20let%20me%20know%20what%20the%20next%20steps%20are%20to%20get%20this%20set%20up?%20
Thank%20you."
          >
            <CustomButton className="hero-button">Post a Job</CustomButton>
          </a>
        </div>
        <SpaceSmall className="desktop-hidden mobile-display" />

        <div className="hero-right">
          <div className="hero-image"></div>
        </div>
      </HeroContainer>

      <Container className="search-section">
        <div className="search-container">
          <InputField
            type="text"
            placeholder="🔍︎ Search"
            id="search-bar"
            value={search}
            className="search-input"
            onChange={(e) => setSearch(e.target.value)}
          ></InputField>

          <div className="filters">
            <span className="search-select option-bold" onClick={defaultJobs}>
              Default
            </span>

            <SelectField
              id="job-levels"
              defaultValue="Job Levels"
              className="search-select"
              value={jobLevel}
              onChange={(e) => setJobLevel(e.target.value)}
            >
              <option selected className="option-bold" disabled value="">
                Job Levels
              </option>
              {JobLevels.map((item, index) => {
                const val = item.replace(/-/g, '').toLowerCase()
                return (
                  <option key={index} value={val} onClick={handleDropDown}>
                    {item}
                  </option>
                )
              })}
            </SelectField>

            <SelectField
              id="job-types"
              defaultValue="Job Types"
              className="search-select"
              value={jobType}
              onChange={(e) => setJobType(e.target.value)}
            >
              <option
                selected
                className="option-bold"
                onClick={handleDropDown}
                disabled
                value=""
              >
                Job Types
              </option>
              {JobTypes.map((item, index) => {
                const val = item.replace(/-/g, '').toLowerCase()
                return (
                  <option value={val} key={index}>
                    {item}
                  </option>
                )
              })}
            </SelectField>

            {/* <SelectField
              id="job-types"
              defaultValue="Job Types"
              className="search-select"
              value={jobLocation}
              onChange={(e) => setJobLocation(e.target.value)}
            >
              <option
                selected
                className="option-bold"
                onClick={handleDropDown}
                disabled
                value=""
              >
                Location
              </option>
              {Locations.map((item, index) => {
                const val = item.replace(/-/g, '').toLowerCase()
                return (
                  <option value={val} key={index}>
                    {item}
                  </option>
                )
              })}
            </SelectField> */}
          </div>
        </div>
      </Container>

      <Container className="jobs-section">
        <Container className="post-jobs">
          <Heading fontSize="20px" lineHeight="24px" color="#636969">
            Post a Job
          </Heading>
          <SpaceSmall />
          <CardParagraph fontSize="15px" color="#636969">
            Target the most qualified, creative candidates and add a distinct
            strategic advantage to your recruitment efforts.
          </CardParagraph>
          <ul>
            <li>From £149 per posting</li>
            <li>
              Sent to our partner's & bootcamp students mailing list of more
              than 30,000 members.
            </li>
            <li>Promoted on our social media channels</li>
            <li>Online and active for 90 Days</li>
          </ul>

          <a href="mailto:partnerships@niyo.co?subject=New%20jobs%20board%20posting%20enquiry&body=Hi%20Niyo%20team,%20I'm%20interested%20in%20using%20your%20platform%20to%20promote%20job(s)%20opportunities%20which%20are%20available%20within%20my%20organisation.%20Can%20you%20please%20let%20me%20know%20what%20the%20next%20steps%20are%20to%20get%20this%20set%20up?%20Thank%20you.">
            <CustomButton height="45px">Post a Job</CustomButton>
          </a>
          <SpaceSmall />
        </Container>

        <Container className="latest-jobs">
          <Heading fontSize="30px" className="mobile-hidden">
            LATEST JOBS
          </Heading>
          {jobRoles?.length < 1 ? (
            <div>Jobs currently empty</div>
          ) : (
            <div className="job-cards-list">
              {filteredCareers?.slice(0, visibleNumber)?.map((item, index) => (
                <>
                  <div className="job-card">
                    <div className="card-section">
                      <img
                        src={item?.companyLogo?.url}
                        className="niyo-logo"
                        alt="niyo-logo"
                      />
                    </div>

                    <div className="card-section middle-section">
                      <div>
                        {' '}
                        <Heading
                          fontSize="18px"
                          lineHeight="20px"
                          className="heading"
                        >
                          {item?.jobRole}
                        </Heading>
                      </div>

                      <div className="info-summary">
                        <CardParagraph className="padding text">
                          {item?.companyName}
                        </CardParagraph>
                        <CardParagraph className="padding text">
                          {item?.jobType}
                        </CardParagraph>
                        <CardParagraph className="text">
                          {item?.jobLocation}
                        </CardParagraph>
                      </div>
                    </div>

                    <div className="card-section">
                      <CustomButton
                        width="120px"
                        border="1px solid #FFD470"
                        fontSize="14px"
                        background="#fff"
                        color="#FBAF00"
                        height="45px"
                        className="mobile-hidden"
                      >
                        <a href={item?.jobLink}>Apply Now</a>
                      </CustomButton>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}

          {filteredCareers?.length < 1 && <div>No Jobs Found</div>}

          <ButtonSpace />

          {!filteredCareers?.length < 1 && (
            <>
              {visible === 'more' ? (
                <div className="jobs-button">
                  <CustomButton
                    background="none"
                    color="#FBAF00"
                    onClick={() => handleLoadMore('more')}
                  >
                    Show more Jobs 🡪
                  </CustomButton>
                </div>
              ) : (
                <div className="jobs-button">
                  <CustomButton
                    background="none"
                    color="#FBAF00"
                    onClick={() => handleLoadMore('less')}
                  >
                    Show less Jobs 🡪
                  </CustomButton>
                </div>
              )}
            </>
          )}
        </Container>
      </Container>
    </JobsContainer>
  )
}

export default Jobs
