import { Link } from 'react-router-dom'
import BootcampContainer from './style'
import { Modal, Button } from 'antd'

import Eligibility from '../../components/Eligibility'
import {
  ButtonSpace,
  CardParagraph,
  Container,
  CustomButton,
  Heading,
  HeroContainer,
  Paragraph,
  PreHeading,
  SpaceMedium,
  SpaceSmall,
} from '../../styles/StyledComponents'

import {
  web2BootcampInfo,
  web3BootcampInfo,
  hairBootcampInfo,
  foundherBootcampInfo,
} from '../../constants'
import OurGrads from '../../components/Grads'
import FAQS from '../../components/FAQS'

import Upskilling from '../../components/Upskilling'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/scss/scrollbar'
import { useEffect, useState } from 'react'

const Bootcamps = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [success, setSuccess] = useState(false);

  const showModal = () => {
    setOpen(true)
  }

  const handleOK = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setOpen(false)
      setConfirmLoading(false)
    }, 2000)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <BootcampContainer>
      <HeroContainer height={'auto'} className="hero-container">
        <div className="hero-left">
          <PreHeading className="mobile-hidden">
            WELCOME TO NIYO BOOTCAMPS
          </PreHeading>
          <SpaceSmall />
          <Heading>
            <span>Explore</span> our Bootcamps to see the various courses.
          </Heading>
          <SpaceSmall />
          <Paragraph>
            Are you looking for a pathway into having a career in tech, or a
            high impact industry? Learn with a growing community of people
            in tech, passionate about changing the narrative too!
          </Paragraph>
          <ButtonSpace />

         
          <Modal
             open={open}
             onOk={handleOK}
             confirmLoading={confirmLoading}
             onCancel={handleCancel}
             className="eligibility-modal"
             width={success ? "40%" : "90%"}
             bodyStyle={success ? { height: "" } : { height: "auto" }}
             style={success ? { top: 200 } : { top: 10 }}
             footer={null}
          >
            <Eligibility success={success} setSuccess={setSuccess}/>
          </Modal>
        </div>

        <SpaceSmall className="desktop-hidden mobile-display" />

        <div className="hero-right">
          <div className="hero-image"></div>
        </div>
      </HeroContainer>

      <Container className="start-journey">
        <Container className="web-2-section bootcamp-cards">
          <SpaceSmall className="mobile-hidden" />
          <PreHeading className="preheading">TECHNOLOGY BOOTCAMP</PreHeading>
          <SpaceSmall className="mobile-hidden" />
          <div className="section-top">
            <Heading fontSize="36px" className="top-heading">
              Start your journey in any of our <span>Technical Bootcamps</span>
            </Heading>
            <CustomButton
              className="top-button"
              background="none"
              fontFamily="Bold"
              width="80px"
              color="#E09D00"
            >
              <Link to="/web2-bootcamps">View All</Link>
            </CustomButton>
          </div>
          <SpaceSmall className="mobile-hidden" />
          <div className="card-container">
            {web2BootcampInfo.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <div className="text">
                    <Heading className="card-title" color={item.color}>
                      {item.title}
                    </Heading>
                    <CardParagraph className="card-text" color={item.color}>
                      {item.description}
                    </CardParagraph>
                    {/* <img
                      src={item.rating}
                      className="card-rating"
                      alt="rating"
                    /> */}
                    <CardParagraph className="card-text" color={item.color}>
                      <b> {item.schedule}</b>
                    </CardParagraph>

                    {item.externalLink ? (
                      <a
                        href={item.externalLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CustomButton
                          className="card-button"
                          border={'2px solid #E09D00'}
                          background={item.background}
                          color="#E09D00"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                      </a>
                    ) : (
                      <Link to={`${item.path}/${item?.title}`}>
                        <CustomButton
                          className="card-button"
                          border={'2px solid #E09D00'}
                          background={item.background}
                          color="#E09D00"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                      </Link>
                    )}
                  </div>
                  <SpaceSmall />
                </Container>
              )
            })}
          </div>

          <div className="desktop-hidden mobile-swipe-container">
            <Swiper
              slidesPerView={1.1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {web2BootcampInfo.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        {/* <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        /> */}
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>

                        {item.externalLink ? (
                          <a
                            href={item.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CustomButton
                              className="card-button"
                              border={'2px solid #E09D00'}
                              background={item.background}
                              color="#E09D00"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </a>
                        ) : (
                          <Link to={`${item.path}/${item?.title}`}>
                            <CustomButton
                              className="card-button"
                              border={'2px solid #E09D00'}
                              background={item.background}
                              color="#E09D00"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </Link>
                        )}

                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                )
              })}
            </Swiper>
          </div>
        </Container>

        <SpaceMedium className="mobile-hidden" />

        <Container className="web-3-section bootcamp-cards">
          <SpaceSmall className="mobile-hidden" />
          <PreHeading className="preheading">TECHNOLOGY BOOTCAMP</PreHeading>
          <SpaceSmall className="mobile-hidden" />
          <div className="section-top">
            <Heading fontSize="36px" className="top-heading">
              Start your journey in any of our <span>Web 3.0 Bootcamps</span>
            </Heading>
            <CustomButton
              className="top-button"
              background="none"
              fontFamily="Bold"
              width="80px"
              hover="#fff"
              hoverColor="#000"
              color="#070909"
            >
              <Link to="/web3-bootcamps">View All</Link>
            </CustomButton>{' '}
          </div>

          <SpaceSmall className="mobile-hidden" />

          <div className="card-container">
            {web3BootcampInfo.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <div className="text">
                    <Heading className="card-title" color={item.color}>
                      {item.title}
                    </Heading>
                    <CardParagraph className="card-text" color={item.color}>
                      {item.description}
                    </CardParagraph>
                    <CardParagraph className="card-text" color={item.color}>
                      {item.schedule}
                    </CardParagraph>
                    {item.externalLink ? (
                      <a
                        href={item.externalLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CustomButton
                        className="card-button"
                        border={"2px solid #fff"}
                        background={item.background}
                        color="#fff"
                        hoverColor="#000"
                        hover="#fff"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                      </a>
                    ) : (
                      <Link to={`${item.path}/${item?.title}`}>
                        <CustomButton
                          className="card-button"
                          border={"2px solid #fff"}
                          background={item.background}
                          color="#fff"
                          hoverColor="#000"
                          hover="#fff"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                      </Link>
                    )}
                    ;
                  </div>
                  <SpaceSmall />
                </Container>
              )
            })}
          </div>

          <div className="desktop-hidden mobile-swipe-container">
            <Swiper
              slidesPerView={1.1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {web3BootcampInfo.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>

                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        {item.externalLink ? (
                          <a
                            href={item.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CustomButton
                             className="card-button"
                             border={"2px solid #fff"}
                             background={item.background}
                             color="#fff"
                             hoverColor="#000"
                             hover="#fff"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </a>
                        ) : (
                          <Link to={`${item.path}/${item?.title}`}>
                            <CustomButton
                              className="card-button"
                              border={'2px solid #E09D00'}
                              background={item.background}
                              color="#E09D00"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </Link>
                        )}
                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                )
              })}
            </Swiper>
          </div>
        </Container>
        <SpaceMedium className="mobile-hidden" />

        <Container className="hair-beauty-section bootcamp-cards">
          <SpaceSmall className="mobile-hidden" />
          <PreHeading className="preheading">HAIR & BEAUTY</PreHeading>
          <SpaceSmall className="mobile-hidden" />
          <div className="section-top">
            <Heading fontSize="36px" className="top-heading">
              Start your journey in any of our{' '}
              <span>Hair & Beauty Tech Bootcamps</span>
            </Heading>
            <CustomButton
              className="top-button"
              background="none"
              fontFamily="Bold"
              width="80px"
              color="#FF47CE"
              hover="#FF47CE"
              hoverColor="#fff"
            >
              <Link to="/hair-bootcamps">View All</Link>
            </CustomButton>{' '}
          </div>
          <SpaceSmall className="mobile-hidden" />

          <div className="card-container">
            {hairBootcampInfo.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <div className="text">
                    <Heading className="card-title" color={item.color}>
                      {item.title}
                    </Heading>
                    <CardParagraph className="card-text" color={item.color}>
                      {item.description}
                    </CardParagraph>

                    <CardParagraph className="card-text" color={item.color}>
                      {item.schedule}
                    </CardParagraph>
                    {item.externalLink ? (
                      <a
                        href={item.externalLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CustomButton
                          className="card-button"
                          border={'2px solid #FF47CE'}
                          background={item.background}
                          color="#FF47CE"
                          hoverColor="#fff"
                          hover="#FF47CE"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                      </a>
                    ) : (
                      <Link to={`${item.path}/${item?.title}`}>
                        <CustomButton
                         className="card-button"
                         border={'2px solid #FF47CE'}
                         background={item.background}
                         color="#FF47CE"
                         hoverColor="#fff"
                         hover="#FF47CE"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                      </Link>
                    )}
                  </div>
                  <SpaceSmall />
                </Container>
              )
            })}
          </div>

          <div className="desktop-hidden mobile-swipe-container">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {hairBootcampInfo.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>

                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        <Link to={`${item.path}/${item?.title}`}>
                          <CustomButton
                              className="card-button"
                              border={'2px solid #FF47CE'}
                              background={item.background}
                              color="#FF47CE"
                              hoverColor="#fff"
                              hover="#FF47CE"
                          >
                            {item.buttonInfo}
                          </CustomButton>
                        </Link>
                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                )
              })}
            </Swiper>
          </div>
        </Container>
        <SpaceMedium className="mobile-hidden" />

        <Container className="foundhers-section bootcamp-cards">
          <SpaceSmall className="mobile-hidden" />
          <PreHeading className="preheading">FOUNDHERS BOOTCAMP</PreHeading>
          <SpaceSmall className="mobile-hidden" />
          <div className="section-top">
            <Heading fontSize="36px" className="top-heading">
              Start your journey in any of our <span>FoundHers Bootcamps</span>
            </Heading>
            <CustomButton
              className="top-button"
              background="none"
              fontFamily="Bold"
              width="80px"
              color="#A37200"
              hover="#A37200"
              hoverColor="#fff"
            >
              <Link to="/foundher-bootcamps">View All</Link>
            </CustomButton>{' '}
          </div>
          <SpaceSmall className="mobile-hidden" />

          <div className="card-container">
            {foundherBootcampInfo.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <div className="text">
                    <Heading className="card-title" color={item.color}>
                      {item.title}
                    </Heading>
                    <CardParagraph className="card-text" color={item.color}>
                      {item.description}
                    </CardParagraph>

                    <CardParagraph className="card-text" color={item.color}>
                      {item.schedule}
                    </CardParagraph>
                    <Link to={`${item.path}/${item?.title}`}>
                      <CustomButton
                        className="card-button"
                        border={'2px solid #A37200'}
                        background={item.background}
                        color="#A37200"
                        hover="#A37200"
                        hoverColor="#fff"
                      >
                        {item.buttonInfo}
                      </CustomButton>
                    </Link>
                  </div>
                  <SpaceSmall />
                </Container>
              )
            })}
          </div>

          <div className="desktop-hidden mobile-swipe-container">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {foundherBootcampInfo.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>

                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        <Link to={`${item.path}/${item?.title}`}>
                          <CustomButton
                           className="card-button"
                           border={'2px solid #A37200'}
                           background={item.background}
                           color="#A37200"
                           hover="#A37200"
                           hoverColor="#fff"
                          >
                            {item.buttonInfo}
                          </CustomButton>
                        </Link>
                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                )
              })}
            </Swiper>
          </div>
        </Container>
      </Container>

      <SpaceMedium className="mobile-hidden" />

      <Container className="fashion-container">
        <Container className="fashion-left">
          <div className="heading-tag-section">
            <PreHeading className="mobile-hidden">
              FASHION TECHNOLOGY BOOTCAMPS
            </PreHeading>
            <div className="heading-tag" alt="heading-tag"></div>
          </div>
          <Heading className="fashion-heading">
            Start your journey in any of our <span>Fashion Tech Bootcamp</span>
          </Heading>
        </Container>

        <Container className="fashion-right">
          <Paragraph>
            Launch your own Fashion Brand on the metaverse from scratch. Be the
            first to here about this.
            <CustomButton className="fashion-button">
              <a href="https://airtable.com/shrw6ZbgonNTa6POH">Click here to register</a>
            </CustomButton>
          </Paragraph>
        </Container>
      </Container>

      <SpaceMedium />

      <Upskilling />

      <SpaceMedium />

      <OurGrads />

      <FAQS  />
    </BootcampContainer>
  )
}

export default Bootcamps
