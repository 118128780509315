import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

const RegistrationContainer = styled.div`
  .navHomeBtn {
    margin-top: 30px;
  }
  .navHome {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .errorHandler {
    .err {
      border: 2px solid red;
    }
    p {
      color: red;
    }
  }
  .errorHandle {
    .err {
      border: 2px solid red;
    }
    h4 {
      color: red;
    }
    h5 {
      color: red;
      margin-top: 10px;
    }
  }
  .desktop-hidden {
    display: none;
  }
  .disabled {
    pointer-events: none;
  }
  .accordion-container {
    padding: 0;
    margin: 0;

    .accordion__item {
      border: none;
    }

    .dMBYsC {
      height: 0;
    }

    .accordion-item {
      padding: 0;
      margin: 0;
      border: none;

      .accordion-heading {
        padding: 0;
        margin: 0;

        .accordion-click {
          padding: 0;
          margin: 0;

          img {
            margin: 10% 0 0 0;
          }
        }
      }

      .accordion-item-panel {
        padding: 0;
        margin: 0;

        .sc-papXJ,
        .kGEeAN {
          height: 0;
        }
      }
    }
  }

  .form-background {
    height: auto;
    text-align: center;
  }

  .heading-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading-paragraph {
      width: 70%;
    }
  }

  .form-section-one,
  .form-section-two,
  .form-section-three {
    padding: 0;
    margin: 0;
  }

  .form-container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .form-column {
      padding: 0;
      margin: 0;
    }

    .form-column-1 {
      width: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .number {
        height: 34px;
        width: 34px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        padding: 0;
        margin: 0;
      }

      .number:nth-child(1) {
        margin: 12% 0 0 0;
      }

      .timeline-1 {
        height: 100%;
        width: 1%;
        border-left: 4px solid #fbaf00;
        padding: 0;
        margin: 0;
      }
    }

    .form-column-2 {
      padding: 0 1%;
      width: 60%;

      .form-heading {
        font-size: 20px !important;
        cursor: pointer;
      }

      .form-line {
        width: 100%;
        padding: 2% 0;
        margin: 20px 0;
        position: relative;
        display: flex;
        align-items: baseline;
        label {
          font-size: 14px;
        }
      }

      .form-double {
        display: flex;
        justify-content: space-between;

        label {
          width: 48%;
        }
      }

      .form-button-row {
        display: flex;
        padding: 5% 0;
        align-items: center;

        .upload-button {
          display: flex;
          justify-content: center;
          align-items: center;

          .button-icon {
            width: 30px;
            height: 40px;
          }
        }

        .upload-paragraph {
          padding: 0 5%;
        }

        .upload-button:nth-child(2) {
          margin: 0 5%;
        }
      }

      .form-links-row {
        padding: 0 0 10% 0;
        .link-paragraph {
          font-size: 13px;
          padding: 1% 0 0 0;
          a {
            text-decoration: underline;
            color: blue;
          }
        }

        a {
          text-decoration: none;
          color: #070909;
          padding: 0 0 0 1%;
        }

        .radio-line {
          display: flex;
          align-items: center;
          font-size: 10px;
          height: 20px;
          padding: 4% 0 0 0;
          font-family: Regular;

          .radio {
            margin: 0 1% 0 0;
          }
        }
      }

      .button-container {
        width: 65%;
        display: flex;
        justify-content: space-between;
      }

      .sc-papXJ,
      .hFboun {
        /* border: 1px solid yellow; */
        height: 80px;
        width: 100%;
      }
    }

    .form-column-3 {
      width: 30%;
      padding: 0 1%;

      .list-container {
        background-color: #fffcf4;
        padding: 0 8%;

        li {
          padding: 10% 0 0 4%;
          font-size: 14px;
        }

        li::marker {
          color: #fbaf00;
          content: "■";
          font-size: 11px;
        }
      }
    }
  }

  @media ${breakpointSizes.mobile} {
    .desktop-hidden {
      display: block;
    }

    .heading-section {
      padding: 0 0 0 0;
      margin: 0;
      text-align: left;
      justify-content: left;
      align-items: flex-start;

      .heading-paragraph {
        text-align: left;
        width: 100%;
      }

      .list-container {
        background-color: #fffcf4;
        padding: 0 8%;

        li {
          padding: 10% 0 0 4%;
          font-size: 14px;
        }

        li::marker {
          color: #fbaf00;
          content: "■";
          font-size: 11px;
        }
      }
    }

    .form-container {
      .form-column-1 {
        width: 10%;

        .timeline-1 {
          height: 100%;
        }

        .timeline-2 {
          height: 2%;
        }

        .timeline-3 {
          height: 43%;
        }
      }

      .form-column-2 {
        width: 90%;
        padding: 0 0 0 4%;

        .sc-ehmTmK,
        .jmmNfK {
          height: 45px;
        }

        .form-double {
          flex-direction: column;
          width: 100%;
          padding: 0;
          margin: 0;

          label {
            width: 100%;
            padding: 0;
            margin: 0;
          }
        }

        .form-links-row {
          padding: 0 0 15% 0;

          .radio-line {
            padding: 10% 0 0 0;
          }
        }

        .form-button-row {
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;

          .upload-button {
            .button-icon {
              width: 30px;
              height: 40px;
            }
          }

          .upload-paragraph {
            padding: 5% 0;
            align-self: center;
            font-family: Medium;
          }
        }

        .button-container {
          width: 100%;
          flex-direction: column;

          .mobile-button {
            width: 100%;
            font-size: 12px;
            margin: 0 0 10% 0;
          }
        }
      }

      .form-column-3 {
        display: none;
      }
    }

    .sc-kDDrLX,
    .fJGJha {
      height: 50px;
    }
  }

  @media ${breakpointSizes.desktop} {
    .form-container {
      .form-column-1 {
        .timeline-3 {
          height: 38%;
        }
      }
    }
  }
`;

export default RegistrationContainer;
