import styled from "styled-components";

export const PaymentContainer = styled.div`
  .hero-container {
    min-height: 100vh;
    height: auto;
    background-image: none !important;
    z-index: -10;
    display: flex;

    .hero-right,
    .hero-left {
      padding: 0;
    }

    .hero-left {
      width: 45%;
      padding: 4% 0 0 0;

      .individual-package {
        height: auto;
        color: #454a4a;

        ul {
          padding: 0 0 0 2%;
          width: 60%;

          li {
            padding: 0 0 10px 5%;
            color: #636969;
          }

          li::marker {
            color: #fbaf00;
            content: "■";
            font-size: 11px;
          }
        }
      }
    }

    .hero-right {
      width: 55%;
      padding: 0 2%;

      .progress-bar {
        width: 100%;
        padding: 0 0 4% 0;
      }

      .form-line {
        width: 100%;
        padding: 2% 0;
        position: relative;

        label {
          font-size: 14px;
        }
      }

      .form-button-row {
        display: flex;
        padding: 5% 0;
        align-items: center;

        .upload-button {
          display: flex;
          justify-content: center;
          align-items: center;

          .button-icon {
            width: 30px;
            height: 40px;
          }
        }

        .upload-paragraph {
          padding: 0 5%;
        }

        .upload-button:nth-child(2) {
          margin: 0 5%;
        }
      }

      .form-double {
        display: flex;
        justify-content: space-between;

        label {
          width: 48%;
        }
      }

      .payment-links {
        .payment-heading {
          padding: 0 0 2% 0;
        }

        .payment-logos {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0;
          margin: 0;
          width: 100%;
        }

        .sc-gsnTZi,
        .etFMMm,
        .payment-left,
        .payment-right {
          padding: 0;
          width: 48%;
          text-align: center;

          .payment-button {
            background-color: #f4f5f5;
            width: 100%;
            margin: 0 0 2% 0;

            .button-image-1 {
              width: 130px;
              height: 55px;
              padding: 0;
              margin: 0;
            }

            .button-image-2 {
              width: 140px;
              height: 30px;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }

    .padding {
      padding: 0 0 3% 0;
    }

    .spin {
      padding: 10% 0 0 0;
      text-align: center;
    }
  }
`;
