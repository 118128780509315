const size = {
  small: "720px",
  largeMin: "1700px",
   largeMax: "2200px",
   superLargeMin:"2201",
  superLargeMax: "2700"
};

const breakpointSizes = {
  mobile: `(max-width: ${size.small})`,
  desktop: `(min-width: ${size.largeMin} and max-width:${size.largeMax})`,
  large: `(min-width: ${size.superLargeMin} and max-width:${size.superLargeMax})`,
};

export default breakpointSizes;
