import { useRef, useState } from "react";
import {
  ButtonSpace,
  CardParagraph,
  Container,
  CustomButton,
  Heading,
  HeroContainer,
  Paragraph,
  PreHeading,
  SpaceSmall,
} from "../../styles/StyledComponents";
import IndividualContainer from "./style";
import { IndividualFoundhers } from "../../constants";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";

import Eligibility from "../Eligibility";
import { Modal } from "antd";
import { useEffect } from "react";
import OurGrads from "../Grads";
import WMCA from "../../assets/WMCA logo_Digital.png"

const IndividualBootcampLayout = ({
  heroHeading,
  title,
  schedule,
  headingDescription,
  heroImage,
  cardTitle,
  overviewHeading,
  overviewText,
  overviewVideo,
  syllabusText,
  syllabusLessons,
  benefits,
  postBenefits,
  eventsText,
  requirementsText,
  requirementsList,
  tutorsParagraph,
  tutors,
  applicationLink
}) => {
  const overviewSection = useRef(null);
  const syllabusSection = useRef(null);
  const benefitsSection = useRef(null);
  const eventsSection = useRef(null);
  const requirementsSection = useRef(null);
  const tutorsSection = useRef(null);
  const alumniSection = useRef(null);

  const scrollToElement = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const newTitle = title?.replaceAll(' ', '%20')
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const showModal = () => {
    setOpen(true);
  };

  const handleOK = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <IndividualContainer>
        <HeroContainer height={"auto"} className="hero-container">
          <div className="hero-left">
            <Heading fontSize="52px">{heroHeading}</Heading>

            <SpaceSmall />

            <Paragraph fontSize="16px" fontFamily="Medium">
              {schedule}
            </Paragraph>
            <SpaceSmall />

            <Paragraph fontSize="16px">{headingDescription}</Paragraph>

            <ButtonSpace />
            {/* <CustomButton>
              <Link to={`/registration/${title}`}>Apply Now</Link>
            </CustomButton> */}

            <CustomButton>
              <a href={applicationLink} target="_blank">Apply Now</a>
            </CustomButton> <br />
            {/* <CustomButton type="primary" onClick={showModal} className="button-2" background="#fff" color="#FBAF00" border="1px solid #FBAF00">
              Check Eligibility
            </CustomButton> */}
            
            <div className="funding-logo" style={{marginTop:"50px"}}> <p>Funded by: </p><img alt="WMCA Logo" src={WMCA} /></div>

            <Modal
              open={open}
              onOk={handleOK}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
              className="eligibility-modal"
              // width={success ? "100%" : "90%"}
              width={
                success ?
                  (
                    windowSize > 800 ? "40%" : "90%"

                  ) : (
                    "90%"
                  )
              }
              bodyStyle={success ? { height: "" } : { height: "auto" }}
              style={success ? { top: 200 } : { top: 10 }}
              footer={null}
            >
              <Eligibility handleCancel={handleCancel} success={success} setSuccess={setSuccess} applicationLink={`${window?.location.origin}/registration/${newTitle}`} />
            </Modal>
          </div>

          <div className="hero-right">
            <img src={heroImage} className="hero-image" alt="hero background" />
          </div>
        </HeroContainer>

        <SpaceSmall className="mobile-hidden" />

        <Container className="section-container">
          <div className="section section-left">
            <div className="details-box">
              <Paragraph
                fontSize="14px"
                color="#636969"
                fontFamily="Regular"
                className="card-title"
              >
                {cardTitle}
              </Paragraph>

              <div className="divider-line"></div>

              <Paragraph className="text">
                <a
                  href="#overview"
                  onClick={() => scrollToElement(overviewSection)}
                >
                  Overview
                </a>
              </Paragraph>

              <Paragraph className="text">
                <a
                  href="#syllabus"
                  onClick={() => scrollToElement(syllabusSection)}
                >
                  Syllabus
                </a>
              </Paragraph>

              <Paragraph className="text">
                <a
                  href="#benefits"
                  onClick={() => scrollToElement(benefitsSection)}
                >
                  Skills You'll Gain
                </a>
              </Paragraph>

              <Paragraph className="text">
                <a
                  href="#events"
                  onClick={() => scrollToElement(eventsSection)}
                >
                  Empowherment Evenings
                </a>
              </Paragraph>

              <Paragraph className="text">
                <a
                  href="#requirements"
                  onClick={() => scrollToElement(requirementsSection)}
                >
                  Requirements
                </a>
              </Paragraph>

              <Paragraph className="text">
                <a
                  href="#tutors"
                  onClick={() => scrollToElement(tutorsSection)}
                >
                  Tutors
                </a>
              </Paragraph>

              <Paragraph className="text">
                <a
                  href="#alumni"
                  onClick={() => scrollToElement(alumniSection)}
                >
                  Alumni
                </a>
              </Paragraph>

              {/* <CustomButton
                width="55%"
                height="40px"
                fontSize="13px"
                className="mobile-hidden"
              >
                <Link to={`/registration/${title}`}>Apply Now</Link>
              </CustomButton> */}

              <CustomButton width="55%"
                height="40px"
                fontSize="13px"
                className="mobile-hidden">
                <a href={applicationLink}>Apply Now</a>
              </CustomButton>
            </div>
          </div>

          <Container className="section section-right">
            <Container className="course-items">
              <div
                className="overview-section"
                id="overview"
                ref={overviewSection}
              >
                <PreHeading>OVERVIEW</PreHeading>
                <Heading
                  fontSize="30px"
                  lineHeight="32px"
                  className="mobile-heading"
                >
                  {overviewHeading}
                </Heading>

                <SpaceSmall />

                <Paragraph fontSize="20px" className="mobile-text">
                  {overviewText}
                </Paragraph>
                <SpaceSmall />


                <iframe height="600" className="overview-video" src={overviewVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                <SpaceSmall />
              </div>

              <SpaceSmall />

              <div
                className="syllabus-section"
                id="syllabus"
                ref={syllabusSection}
              >
                <PreHeading>SYLLABUS</PreHeading>
                <Heading
                  fontSize="30px"
                  lineHeight="32px"
                  className="mobile-heading"
                >
                  What You'll Learn From This Course
                </Heading>

                <SpaceSmall />

                <Paragraph fontSize="20px" className="mobile-text">
                  {syllabusText}
                </Paragraph>

                <SpaceSmall />

                <Accordion allowZeroExpanded className="accordion-section">
                  {syllabusLessons.map((item, index) => {
                    return (
                      <AccordionItem className="syllabus-item" key={item.weekTitle}>
                          <AccordionItemHeading className="syllabus-heading">
                            <AccordionItemButton className="syllabus-top" style={{ cursor: "pointer" }}>
                              <CardParagraph
                                className="week-tag"
                                fontSize="12px"
                              >
                                {item.weekNumber}
                              </CardParagraph>
                              <Heading
                                fontSize="20px"
                                lineHeight="24px"
                                className="week-title"
                              >
                                {item.weekTitle}
                              </Heading>
                              <img
                                src={IndividualFoundhers.syllabusIcon}
                                className="syllabus-icon"
                                alt="icon"
                              />
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel style={{ paddingBottom: "20px" }}>
                            {item.content.map((data) => {
                              return (
                                <li key={data} style={{ marginLeft: "40px", marginBottom: "10px" }}>{data}</li>
                              )
                            })}

                          </AccordionItemPanel>
                        </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>

              <SpaceSmall />

              <div
                className="benefits-section"
                id="benefits"
                ref={benefitsSection}
              >
                <PreHeading>BENEFITS / SKILLS</PreHeading>

                <Heading
                  fontSize="30px"
                  lineHeight="32px"
                  className="mobile-heading"
                >
                  What You  Stand To Gain
                </Heading>

                <ul className="list">
                  {benefits.map((item, index) => {
                    return (
                      <li key={index}>{item}</li>
                    );
                  })}
                </ul>

                <Heading
                  fontSize="25px"
                  lineHeight="30px"
                  className="mobile-heading"
                >
                  Post Bootcamp
                </Heading>
                <ul className="list">

                   {postBenefits?.map((item, index) => {
                    return (
                      <li key={index}>{item}</li>
                    );
                  })}
                </ul>

                <SpaceSmall />
               


              </div>

              

              <SpaceSmall />

              <div className="events-section" id="events" ref={eventsSection}>
                <PreHeading>Events</PreHeading>

                <Heading
                  fontSize="30px"
                  lineHeight="32px"
                  className="mobile-heading"
                >
                  Empowherment Evenings
                </Heading>

                <SpaceSmall />

                <Paragraph fontSize="20px" className="mobile-text">
                  {eventsText}
                </Paragraph>

                <SpaceSmall />
              </div>

              <SpaceSmall />

              <div
                className="requirements-section"
                id="requirements"
                ref={requirementsSection}
              >
                <PreHeading>REQUIREMENTS</PreHeading>

                <Heading
                  fontSize="30px"
                  lineHeight="32px"
                  className="mobile-heading"
                >
                  Eligibility / Requirements
                </Heading>

                <SpaceSmall />

                <Paragraph fontSize="20px" className="mobile-text">
                  {requirementsText}
                </Paragraph>

                <ul className="list">
                  {requirementsList.map((item, index) => {
                    return (
                      <li key={index}>{item}</li>
                    );
                  })}
                </ul>
                <SpaceSmall />
              </div>

              <div className="tutors-section" id="tutors" ref={tutorsSection}>
                <PreHeading>TUTORS</PreHeading>
                <Heading
                  fontSize="30px"
                  lineHeight="32px"
                  className="mobile-heading"
                >
                  Meet Your Tutors
                </Heading>
                <SpaceSmall />

                <Paragraph className="mobile-text" fontSize="20px">
                  {tutorsParagraph}
                </Paragraph>

                <div className="tutors-cards">
                  {tutors.map((item, index) => {
                    return (
                        <div className="individual-tutor" key={index}>
                          <div className="tutor-image" style={{ backgroundImage: `url(${item.tutorImage})` }} >

                          </div>
                          <Heading
                            fontSize="19px"
                            lineHeight="24px"
                            className="padding"
                          >
                            {item.tutorName}
                          </Heading>
                          <Paragraph
                            fontSize="15px"
                            color="#454A4A"
                            className="padding"
                          >
                            {item.tutorTitle}
                          </Paragraph>
                          <Paragraph fontSize="16px" className="padding">
                            {item.tutorDescription}
                          </Paragraph>
                        </div>
                    );
                  })}
                </div>

                <SpaceSmall />
              </div>
            </Container>
          </Container>
        </Container>

        <SpaceSmall />

        {/* <Container className="grads-section" id="alumni">
          <PreHeading>BOOTCAMP GRADS</PreHeading>
          <Heading className="grads-heading">
            Meet Some of Our Bootcamp <span>Grads</span>
          </Heading>
          <SpaceSmall />
        </Container> */}

        <OurGrads />
      </IndividualContainer>
  );
};

export default IndividualBootcampLayout;
