import HairContainer from "./style";

import {
  HeroContainer,
  PreHeading,
  SpaceSmall,
  Heading,
  Paragraph,
  Container,
  CardParagraph,
  CustomButton,
} from "../../../styles/StyledComponents";
import {
  HairBootcampsIndividual,
  HairBootcampsComingSoon
} from "../../../constants";
import Upskilling from "../../../components/Upskilling";
import OurGrads from "../../../components/Grads";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const HairPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <HairContainer>
        <HeroContainer height={"auto"} className="hero-container">
          <div className="hero-left">
            <PreHeading>HAIR BOOTCAMPS</PreHeading>
            <SpaceSmall />
            <Heading>
              <span>Explore</span> our Bootcamps to see the various courses.
            </Heading>
            <SpaceSmall />
            <Paragraph>
              Are you looking for a pathway into having a career in tech, or a
              high impact industry? Learn with a growing community of people
               in tech, passionate about changing the narrative too!
            </Paragraph>
          </div>
          <div className="hero-right">
            <div className="hero-image"></div>
          </div>
        </HeroContainer>

        <Container className="cards-section">
          <SpaceSmall />
          <PreHeading className="hair-span">HAIR & BEAUTY</PreHeading>

          <Heading>
            Start your journey in any of our{" "}
            <span className="hair-span">Hair & Beauty Bootcamps</span>
          </Heading>
          <SpaceSmall />
          <div className="card-container">
            {HairBootcampsIndividual.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <Heading className="card-title" color={item.color}>
                    {item.title}
                  </Heading>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.description}
                  </CardParagraph>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.schedule}
                  </CardParagraph>
                  <Link to="/hair-beauty-course">
                    {/* <CustomButton
                      className="card-button"
                      border={"2px solid #FF47CE"}
                      background={item.background}
                      color="#FF47CE"
                    >
                      <Link to={item.path}>View Bootcamp</Link>{" "}
                    </CustomButton> */}

                    {item.externalLink ? (
                      <a
                        href={item.externalLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CustomButton
                          className="card-button"
                          border={'2px solid #FF47CE'}
                          background={item.background}
                          color="#FF47CE"
                          hoverColor="#fff"
                          hover="#FF47CE"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                      </a>
                    ) : (
                      <Link to={`${item.path}/${item?.title}`}>
                        <CustomButton
                         className="card-button"
                         border={'2px solid #FF47CE'}
                         background={item.background}
                         color="#FF47CE"
                         hoverColor="#fff"
                         hover="#FF47CE"
                        >
                          {item.buttonInfo}
                        </CustomButton>
                      </Link>

                      

                      
                    )}
                  </Link>
                  <SpaceSmall />
                </Container>
              );
            })}
          </div>

          <Heading className="coming-soon desktop-hidden">Coming Soon</Heading>

          <div className="card-container">
            {HairBootcampsComingSoon.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <Heading className="card-title" color={item.color}>
                    {item.title}
                  </Heading>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.description}
                  </CardParagraph>
                  <img src={item.rating} className="card-rating" alt="rating" />
                  <CardParagraph className="card-text" color={item.color}>
                    {item.schedule}
                  </CardParagraph>
                  {item.externalLink ? (
                    <a
                      href={item.externalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CustomButton
                        border={"2px solid #FF47CE"}
                        background={item.background}
                        color="#FF47CE"
                      >
                        {item.buttonInfo}
                      </CustomButton>
                    </a>
                  ) : (
                    <CustomButton
                      className="card-button"
                      border={"2px solid #FF47CE"}
                      background={item.background}
                      color="#FF47CE"
                    >
                      <Link to={item.path}>{item.buttonInfo}</Link>
                    </CustomButton>
                  )}
                  <SpaceSmall />
                </Container>
              );
            })}
          </div>

          <div className="mobile-swipe-container desktop-hidden">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {HairBootcampsIndividual.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        />
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        {item.externalLink ? (
                          <a
                            href={item.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CustomButton
                              border={"2px solid #FF47CE"}
                              background={item.background}
                              color="#FF47CE"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </a>
                        ) : (
                          <CustomButton
                            className="card-button"
                            border={"2px solid #FF47CE"}
                            background={item.background}
                            color="#FF47CE"
                          >
                            <Link to={item.path}>{item.buttonInfo}</Link>
                          </CustomButton>
                        )}
                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>

          <SpaceSmall className="desktop-hidden" />

          <PreHeading className="desktop-hidden">COMING SOON</PreHeading>
          <div className="mobile-swipe-container desktop-hidden">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {HairBootcampsComingSoon.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        />
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        {item.externalLink ? (
                          <a
                            href={item.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CustomButton
                              border={"2px solid #FF47CE"}
                              background={item.background}
                              color="#FF47CE"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </a>
                        ) : (
                          <CustomButton
                            className="card-button"
                            border={"2px solid #FF47CE"}
                            background={item.background}
                            color="#FF47CE"
                          >
                            <Link to={item.path}>{item.buttonInfo}</Link>
                          </CustomButton>
                        )}
                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </Container>

        <Upskilling />

        <OurGrads />

        {/* <FAQS FAQList={BootcampFAQS} /> */}
      </HairContainer>
    </>
  );
};

export default HairPage;
