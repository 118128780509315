import {
  Web3BootcampsIndividual,
  Web3BootcampsComingSoon,
  HomeImages,
  ourGrads,
} from "../../../constants";
import {
  HeroContainer,
  PreHeading,
  SpaceSmall,
  SpaceMedium,
  Heading,
  Paragraph,
  Container,
  CardParagraph,
  CustomButton,
} from "../../../styles/StyledComponents";
import Web3Container from "./style";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { useEffect } from "react";

const Web3Page = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Web3Container>
        <HeroContainer height={"auto"} className="hero-container">
          <div className="hero-left">
            <PreHeading className="hero-preheading">
              TECHNOLOGY BOOTCAMPS
            </PreHeading>
            <SpaceSmall />
            <Heading className="hero-heading">
              <span>Explore</span> our Bootcamps to see the various courses.
            </Heading>
            <SpaceSmall />
            <Paragraph className="hero-text">
              Are you looking for a pathway into having a career in tech, or a
              high impact industry? Learn with a growing community of people
               in tech, passionate about changing the narrative too!
            </Paragraph>
          </div>
          <div className="hero-right">
            <div className="hero-image"></div>
          </div>
        </HeroContainer>

        <Container className="cards-section">
          <PreHeading className="preheading">TECHNOLOGY BOOTCAMP</PreHeading>
          <SpaceSmall className="mobile-hidden" />
          <Heading className="top-heading" color="#fff">
            Start your journey in and of our <span>Web 3.0 Bootcamps</span>
          </Heading>
          <SpaceMedium />
          <div className="card-container">
            {Web3BootcampsIndividual.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <Heading className="card-title" color={item.color}>
                    {item.topic}
                  </Heading>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.description}
                  </CardParagraph>

                  <CardParagraph className="card-text" color={item.color}>
                    {item.schedule}
                  </CardParagraph>
                  {item.externalLink ? (
                    <a
                      href={item.externalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CustomButton
                        className="card-button"
                        border={"2px solid #E09D00"}
                        background={item.background}
                        color="#E09D00"
                      >
                        {item.buttonInfo}
                      </CustomButton>
                    </a>
                  ) : (
                    <Link to={`${item.path}/${item?.title}`}>
                      <CustomButton
                        className="card-button"
                        border={"2px solid #fff"}
                        background={item.background}
                        color="#fff"
                        hoverColor="#000"
                        hover="#fff"
                      >
                        {item.buttonInfo}
                      </CustomButton>
                    </Link>
                  )}

                  <SpaceSmall />
                </Container>
              );
            })}
          </div>

          <Heading className="coming-soon" color="#fff">
            Coming Soon
          </Heading>

          <div className="card-container">
            {Web3BootcampsComingSoon.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <Heading className="card-title" color={item.color}>
                    {item.topic}
                  </Heading>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.description}
                  </CardParagraph>

                  <CardParagraph className="card-text" color={item.color}>
                    {item.schedule}
                  </CardParagraph>
                  {item.externalLink ? (
                    <a
                      href={item.externalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CustomButton
                        className="card-button"
                        border={"2px solid #fff"}
                        background={item.background}
                        color="#fff"
                        hoverColor="#000"
                        hover="#fff"
                      >
                        {item.buttonInfo}
                      </CustomButton>
                    </a>
                  ) : (
                    <CustomButton
                      className="card-button"
                      border={"2px solid #E09D00"}
                      background={item.background}
                      color="#E09D00"
                    >
                      <Link to={item.path}>{item.buttonInfo}</Link>
                    </CustomButton>
                  )}

                  <SpaceSmall />
                </Container>
              );
            })}
          </div>

          <div className="mobile-swipe-container desktop-hidden">
            <Swiper
             slidesPerView={1.1}
             spaceBetween={10}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {Web3BootcampsIndividual.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.topic}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        />
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        {item.externalLink ? (
                          <a
                            href={item.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CustomButton
                              className="card-button"
                              border={"2px solid #fff"}
                              background={item.background}
                              color="#fff"
                              hoverColor="#000"
                              hover="#fff"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </a>
                        ) : (
                          <CustomButton
                            className="card-button"
                            border={"2px solid #E09D00"}
                            background={item.background}
                            color="#E09D00"
                          >
                            <Link to={item.path}>{item.buttonInfo}</Link>
                          </CustomButton>
                        )}

                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>

          <SpaceSmall className="desktop-hidden" />

          <PreHeading className="desktop-hidden" color="#fff">
            COMING SOON
          </PreHeading>
          <div className="mobile-swipe-container desktop-hidden">
            <Swiper
              slidesPerView={1.1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {Web3BootcampsComingSoon.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.topic}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        />
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        {item.externalLink ? (
                          <a
                            href={item.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CustomButton
                              className="card-button"
                              border={"2px solid #E09D00"}
                              background={item.background}
                              color="#E09D00"
                            >
                              {item.buttonInfo}
                            </CustomButton>
                          </a>
                        ) : (
                          <CustomButton
                            className="card-button"
                            border={"2px solid #E09D00"}
                            background={item.background}
                            color="#E09D00"
                          >
                            <Link to={item.path}>{item.buttonInfo}</Link>
                          </CustomButton>
                        )}

                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </Container>

        <Container className="upskilling">
          <Container className="upskilling-left">
            <Heading className="upskilling-heading" color="#fff">
              Upskilling people in <span>Tech</span>
            </Heading>
          </Container>
          <Container className="upskilling-right">
            <Paragraph className="upskilling-text" color="#fff">
              Niyo Bootcamps (previously known as Niyo Network) is our training
              ground. It aims to use skilled bootcamps in technology to equip
              and upskill unemployed people, at risk of unemployment, or in
              low-paid jobs. <br />
              <br /> Niyo creates opportunities that cater directly to their
              purposeful paths; whether it be employed in a high-impact industry
              or running their own disruptive business.
            </Paragraph>
          </Container>
        </Container>

        <Container className="grads-section">
          <PreHeading>BOOTCAMP GRADS</PreHeading>
          <SpaceSmall />
          <Heading color="#fff">
            Meet Some of Our <br />
            Bootcamp <span>Grads</span>
          </Heading>
          <SpaceMedium className="mobile-space" />
          <div className="grads-list">
            {ourGrads.map((item, index) => {
              return (
                <>
                  <div className="grad-card" key={index}>
                    <PreHeading>{item.bootcamp}</PreHeading>
                    <iframe
                      width="100%"
                      height="315"
                      src={item.youtube}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>

                    <Heading
                      fontSize={"16px"}
                      className="grad-heading"
                      color="#fff"
                    >
                      {item.name}
                    </Heading>
                    <CardParagraph color="#fff">{item.story}</CardParagraph>
                    <a href={item.path} style={{ textDecoration: "none" }}>
                      <CustomButton className="button-arrow grad-button">
                        Watch {item.name.split(" ")[0]}'s story{" "}
                        <img
                          src={HomeImages.gradArrow}
                          className="grad-arrow"
                          alt="arrow"
                        />
                      </CustomButton>
                    </a>
                  </div>
                </>
              );
            })}
          </div>
          <div className="grads-button-container">
            <CustomButton className="more-button">
              <a href="https://www.youtube.com/@oyink46">
                <div className="button-arrow ">
                  Watch more stories
                  <img
                    src={HomeImages.whiteArrow}
                    className="white-arrow"
                    alt="arrow"
                  />
                </div>
              </a>
            </CustomButton>
          </div>
          <SpaceMedium />
        </Container>

        {/* <FAQS FAQList={BootcampFAQS} backgroundColor={"#FBAF00"} /> */}
      </Web3Container>
    </>
  );
};

export default Web3Page;
