import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

export const TopHeader = styled.div`
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 20;

  .info{
    background-color:#140e00;
    height:60px;
    text-align: center;
    padding-top:15px;
    h4{
      color:#fff;
      font-size:18px;
      a{
        color:#fbaf00;
        text-decoration:none;
      }
    }
  }


  .ant-modal,
  .ant-modal-content {
    padding: 0 !important;
    background-color: transparent;
  }

  .navbar {
    display: flex;
    justify-content: space-between;

    .logo {
      width: 20%;
      img {
        margin-top: 10px;
        width: 100px;
      }
    }

    .nav-links {
      display: flex;
      justify-content: space-between;
      width: 80%;
      li {
        list-style: none;
        margin-top: 15px;
        a {
          color: #000;
          font-family: Medium;
          text-decoration: none;
          transition: 0.2s ease-in-out;
        }
        svg {
          padding-bottom: -10px !important;
        }
        a:hover {
          color: #fbaf00;
        }
      }

      .join-button {
        border-radius: 0;
        color: #fbaf00;
        border: 1px solid #fbaf00;
        background-color: #fff;
        height: 56px;
        width: 204px;
        font-family: "Regular";
        font-size: 16px;
        transition: 0.2s ease-in-out;
        cursor: pointer;
      }
    }

    .ant-menu {
      display: none;
    }

    .community-drawer {
      width: 100vw;
    }
  }

  .navbar.active {
    background-color: #f4f4f4;
  }

  .nav-menu {
    background-color: #f4f4f4;
    width: 80%;
    height: 100%;
    padding-top: 60px;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    text-align: left;
    box-shadow: 0 40px 40px rgba(0, 0, 0, 0.1);

    li {
      list-style: none;
      margin-left: 30px;
      margin-bottom: 30px;
    }

    .cancel {
      float: right;
      margin-right: 30px;
      margin-top: -20px;
    }

    a {
      color: #000;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      font-family: regular;
      text-align: left;
      width: auto;

      img {
        width: 20px;
        margin-right: 10px;
        margin-bottom: -5px;
      }
      span {
        margin-top: -3px;
      }
    }
    .join-family {
      text-align: center;
    }
    button {
      height: 50px;
      width: 200px;
      font-size: 14px;
      text-align: center;
    }
  }

  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }

  .mobile-menu-container {
    display: none;
  }

  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  .nav-link-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
  }

  @media ${breakpointSizes.mobile} {
    .info{
      height: 55px;;
      padding: 7px 50px;
    h4{
      font-size:14px;
    
     
    }
  }
    .navbar {
      .nav-links {
        display: none;
      }
    }
  }

  /* .hamburger-menu {
      .button {
      }
    } */

  .hamburger-section {
    display: block;
    height: 30px;
    width: 30px;
    border: none;
    background-color: transparent;
  }

  .side-menu-active {
    display: block;
    width: 100%;
    height: auto;
    padding: 20px 10% 20px 10%;
    position: sticky;
    display: block;
    display: flex;
    align-items: center;

    .mobile-menu-container {
      li {
        text-decoration: none;
        list-style: none;
        height: 45px;
        width: 100%;
        margin: 0 0 20px 0;
        border-bottom: 1px solid #d5d7d7;
        color: #070909;

        a:link,
        a:visited,
        a:hover,
        a:active {
          color: #070909;
          text-decoration: none;
        }
      }
    }
  }

  .side-menu {
    display: none;
  }

  .community-drawer {
    display: none;
  }

  @media ${breakpointSizes.desktop} {
  }
`;
