import styled from 'styled-components'
import { Background } from '../constants'
import breakpointSizes from '../constants/breakpointSizes'

export const CustomButton = styled.button`
  border: ${({ border }) => (border ? border : 'none')};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : 'none'};
  background: ${({ background }) => (background ? background : '#FBAF00')};
  color: ${({ color }) => (color ? color : '#fff')};
  height: ${({ height }) => (height ? height : '56px')};
  width: ${({ width }) => (width ? width : '204px')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Regular')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ hover }) => (hover ? hover : '#e09d00')};
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#fff')};
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`
export const CustomDiv = styled.div`
  border: ${({ border }) => (border ? border : 'none')};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : 'none'};
  background: ${({ background }) => (background ? background : '#FBAF00')};
  color: ${({ color }) => (color ? color : '#fff')};
  height: ${({ height }) => (height ? height : '56px')};
  width: ${({ width }) => (width ? width : '204px')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Regular')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ hover }) => (hover ? hover : '#e09d00')};
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#fff')};
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const Container = styled.div`
  width: 100%;
  padding: 20px 10%;
  height: auto;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  background-color: ${({ background }) => (background ? background : '')};

  @media ${breakpointSizes.mobile} {
    padding: 20px;
  }

  @media ${breakpointSizes.desktop} {
    padding:20px 350px;
  }
  @media ${breakpointSizes.large} {
    padding:20px 650px;
  }
`

export const HeroContainer = styled.div`
  width: 100%;
  padding: 150px 10% 20px 10%;
  height: ${({ height }) => (height ? height : '100vh')};
  background-image: url(${Background.hero});
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  @media ${breakpointSizes.desktop} {
    padding:150px 350px;
  }
  @media ${breakpointSizes.large} {
    padding:20px 650px
  }
  @media ${breakpointSizes.mobile} {
    padding:130px 20px 20px 20px;
  }
`

export const Heading = styled.h1`
  color: ${({ color }) => (color ? color : '#000')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Bold')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '60px')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '62px')};
  -webkit-text-stroke: ${({ textStroke }) =>
    textStroke ? textStroke : 'none'};
    a{
      color:#000;
      text-decoration:none;
    }

  span {
    color: #fbaf00;
  }

  @media ${breakpointSizes.mobile} {
    font-size: 22px;
    line-height: 46px;
  }
`

export const PreHeading = styled.h6`
  color: ${({ color }) => (color ? color : '#FBAF00')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Medium')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  text-transform: uppercase;
`

export const Paragraph = styled.p`
  color: ${({ color }) => (color ? color : '#000')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Light')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
`

export const Span = styled.span`
  color: ${({ color }) => (color ? color : '#000')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Light')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
  font-weight: bolder;
  font-size: 20px;
  line-height: 40px;
  /* or 125% */

  letter-spacing: -1px;
`

export const CardParagraph = styled.p`
  color: ${({ color }) => (color ? color : '#000')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Light')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
`

export const InputField = styled.input`
  color: ${({ color }) => (color ? color : "#000")};
  height: 60px !important;
  width: ${({ width }) => (width ? width : "100%")};
  margin: 1% 0 2% 0;
  border: 1px solid #b5baba;
  color: #000;
  padding: 0 0 0 2%;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Light")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  background-color: #fff;
  ::placeholder {
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Light")};
    color: #a0a6a6;
    font-size: 14px;
  }

  @media ${breakpointSizes.mobile} {
    height: 45px;
    padding: 0 0 0 2%;
    margin: 1% 0 2% 0;
  }
`;

export const TextareaField = styled.textarea`
  color: ${({ color }) => (color ? color : '#000')};
  height: ${({ height }) => (height ? height : '300px')};
  width: ${({ width }) => (width ? width : '100%')};
  margin: 1% 0 2% 0;
  border: 1px solid #b5baba;
  outline: none;
  padding: 2% 0 0 2%;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Light')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  background-color: #fff;
  ::placeholder {
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Light')};
    color: #a0a6a6;
    font-size: 14px;
  }
`

export const SelectField = styled.select`
  color: ${({ color }) => (color ? color : '#000')};
  height: 60px;
  /* height: 60px; */
  width: ${({ width }) => (width ? width : '100%')};
  margin: 1% 0 2% 0;
  border: 1px solid #b5baba;
  padding: 0 0 0 2%;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Light')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  background-color: #fff;
  option:first-child {
    color: #a0a6a6 !important;
  }
  ::placeholder {
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Light')};
    color: #a0a6a6;
    font-size: 14px;
  }

  @media ${breakpointSizes.mobile} {
    height: 45px;
    padding: 0 0 0 2%;
    margin: 1% 0 2% 0;
  }
`
export const SpaceLarge = styled.div`
  height: 150px;
  width: 100px;
`

export const SpaceMedium = styled.div`
  height: 80px;
  width: 100px;
  @media ${breakpointSizes.mobile}{
   height:40px;
  }
`
export const ButtonSpace = styled.div`
  height: 50px;
  width: 100px;
  @media ${breakpointSizes.mobile}{
   height:30px;
  }
`
export const SpaceSmall = styled.div`
  height: 30px;
  width: 100px;
`
