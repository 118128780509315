import React from "react";
import { Link } from "react-router-dom";
import { Logos, ourSocials } from "../../constants";
import {
  ButtonSpace,
  Container,
  CustomButton,
  Heading,
  Paragraph,
  SpaceMedium,
  SpaceSmall,
} from "../../styles/StyledComponents";
import { FooterContainer, HorizontalLine } from "./style";
import doc from "../../documents/Supply Chain Policy for Skills Bootcamp Delivery.pdf"

const Footer = () => {
  return (
    <FooterContainer>
      <Container textAlign="center" className="mobile-text">
        <Heading color="#fff">
          Ready to start a career in <span>tech?</span>
        </Heading>
        <SpaceSmall />
        <Paragraph color="#fff">
          Choose a bootcamp, join 7000+ women building their career.
        </Paragraph>

        <ButtonSpace />
        <Link to="/bootcamps"><CustomButton>
          View bootcamps
        </CustomButton></Link>
      </Container>

      <HorizontalLine />

      <Container className="footer-links">
        <div className="short-bio">
          <img src={Logos.whiteLogo} alt="Niyo Bootcamp white logo" />
          <SpaceSmall />
          <Paragraph fontSize="16px" color="#fff" className="mobile-links">
            We upskill, we support, <br />we get them
            employed! - <br />Feel empowered, be empowered
          </Paragraph>
          <SpaceSmall />
          <div className="social-icons">
            {ourSocials.map((item, index) => {
              return (
                <>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    <img
                      src={item.image}
                      className="socials-icon"
                      alt="social media icon"
                    />
                  </a>
                </>
              );
            })}
          </div>
        </div>

        <div className="bootcamps links">
          <h4>Bootcamps</h4>
          <li>
            <Link to="/web2-bootcamps">Technology Bootcamp</Link>
          </li>
          <li>
            <Link to="/hair-bootcamps">
              Hair and Beauty Technology Bootcamp
            </Link>
          </li>
          <li>
            <Link to="/foundher-bootcamps">Foundhers Bootcamps</Link>
          </li>
          <li>
            <Link to="/fashion-bootcamps">Fashion Technology Bootcamps</Link>
          </li>
        </div>

        <div className="company links">
          <h4>Company</h4>
          <li>
            <Link to="/partners">Partner with us</Link>
          </li>
          <li>
            <a href={doc}>Supply Chain Policy</a>
          </li>
          {/* <li>
            <Link to="/">Privacy & Policy</Link>
          </li>
          <li>
            <Link to="/">Terms of service</Link>
          </li> */}
        </div>

        <div className="about links">
          <h4>About Us</h4>
          <li>
            <a href="https://medium.com/niyogroup">Blog</a>
          </li>
          <li>
            <Link to="/our-story">Our Story</Link>
          </li>
          {/* <li>
            <Link to="/contact">Contact Us</Link>
          </li> */}
        </div>

        <div className="connect links">
          <h4>Connect with us</h4>
          <li>
            <a href="https://instagram.com/niyobootcamps?igshid=YmMyMTA2M2Y=">
              Instagram
            </a>
          </li>
          <li>
            <a href="https://twitter.com/niyobootcamps?s=21&t=pGaqXPG0rgChO94aYF2r7g">
              Twitter
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/school/niyobootcamps/">
              LinkedIn
            </a>
          </li>
        </div>
      </Container>
      <SpaceMedium />
      <Container className="copyright">
        ©{new Date().getFullYear()} Niyo Bootcamp. A <a style={{ color: "#FBAF01", textTransformation: "none" }} href="https://www.niyo.co" >Niyo Group</a> Company. All rights reserved.
      </Container>
    </FooterContainer>
  );
};

export default Footer;
