import { ToastContainer, toast } from 'react-toastify'

export const errorHandler = (response) => {
    // if (response) {

    //     if (error === "Error verifying NIN") {
    //         toast.error('Error on NIN field, please recheck NIN field and make sure your using a right NIN', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (error.includes("Invalid postcode")) {
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         toast.error('Error on postalCode field, please recheck field and make sure your using a right postal code', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         return
    //       }
    //       if (error === "Error verifying postcode") {
    //         toast.error('Error on postalCode field, please recheck field and make sure your using a right postal code', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       const errorMessage = error?.map(data => data.name);
    //       if (errorMessage?.includes("fullName")) {
    //         toast.error('Error on fullName field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("email")) {
    //         toast.error('Error on email field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("ninNo")) {
    //         toast.error('Error on ninNo field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("leaveInTheUk")) {
    //         toast.error('Error on based in england field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("participatedInPrevBootcamps")) {
    //         toast.error('Error on participated In Prev Bootcamps field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("higherEduLevel")) {
    //         toast.error('Error on higher Level of Study field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("currentSalary")) {
    //         toast.error('Error on Annual Salary field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
      
    //       if (errorMessage?.includes("hoursPerWeek")) {
    //         toast.error('Error on hours Per Week field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("jobTitle")) {
    //         toast.error('Error on job Role field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("industry")) {
    //         toast.error('Error on industry field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("britishCitizen")) {
    //         toast.error('Error on british Citizen field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("ethnicity")) {
    //         toast.error('Error on ethnicity field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("dateOfBirth")) {
    //         toast.error('Error on date Of Birth field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("continueWorking")) {
    //         toast.error('Error on continue Working on Bootcamp field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("eligibleToWorkUk")) {
    //         toast.error('Error on eligible To Work in Uk field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("postalCode")) {
    //         toast.error('Error on Postal Code field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("phoneNumber")) {
    //         toast.error('Error on Phone number field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("employmentDetails")) {
    //         toast.error('Error on employment Details fields, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("gender")) {
    //         toast.error('Error on Gender field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (errorMessage?.includes("locationData")) {
    //         toast.error('Error on locationData field, please recheck field', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    //       if (error) {
    //         toast.error('Please recheck all fields and make sure all fields are appropriately filled', {
    //           position: toast.POSITION.TOP_CENTER,
    //         })
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //         return
    //       }
    // }
  };