import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './config'

export const registration = createAsyncThunk(
  'register/createregistration',
  async ({ eligbilityCode, formData }, { rejectWithValue }) => {
    try {
      console.log(formData, 'eligbilityCodeeligbilityCode')

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const data = await client.post(
        `${BASE_URL}bootcamp/registration/complete/${eligbilityCode}`,
          formData,
          config,
      )
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error?.response?.data?.message) {
        // errorHandler(error?.response?.data?.message)
        return rejectWithValue(error?.response?.data?.message)
      } else {
        return rejectWithValue(error?.response?.data?.message)
      }
    }
  }
)
