import {
  CardParagraph,
  Container,
  CustomButton,
  Heading,
  PreHeading,
} from "../../styles/StyledComponents";
import { Dropdown, Menu } from "antd";
import { CommunityContainer } from "./style";
import { CommunityData } from "../../constants";
import { FaAngleDown } from "react-icons/fa";

const Community = ({ showModal }) => {
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <>
              <CommunityContainer className="community-container">
                <PreHeading className="community-preheading" color="#070909">
                  NIYO BOOTCAMP COMMUNITY
                </PreHeading>
                <div className="preheading-underline"></div>
                <Container className="community-cards">
                  {CommunityData.map((item, index) => {
                    return (
                      <>
                        <Container key={index} className="individual-card">
                          <img
                            src={item.image}
                            alt="background"
                            className="card-image"
                          />
                          <Heading className="card-title" fontSize="30px">
                            {item.title}
                          </Heading>
                          <CardParagraph className="card-description">
                            {item.description}
                          </CardParagraph>

                          {index === 0 ? (
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                showModal();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <CustomButton
                                width="180px"
                                height="50px"
                                className="mobile-button"
                              >
                                {item.buttonText}
                                <img
                                  style={{
                                    width: "15px",
                                    height: "auto",
                                    marginLeft: "10px",
                                  }}
                                  src={item.icon}
                                  alt=""
                                />
                              </CustomButton>
                            </a>
                          ) : (
                            <a
                              href={item.externalLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <CustomButton
                                width="180px"
                                height="50px"
                                className="mobile-button"
                              >
                                {item.buttonText}
                                <img
                                  style={{
                                    width: "15px",
                                    height: "auto",
                                    marginLeft: "10px",
                                  }}
                                  src={item.icon}
                                  alt=""
                                />
                              </CustomButton>
                            </a>
                          )}
                        </Container>
                      </>
                    );
                  })}
                </Container>
              </CommunityContainer>
            </>
          ),
        },
      ]}
    />
  );
  return (
    <>
      <Dropdown overlay={menu} placement="bottomLeft">
        <p
          onClick={(e) => e.preventDefault()}
          style={{
            color: "#000",
            fontSize: "16px",
            cursor: "pointer",
            fontFamily: "Medium",
            marginTop: "16px",
          }}
        >
          Community <FaAngleDown />
        </p>
      </Dropdown>
    </>
  );
};

export default Community;
