import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

const GradsContainer = styled.div`
  width: 100%;
  padding: 20px 10%;
  background-color: ${({ background }) =>
    background ? background : "#fffcfa"};

  .grads-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    .grad-card {
      margin: 0;
      width: 32%;
      height: auto;
      padding: 0;

      a {
        text-decoration: none;
      }

      .grad-button {
        background-color: #fffcf4;
        color: #fbaf00;
        text-align: left;
        font-size: 14px;
        width: 100%;
        justify-content: left;
        padding: 5% 0 0 0;
        margin: 0;
        font-family: bold;

        .grad-arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fbaf00;
          width: 20px;
          margin: 0 0 0 2%;
        }
      }
    }
  }

  .grads-button-container {
    display: flex;
    justify-content: center;
  }

  @media ${breakpointSizes.mobile} {
    padding: 20px;

    .mobile-space {
      height: 40px;
    }

    .hidden-space {
      display: none;
    }

    .grads-list {
      flex-direction: column;

      .grad-card {
        width: 100%;
        padding: 1% 0 5% 0;

        .grad-heading {
          font-size: 16px;
        }
      }
    }

    .grads-button-container {
      width: 100%;
      padding: 0;

      .more-button {
        width: 100%;
      }
    }
  }

  @media ${breakpointSizes.desktop} {
    padding:10px 350px
  }
  @media ${breakpointSizes.large} {
    padding:20px 650px
  }
`;

export default GradsContainer;
