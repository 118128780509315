import IndividualBootcampLayout from '../../../components/individualBootcamps'
import { SoftwareInfo } from '../../../constants'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const SoftwareCourse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  let { id } = useParams()
  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={SoftwareInfo.courseTitle}
        schedule={SoftwareInfo.schedule}
        courseRating={SoftwareInfo.courseRating}
        applicationLink={SoftwareInfo.applicationLink}
        headingDescription={SoftwareInfo.headingDescription}
        heroImage={SoftwareInfo.heroImage}
        cardTitle={SoftwareInfo.cardTitle}
        overviewHeading={SoftwareInfo.overviewHeading}
        overviewText={SoftwareInfo.overviewText}
        overviewVideo={SoftwareInfo.overviewVideo}
        syllabusText={SoftwareInfo.syllabusText}
        syllabusLessons={SoftwareInfo.syllabusLessons}
        benefits={SoftwareInfo.benefits}
        postBenefits={SoftwareInfo.postBenefits}
        eventsText={SoftwareInfo.eventsText}
        eventsList={SoftwareInfo.eventsList}
        requirementsText={SoftwareInfo.requirementsText}
        requirementsList={SoftwareInfo.requirementsList}
        tutors={SoftwareInfo.tutors}
        tutorsParagraph={SoftwareInfo.tutorsParagraph}
      />
    </>
  )
}

export default SoftwareCourse
