import IndividualBootcampLayout from "../../../components/individualBootcamps";
import { MarketingInfo } from "../../../constants";
import { useEffect } from "react";
import { useParams } from 'react-router-dom'

const MarketingCourse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let { id } = useParams()
  return (
    <>
      <IndividualBootcampLayout
        title={id}
        heroHeading={MarketingInfo.courseTitle}
        schedule={MarketingInfo.schedule}
        courseRating={MarketingInfo.courseRating}
        applicationLink={MarketingInfo.applicationLink}
        headingDescription={MarketingInfo.headingDescription}
        heroImage={MarketingInfo.heroImage}
        cardTitle={MarketingInfo.cardTitle}
        overviewHeading={MarketingInfo.overviewHeading}
        overviewText={MarketingInfo.overviewText}
        overviewVideo={MarketingInfo.overviewVideo}
        syllabusText={MarketingInfo.syllabusText}
        syllabusLessons={MarketingInfo.syllabusLessons}
        benefits={MarketingInfo.benefits}
        postBenefits={MarketingInfo.postBenefits}
        eventsText={MarketingInfo.eventsText}
        eventsList={MarketingInfo.eventsList}
        requirementsText={MarketingInfo.requirementsText}
        requirementsList={MarketingInfo.requirementsList}
        tutors={MarketingInfo.tutors}
        tutorsParagraph={MarketingInfo.tutorsParagraph}
      />
    </>
  );
};

export default MarketingCourse;
