import { http } from "../utils/http.js";

class PartnerService {
  constructor(http) {
    this.request = http;
  }

  async createAPartner(data) {
    return await this.request.post("partner", data);
  }

  async getAllPartners() {
    return await this.request.get("partner");
  }
}

export default new PartnerService(http);
