import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import {
  HeroContainer,
  Container,
  Heading,
  Paragraph,
  SpaceMedium,
  SpaceSmall,
  ButtonSpace,
  CustomButton,
  CardParagraph,
} from "../../styles/StyledComponents";
import { Modal } from "antd";
import JoinFamily from "./join-family";

import { PartnersContainer } from "./style";

import {
  HomeImages,
  partnerCards,
  workBoxes,
  offeringCards,
  PartnerBrands1,
  PartnerBrands2,
} from "../../constants";

const Partners = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOK = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  return (
    <PartnersContainer>
      <HeroContainer className="partner-hero">
        <Container className="hero-boxes">
          <Container className="hero-left">
            <Heading>
              Partner with us to <span>transform</span> the 1% into 50%
            </Heading>
            <SpaceSmall />
            <Paragraph className="hero-text">
              We host a range of fully-funded tech bootcamps for individuals
              across the globe with the aim of getting them into tech roles. We
              produce diverse talent and work to remove barriers that
              individuals face when entering the tech industry.
            </Paragraph>
            <SpaceMedium className="mobile-hidden" />
          </Container>

          <Container className="hero-right">
            <div className="hero-image"></div>
          </Container>
        </Container>
        <SpaceMedium className="mobile-space" />
      </HeroContainer>

      <Container className="case-section">
        <SpaceSmall />
        <Heading>
          The Case for more talents <br /> in <span>technology.</span>
        </Heading>
        <SpaceSmall />
        <Paragraph>
          Change has never been easy but it’s not supposed to be. Imagine what
          could <br /> happen if we doubled the representation of talents in the
          tech industry <br /> by 2025. We transform a small group of people to
          create a huge <br /> opportunity for the tech industry.
        </Paragraph>
        <ButtonSpace />

        <CustomButton width={"300px"} onClick={showModal}>
          <div className="button-arrow case-arrow">
            Download our Impact report
            <img
              src={HomeImages.whiteArrow}
              className="white-arrow"
              alt="arrow"
            />
          </div>
        </CustomButton>

        <SpaceMedium />
      </Container>

      <Container className="partner-cards">
        {partnerCards.map((item, index) => {
          const split = item.title.split(" ");
          const itemIndex = split.indexOf(item.spanWord);
          const keyWord = split[itemIndex];
          const firstHalf = split.slice(0, itemIndex).join(" ");
          const secondHalf = split.slice(itemIndex + 1, split.length).join(" ");

          return (
            <>
              <div
                className="individual-card"
                key={index}
                style={{ backgroundColor: item.background }}
              >
                <SpaceSmall />
                <div className="heading-wrapper">
                  <Heading
                    fontSize={"30px"}
                    lineHeight={"38px"}
                    color={item.font}
                    className="card-heading"
                  >
                    <p className="first-half">{firstHalf}</p>
                    <Heading
                      className="key-word"
                      color={item.background}
                      fontSize={"34px"}
                      lineHeight={"38px"}
                      textStroke={`1px ${item.font}`}
                    >
                      {keyWord}
                    </Heading>
                    <p className="second-half">{secondHalf}</p>
                  </Heading>
                </div>
                <SpaceSmall />
                <CardParagraph color={item.font}>{item.text}</CardParagraph>
                <SpaceMedium />

                {item.externalLink ? (
                  <a href={item.externalLink} target="_blank" rel="noreferrer">
                    <CustomButton
                      className="card-button"
                      background={item.buttonBg}
                    >
                      More Information
                    </CustomButton>
                  </a>
                ) : (
                  <Link to={item.buttonLink}>
                    <CustomButton
                      className="card-button"
                      background={item.buttonBg}
                    >
                      More Information
                    </CustomButton>
                  </Link>
                )}

                <SpaceSmall />
              </div>
            </>
          );
        })}
      </Container>

      <Container className="work-with">
        <SpaceMedium className="mobile-space" />
        <Heading className="work-heading">
          How We Work <span>With You?</span>
        </Heading>
        <SpaceMedium className="mobile-space" />
        <Container className="work-boxes">
          {workBoxes.map((item, index) => {
            return (
              <>
                <div className="individual-work-box" key={index}>
                  <img src={item.logo} className="work-logo" alt="logo" />
                  <Heading className="work-title">{item.title}</Heading>
                  <CardParagraph className="work-text">
                    {item.text}
                  </CardParagraph>
                </div>
              </>
            );
          })}
        </Container>

        <CustomButton className="partner-button">
          <Link to="/partners-form">Partner with us</Link>
        </CustomButton>
        <SpaceMedium />
      </Container>

      <Container className="offering-section">
        <Heading className="offering-section-title">
          Our NIYO Bootcamp Offerings
        </Heading>
        <SpaceMedium className="mobile-space" />
        <Container className="offering-cards">
          {offeringCards.map((item, index) => {
            return (
              <>
                <div className="individual-offering" key={index}>
                  <Heading className="offering-title">{item.title}</Heading>
                  <SpaceSmall />
                  {item.tag1 && (
                    <img
                      src={item.tag1}
                      className="offering-logo"
                      alt="web type"
                    />
                  )}

                  <ul>
                    {item.points1.map((item, index) => {
                      return <li>{item}</li>;
                    })}
                  </ul>

                  {item.tag2 && (
                    <img
                      src={item.tag2}
                      className="offering-logo offering-logo-2"
                      alt="web type"
                    />
                  )}

                  <ul>
                    {item.points2?.map((item, index) => {
                      return <li>{item}</li>;
                    })}
                  </ul>
                </div>
              </>
            );
          })}
        </Container>
      </Container>

      <Container className="ready-section">
        <div className="ready-left">
          <Heading color="#fff">Ready to partner with us?</Heading>
        </div>

        <div className="ready-right">
          <CustomButton>
            <Link to="/partners-form">Request a Call</Link>
          </CustomButton>
        </div>
      </Container>

      <Container className="partners-section">
        <SpaceSmall />
        <Heading className="partners-heading">Our Partners</Heading>
        <SpaceSmall />
        <Paragraph>
          Since 2020, We have partnered with these companies to <br />
          upskill and enable 560 people to break into tech.
        </Paragraph>
        <SpaceSmall />
        <div className="partners-video">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/xv4EiD9Z8Bg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <SpaceMedium className="mobile-space" />
        <Container className="logo-container">
          <Marquee
            speed="30"
            gradientWidth="0"
            pauseOnHover="true"
            className="marquee"
          >
            {PartnerBrands1.map((item, index) => {
              return (
                <div key={index}>
                  <img
                    src={item.image}
                    className="logo-individual"
                    alt="logo"
                  />
                </div>
              );
            })}
          </Marquee>

          <Marquee
            direction="right"
            speed="30"
            gradientWidth="0"
            pauseOnHover="true"
            className="marquee"
          >
            {PartnerBrands2.map((item, index) => {
              return (
                <div key={index}>
                  <img
                    src={item.image}
                    className="logo-individual"
                    alt="logo"
                  />
                </div>
              );
            })}
          </Marquee>
        </Container>
        <ButtonSpace />
        <CustomButton>
          <div className="partner-button">
            <Link to="/partners-form">
              <div className="button-arrow">
                Partner with us{" "}
                <img
                  src={HomeImages.whiteArrow}
                  className="white-arrow"
                  alt="arrow"
                />
              </div>
            </Link>
          </div>
        </CustomButton>
        <SpaceMedium />
      </Container>

      <Modal
        open={open}
        onOk={handleOK}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className="join-modal"
        // bodyStyle={{ height: "100%" }}
        footer={null}
        centered
      >
        <JoinFamily />
      </Modal>
    </PartnersContainer>
  );
};

export default Partners;
