import styled from "styled-components";
import { BootcampImages } from "../../constants";
import breakpointSizes from "../../constants/breakpointSizes";

const BootcampContainer = styled.div`
  .button-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    .white-arrow {
      width: 20px;
      margin: 0 0 0 4%;
    }
  }

  .desktop-hidden {
    display: none;
  }

  .hero-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: auto;

    .ant-modal-footer {
      display: none;
    }

    .hero-left {
      width: 52%;
      margin: 0;
      padding: 5% 0 0 0;

      .eligibility-button {
        background-color: #fbaf00;
        border-radius: 0;
        color: #fff;
        height: 56px;
        width: 204px;
        font-family: "Regular";
        font-size: "16px";
      }
    }

    .hero-right {
      width: 48%;
      margin: 0;
      padding: 0 0 5% 3%;
      display: flex;
      justify-content: center;

      .hero-image {
        background-image: url(${BootcampImages.bootcampHero});
        background-size: cover;
        width: 500px;
        height: 540px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .start-journey {
    .bootcamp-cards {
      padding: 0;

      .section-top {
        display: flex;
        justify-content: space-between;

        .top-heading {
          width: 50%;
          line-height: 42px;
        }
      }

      .card-container {
        display: flex;

        .individual-card:not(:last-child) {
          margin: 0 2% 0 0;
        }

        .individual-card {
          padding: 0;
          width: 32%;
          height: 600px;
          position: relative;
          box-shadow: 0px 30px 40px rgba(251, 175, 0, 0.04);
          .text {
            padding: 4% 6%;
          }
          .card-image {
            width: 100%;
          }

          .card-title {
            font-size: 16px;
            line-height: normal;
            margin-bottom: 15px;
          }

          .card-text {
            font-size: 14px;
            margin-bottom: 15px;
          }

          .card-rating {
            height: 20px;
            width: 150px;
            margin-bottom: 15px;
          }

          .card-button {
            position: absolute;
            bottom: 50px;
          }
        }
      }
    }

    .web-2-section {
      span,
      .preheading {
        color: #e09d00;
      }
    }

    .web-3-section {
      span,
      .preheading {
        color: #070909;
      }
    }

    .hair-beauty-section {
      span,
      .preheading {
        color: #ff47ce;
      }
    }

    .foundhers-section {
      span,
      .preheading {
        color: #a37200;
      }
    }
  }

  .fashion-container {
    display: flex;

    .fashion-left {
      padding: 0;
      width: 60%;

      .heading-tag-section {
        display: flex;
        align-items: center;
        padding: 0 0 2% 0;

        .heading-tag {
          background-image: url(${BootcampImages.soon});
          background-size: cover;
          width: 100px;
          height: 35px;
          margin: 0 0 0 2%;
        }
      }

      .fashion-heading {
        font-size: 33px;
        line-height: 40px;
      }
    }

    .fashion-right {
      padding: 0;
      width: 40%;

      .fashion-button {
        background-color: #fffcf4;
        color: #070909;
        font-size: 22px;
        font-family: Light;
        text-decoration: 1px black underline;
        text-underline-position: under;
        height: 40px;
        width: 230px;
        padding: 0;
        margin: 0;
      }
    }
  }

  .faq-section {
  }

  @media ${breakpointSizes.mobile} {
    .antd-modal-content {
      height: auto;
    }

    .desktop-hidden {
      display: block;
    }

    .mobile-display {
      display: block;
    }

    .mobile-hidden {
      display: none;
    }

    .hero-container {
      flex-direction: column;
      padding: 100px 20px 20px 20px;

      .hero-left {
        width: 100%;

        .hero-button {
          width: 100%;
        }
      }

      .hero-right {
        width: 100%;
        padding: 0 0 20% 0;

        .hero-image {
          width: 100%;
          height: 380px;
          padding: 10% 0;
          border-radius: 100px 0px 100px;
        }
      }
    }

    .start-journey {
      .web-2-section,
      .web-3-section,
      .hair-beauty-section,
      .foundhers-section {
        padding: 14% 0 0 0;
      }

      .desktop-hidden {
        display: none;
      }

      .bootcamp-cards {
        .preheading {
          padding: 0 0 10% 0;
        }

        .section-top {
          width: 100%;
          justify-content: start;
          padding: 0 0 10% 0;

          .top-heading {
            width: 70%;
            font-size: 22px;
            line-height: 26px;
          }

          .top-button {
            width: 100px;
            background-color: #fffcf4;
            color: #070909;
            font-family: Medium;
          }
        }

        .card-container {
          display: none;
        }

        .mobile-swipe-container {
          display: block;
          height: 600px;
          width: 100%;

          .individual-card {
            padding: 0;
            width: 100%;
            height: auto;
            min-height: 550px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .card-image {
              width: 100%;
            }

            .card-title {
              font-size: 16px;
              line-height: normal;
              padding: 2% 4%;
            }

            .card-text {
              font-size: 14px;
              padding: 0 4% 4% 4%;
            }

            .card-rating {
              height: 20px;
              width: 150px;
              padding: 0 4%;
            }

            .card-button {
              margin: 0 4% 4% 4%;
            }
          }
        }
      }
    }

    .fashion-container {
      flex-direction: column;

      .fashion-left {
        width: 100%;
        padding: 0 0 10% 0;

        .heading-tag-section {
          padding: 0 0 4% 0;

          .heading-tag {
            width: 120px;
            height: 40px;
            margin: 0;
          }
        }
      }

      .fashion-right {
        width: 100%;

        .fashion-button {
          text-decoration: none;
          padding: 0;
          margin: 0;
          width: 216px;
        }
      }
    }

    .upskilling-container {
      flex-direction: column;

      .upskilling-left {
        width: 100%;
        padding: 0 0 10% 0;
      }

      .upskilling-right {
        width: 100%;
      }
    }
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export default BootcampContainer;
