import RegistrationContainer from "./style";
import { Accordion, AccordionItem } from "react-accessible-accordion";
import { useEffect, useState } from "react";
import {
  Container,
  Heading,
  SpaceLarge,
  SpaceMedium,
  SpaceSmall,
  Paragraph,
  Span,
  HeroContainer,
  CustomDiv,
} from "../../styles/StyledComponents";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdditionalInformation from "./personalInformation";
import { useParams } from "react-router-dom";
import FurtherInformation from "./furtherInformation";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registration } from "../../api/registration";

const validationSchema = yup.object({
  // eligibilityCode: yup.string().required('eligibilityCode is required'),
  // childCareAssistance: yup.string().required("childCareAssistance is required"),
  schoolCert: yup.string().required("schoolCert is required"),
  ageGroup: yup.string().required("ageGroup is required"),
  ownLaptop: yup.string().required("ownLaptop is required"),
  // pastBootcamp: yup.string().required("pastBootcamp is required"),
  prevExperience: yup.string().required("prevExperience is required"),
  qualitativeInterviews: yup
    .string()
    .required("qualitativeInterviews is required"),
  reasonForApplying: yup.string().required("reasonForApplying is required"),
  achievingGoalsWithBootcamp: yup
    .string()
    .required("achievingGoalsWithBootcamp is required"),
  readDocuments: yup
    .boolean()
    .oneOf([true], "checkbox is required")
    .required("readDocuments is required"),
  addToMailingList: yup
    .boolean()
    .oneOf([true], "checkbox is required")
    .required("addToMailingList is required"),
  preferredAssessmentCenterDay: yup
    .string()
    .required("preferredAssessmentCenterDay is required"),
  sendCopyResponse: yup
    .boolean()
    .oneOf([true], "checkbox is required")
    .required("sendCopyResponse is required"),
  dataWindow: yup.string().required("dataWindow is required"),
});

const RegistrationForm = () => {
  const { loading, disable, regSuccess, regError } = useSelector(
    (state) => state.registration
  );

  const { data} = useSelector(
    (state) => state.eligibility
  );

  const fieldData = data?.data?.data?.eligibilityData || null

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (regError) {
      if (regError === "Already completed registration") {
        toast.error("Registration Has Been Completed Already", {
          position: toast.POSITION.TOP_CENTER,
        });
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (regError === "Invalid bootcamp selected") {
        toast.error(
          "Invalid bootcamp selected, please select available bootcamps",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (regError === "eligibility data do not exist") {
        toast.error(
          regError,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (regError === "Please upload work eligibility proof file") {
        toast.error(
          regError,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (regError === "Unexpected field") {
        toast.error(
          regError,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      
      const errorMessage = regError?.map((data) => data.name);
      if (errorMessage?.includes("Invalid bootcamp selected")) {
        toast.error(
          "Invalid bootcamp selected, please select available bootcamps",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("resumeFile")) {
        toast.error("Error on resume File field, please recheck field", {
          position: toast.POSITION.TOP_CENTER,
        });
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("addToMailingList")) {
        toast.error(
          "Error on Mailing List field, please check field and check the box",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("readDocuments")) {
        toast.error(
          "Error on Documents field, please confirm you have read and check document field boxes",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("sendCopyResponse")) {
        toast.error(
          "Error on Email Me a Copy of My Response field, please make sure the box is checked",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("eligibilityCode")) {
        toast.error("Error on eligibility Code field, please recheck field", {
          position: toast.POSITION.TOP_CENTER,
        });
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("ageGroup")) {
        toast.error("Error on age Group field, please recheck field", {
          position: toast.POSITION.TOP_CENTER,
        });
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("childCare[isRequiredChildCare]")) {
        toast.error(
          "Error on child Care field, please recheck all childcare fields carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("disability[isDisable]")) {
        toast.error(
          "Error on disability field, please recheck all disabilty fields carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("universityBenefit[isBenefit]")) {
        toast.error(
          "Error on university Benefit field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("schoolCert")) {
        toast.error(
          "Error on school Cert field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("prevExperience")) {
        toast.error(
          "Error on previous Experience field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("ownLaptop")) {
        toast.error(
          "Error on do you own a laptop field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("qualitativeInterviews")) {
        toast.error(
          "Error on qualitative Interviews field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("reasonForApplying")) {
        toast.error(
          "Error on reason for applying field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("achievingGoalsWithBootcamp")) {
        toast.error(
          "Error on achieving Goals With Bootcamp field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("whereDidYouHearAbtBootCamp")) {
        toast.error(
          "Error on where did you hear about our BootCamp field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("dataWindow")) {
        toast.error(
          "Error on data Window field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("interestInTechCareer")) {
        toast.error(
          "Error on interest In TechCareer field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("preferredAssessmentCenterDay")) {
        toast.error(
          "Error on preferred Assessment Center Day field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("studyLevel")) {
        toast.error(
          "Error on study Level field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("studySubject")) {
        toast.error(
          "Error on study Subject field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("previousHairstylingUpskill")) {
        toast.error(
          "Error on previous Hairstyling Upskill field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("bootcampAndPersonalGoalAlignment")) {
        toast.error(
          "Error on bootcamp And Personal Goal Alignment field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("interestInBootcamp")) {
        toast.error(
          "Error on interest In Bootcamp field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[roleTitle]")) {
        toast.error(
          "Error on foundhers role Title field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[teamMembers]")) {
        toast.error(
          "Error on foundhers teamMembers field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[numberOfFounder]")) {
        toast.error(
          "Error on foundhers number Of Founder field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (
        errorMessage?.includes(
          "foundhersDetails[bootcampParticipatoryTeamMembers]"
        )
      ) {
        toast.error(
          "Error on foundhers bootcamp Participatory TeamMembers field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (
        errorMessage?.includes("foundhersDetails[registeredCompanyOrBusiness]")
      ) {
        toast.error(
          "Error on foundhers bootcamp registered Company Or Business field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (
        errorMessage?.includes("foundhersDetails[companyRegistrationNumber]")
      ) {
        toast.error(
          "Error on foundhers bootcamp company Registration Number field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[businessStructure]")) {
        toast.error(
          "Error on foundhers business Structure field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[fundingReceived]")) {
        toast.error(
          "Error on foundhers funding Received field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[companyName]")) {
        toast.error(
          "Error on foundhers company Name field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (
        errorMessage?.includes(
          "foundhersDetails[businessOrCompanySectorOrIndustry]"
        )
      ) {
        toast.error(
          "Error on foundhers business Or Company Sector Or Industry field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[companyWebsite]")) {
        toast.error(
          "Error on foundhers company Website field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (
        errorMessage?.includes("foundhersDetails[companySocialMediaHandles]")
      ) {
        toast.error(
          "Error on foundhers companySocialMediaHandles field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[businessLaunchDate]")) {
        toast.error(
          "Error on foundhers business Launch Date field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage?.includes("foundhersDetails[useOfTechnology]")) {
        toast.error(
          "Error on foundhers use Of Technology field, please recheck field carefully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
      if (errorMessage || regError) {
        toast.error(
          "Something went wrong, please carefully make sure all fields are correctly filled and try again",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }
    }
  }, [regError]);

  let { id } = useParams();
  const bootcamp = id;

  const [regData, setRegData] = useState({ bootcampTitle: id });
  const [eligibility, setEligibility] = useState(true);

  // const [medicFile, setMedicFile] = useState(null);
  // const [deskFile, setDeskFile] = useState(null);
  // const [medicFile1, setMedicFile1] = useState(null);
  // const [deskFile1, setDeskFile1] = useState(null);
  // const [pitchFile, setPitchFile] = useState(null);
  // const [pitchFile1, setPitchFile1] = useState(null);

  const [techInterest, setTechInterest] = useState([]);
  const [stage, setStage] = useState("personal-information");


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  

  const onSubmit = (values) => {
    const {
      schoolCert,
      ageGroup,
      ownLaptop,
      // pastBootcamp,
      ninNo,
      prevExperience,
      currentSalary,
      qualitativeInterviews,
      hoursPerWeek,
      continueWorking,
      industry,
      jobTitle,
      currentSalaryType,
      reasonForApplying,
      achievingGoalsWithBootcamp,
      readDocuments,
      addToMailingList,
      preferredAssessmentCenterDay,
      sendCopyResponse,
      dataWindow,
    } = values

    
    const eligbilityCode = fieldData?.regCode || regData.eligibilityCode || null
    
    let interestInTechCareer = techInterest?.join(',')
    let formData = new FormData()
    
    formData.append('eligibilityCode', eligbilityCode || 'N/A')
    formData.append('ninNo', ninNo || 'N/A')
    
    formData.append('resumeFile', regData?.resumeFile || 'N/A')
    formData.append('ageGroup', ageGroup || 'N/A')
    formData.append(
      'workEligibilityProof',
      regData?.workEligibilityProof || 'No',
    )
    formData.append(
      'childCare[isRequiredChildCare]',
      regData?.childCareAssistance || 'No',
    )
    formData.append(
      'childCare[childCareResponsibility]',
      regData?.childCareAssistance1 || 'N/A',
    )
    formData.append(
      'childCare[childCareAssistance]',
      regData?.childCareAssistance2 || 'N/A',
    )
    formData.append('disability[isDisable]', regData?.disability || 'No')
    formData.append(
      'disability[selectSpecificDisability]',
      regData?.specificDisability || 'N/A',
    )
    formData.append(
      'disability[otherSpecificDisability]',
      regData?.specificDisability1 || 'N/A',
    )
    formData.append(
      'disability[requireSupport]',
      regData?.disabilitySupport || 'N/A',
    )
    formData.append(
      'disability[selectSpecificSupport]',
      regData?.disabilitySupport1 || 'N/A',
    )
    if (regData?.disability === 'Yes') {
      formData.append('medicalDiagnosisFile', regData?.medicalDiagnosisFile)
    }
    formData.append(
      'universityBenefit[isBenefit]',
      regData?.universalCredit || 'No',
    )
    formData.append(
      'universityBenefit[selectSpecificBenefit]',
      regData?.universalCredit1 || 'N/A',
    )
    formData.append(
      'universityBenefit[otherSpecificBenefit]',
      regData?.otherUniversalCredit1 || 'N/A',
    )
    formData.append('schoolCert', schoolCert || 'N/A')
    formData.append('prevExperience', prevExperience || 'N/A')
    formData.append('ownLaptop', ownLaptop || 'No')

    formData.append(
      'employmentDetails[employmentStatus]',
      regData?.employmentStatus || 'N/A',
    )

    formData.append(
      'employmentDetails[employerName]',
      regData?.employerName || 'N/A',
    )

    formData.append(
      'employmentDetails[employerPostcode]',
      regData?.employerPostcode || 'N/A',
    )
    formData.append(
      'employmentDetails[throughEmployer]',
      regData?.throughEmployer || 'N/A',
    )

    if (bootcamp === 'Tech Bootcamp for Black Female Foundhers' && bootcamp === 'Marketing for Digital Entrepreneurship') {
      formData.append(
        'foundhersDetails[roleTitle]',
        regData?.roleTitle || 'N/A',
      )
      formData.append(
        'foundhersDetails[selfSpecifiedRole]',
        regData?.roleTitle1 || 'N/A',
      )
      formData.append(
        'foundhersDetails[numberOfFounder]',
        regData?.noFoundher || 'N/A',
      )
      formData.append(
        'foundhersDetails[teamMembers]',
        regData?.teamMembers || 'N/A',
      )
      formData.append(
        'foundhersDetails[bootcampParticipatoryTeamMembers]',
        regData?.teamMembersParticaption || 'N/A',
      )
      formData.append(
        'foundhersDetails[registeredCompanyOrBusiness]',
        regData?.busReg || 'N/A',
      )
      formData.append(
        'foundhersDetails[companyRegistrationNumber]',
        regData?.busReg1 || 'N/A',
      )
      formData.append(
        'foundhersDetails[businessStructure]',
        regData?.businessStructure || 'N/A',
      )
      formData.append(
        'foundhersDetails[specifyBusinessStructure]',
        regData?.businessStructure1 || 'N/A',
      )
      formData.append(
        'foundhersDetails[fundingReceived]',
        regData?.fundingReceived || 'N/A',
      )
      formData.append(
        'foundhersDetails[companyName]',
        regData?.companyName || 'N/A',
      )
      formData.append(
        'foundhersDetails[businessOrCompanySectorOrIndustry]',
        regData?.businessSector || 'N/A',
      )
      formData.append(
        'foundhersDetails[companyWebsite]',
        regData?.companyWebsite || 'N/A',
      )
      formData.append(
        'foundhersDetails[companySocialMediaHandles]',
        regData?.socialHandles || 'N/A',
      )
      formData.append(
        'foundhersDetails[businessOrCompanyLocation]',
        regData?.companyLocation || 'N/A',
      )
      formData.append(
        'foundhersDetails[businessLaunchDate]',
        regData?.businessLaunch || 'N/A',
      )
      formData.append(
        'foundhersDetails[currentBusinessStage]',
        regData?.businessStage || 'N/A',
      )
      formData.append(
        'foundhersDetails[useOfTechnology]',
        regData?.technologyUse || 'N/A',
      )
      formData.append(
        'foundhersDetails[businessPitchVideoUrl]',
        regData?.businessPitchVideoUrl || 'N/A',
      )
      formData.append('businessPitchDeck', regData?.businessPitchDeck || 'N/A')
    }

    
    formData.append('hoursPerWeek', hoursPerWeek || 'N/A')
    formData.append('continueWorking', continueWorking || 'N/A')

    
    formData.append('jobTitle', jobTitle || 'N/A')
    formData.append('industry', industry || 'N/A')

    
    
    formData.append('qualitativeInterviews', qualitativeInterviews || 'N/A')
    formData.append('currentSalaryType', currentSalaryType || 'N/A')

    
    formData.append('currentSalary', currentSalary || 'N/A')

    
    formData.append('reasonForApplying', reasonForApplying || 'N/A')
    formData.append(
      'achievingGoalsWithBootcamp',
      achievingGoalsWithBootcamp || 'N/A',
    )
    formData.append('readDocuments', readDocuments || 'N/A')
    if (bootcamp === 'Afro Hair with Digital Entrepreneurship') {
      formData.append(
        'hairBossDetails[previousHairstylingUpskill]',
        regData?.previousHairstylingUpskill || 'N/A',
      )
      formData.append(
        'hairBossDetails[bootcampAndPersonalGoalAlignment]',
        regData?.bootcampAndPersonalGoalAlignment || 'N/A',
      )
      formData.append(
        'hairBossDetails[interestInBootcamp]',
        regData?.interestInBootcamp || 'N/A',
      )
    }
    formData.append('addToMailingList', addToMailingList || 'N/A')
    formData.append(
      'whereDidYouHearAbtBootCamp',
      regData?.whereDidYouHearAbtBootCamp || 'N/A',
    )
    formData.append(
      'whereDidYouHearAbtBootCampOthers',
      regData?.whereDidYouHearAbtBootCampOthers || 'N/A',
    )
    formData.append('dataWindow', dataWindow || 'N/A')
    formData.append('sendCopyResponse', sendCopyResponse || 'N/A')
    // formData.append('childCare', childCare)
    formData.append(
      'preferredAssessmentCenterDay',
      preferredAssessmentCenterDay || 'N/A',
    )
    formData.append('studyLevel', regData?.studyLevel || 'N/A')
    formData.append('studySubject', regData?.studySubject || 'N/A')
    // formData.append('healthConditions', regData?.healthConditions)
    formData.append('bootcampTitle', regData?.bootcampTitle || 'N/A')
    formData.append('interestInTechCareer', interestInTechCareer || 'N/A')
    dispatch(registration({eligbilityCode, formData}))
  }

  const formik = useFormik({
    initialValues: {
      ageGroup: "",
      ninNo: "",
      childCareAssistance : "",
      throughEmployer: "",
      employerPostcode: "",
      employerName: "",
      employmentStatus: "",
      // employmentStatus1: "",
      schoolCert: "",
      ownLaptop: "",
      // pastBootcamp: "",
      prevExperience: "",
      qualitativeInterviews: "",
      jobTitle : "",
      hoursPerWeek: "",
      industry: "",
      continueWorking : "",
      currentSalaryType : "",
      currentSalary : "",
      reasonForApplying: "",
      achievingGoalsWithBootcamp: "",
      readDocuments: "",
      addToMailingList: "",
      preferredAssessmentCenterDay: "",
      sendCopyResponse: "",
      dataWindow: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });



  const { handleChange, handleSubmit, values, touched, errors } = formik;

  
  return (
    <>
      <RegistrationContainer>
        <HeroContainer className="form-background">
          <ToastContainer />
          {!regSuccess && (
            <>
              <Container className="heading-section">
                <Heading>Start your application</Heading>
                <SpaceSmall />
                <Paragraph className="heading-paragraph">
                  Welcome back! Ready to take the next step to complete your
                  application? fill out the form below, to confirm your
                  eligibility and know about admission process for{" "}
                  <Span style={{ fontFamily: "Medium" }}>{id}</Span>
                </Paragraph>

                <SpaceSmall className="desktop-hidden" />
              </Container>

              <SpaceMedium />

              <Accordion
                className="accordion-container"
                preExpanded={[`${stage}`]}
              >
                <AccordionItem uuid={stage}>
                  <form>
                    <AdditionalInformation
                      setRegData={setRegData}
                      fieldData={fieldData}
                      regData={regData}
                      bootcamp={id}
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      // setPitchFile1={setPitchFile1}
                      // setPitchFile={setPitchFile}
                      // setDeskFile1={setDeskFile1}
                      // setDeskFile={setDeskFile}
                      // setMedicFile={setMedicFile}
                      // setMedicFile1={setMedicFile1}
                    />

                    <FurtherInformation
                      fieldData={fieldData}
                      setRegData={setRegData}
                      regData={regData}
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      regError={regError}
                      touched={touched}
                      setEligibility={setEligibility}
                      bootcamp={id}
                      techInterest={techInterest}
                      setTechInterest={setTechInterest}
                      handleSubmit={handleSubmit}
                      disable={disable}
                      loading={loading}
                      // medicFile={medicFile}
                      // medicFile1={medicFile1}
                      // deskFile={deskFile}
                      // deskFile1={deskFile1}
                      // pitchFile={pitchFile}
                      // pitchFile1={pitchFile1}
                    />
                  </form>
                </AccordionItem>
              </Accordion>
            </>
          )}

          {regSuccess && (
            <>
              <Container className="heading-section">
                <Heading>Registration successful</Heading>
                <SpaceSmall />
                <Paragraph className="heading-paragraph">
                  You have succesfully applied for this bootcamp. Thanks
                </Paragraph>
                <CustomDiv
                  className="navHomeBtn"
                  background="#fff"
                  height="100%"
                  color="#636969"
                  onClick={() => navigate("/")}
                >
                  <div className="navHome">Go to Homepage</div>
                </CustomDiv>
                <SpaceSmall className="desktop-hidden" />
              </Container>
              <SpaceMedium />
            </>
          )}

          <SpaceLarge />
        </HeroContainer>
      </RegistrationContainer>
    </>
  );
};

export default RegistrationForm;

