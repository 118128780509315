import { UploadButtons } from "../../../constants";
import {
  Container,
  HeroContainer,
  Heading,
  CardParagraph,
  CustomButton,
  InputField,
  SpaceSmall,
  ButtonSpace,
  SpaceLarge,
  TextareaField,
} from "../../../styles/StyledComponents";
import { DetailsContainer } from "./style";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Details = ({ chosenPackage }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <DetailsContainer>
      <HeroContainer className="hero-container">
        <Container className="hero-left">
          <Heading fontSize="24px" lineHeight="26px" className="padding">
            Order Summary - £{chosenPackage.price}
          </Heading>

          <CardParagraph fontSize="18px" className="padding">
            Premium Package {chosenPackage.number}
          </CardParagraph>

          <div className="individual-package">
            <CardParagraph fontSize="18px">What you'll get</CardParagraph>

            <ul>
              {chosenPackage.list.map((listItem, index) => {
                return <li key={index}>{listItem}</li>;
              })}
            </ul>
          </div>
        </Container>

        <Container className="hero-right">
          <Heading fontSize="24px" lineHeight="26px" className="padding">
            Fill Out The Below Requirements for Your Order
          </Heading>
          <CardParagraph>
            Provide us with information about your company{" "}
          </CardParagraph>
          <SpaceSmall />

          <div className="form-line">
            <label>
              Company Name
              <InputField
                id="company-name"
                placeholder="Niyo Group..."
              ></InputField>
            </label>
          </div>

          <div className="form-line">
            <label>
              Job Type
              <InputField
                id="job-type"
                placeholder="Full Time, Part time..."
              ></InputField>
            </label>
          </div>

          <div className="form-line">
            <label>
              Job Role
              <InputField
                id="job-role"
                placeholder="Software Engineer..."
              ></InputField>
            </label>
          </div>

          <div className="form-line">
            <label>
              Job Location
              <InputField
                id="job-location"
                placeholder="Remote, Birmingham UK..."
              ></InputField>
            </label>
          </div>
          <div className="form-line">
            <label>
              Job Description
              <TextareaField
                id="job-description"
                placeholder="Please enter the details of your job, including the following information:&#10;Recruitment Process&#10;Equal Opportunities  Policy &#10;Diversity Policy"
              ></TextareaField>
            </label>
          </div>

          <div className="form-line form-button-row">
            <label>
              Please attach your company's logo
              <div className="form-button-row">
                <CustomButton
                  id="company-logo"
                  className="upload-button"
                  background="#fff"
                  color="#636969"
                >
                  <img
                    src={UploadButtons[0]}
                    className="button-icon"
                    alt="button icon"
                  />
                  Attach File
                </CustomButton>
                <CardParagraph className="upload-paragraph">or</CardParagraph>

                <CustomButton
                  id="upload-button"
                  className="upload-button"
                  background="#fff"
                  color="#636969"
                >
                  <img
                    src={UploadButtons[1]}
                    className="button-icon"
                    alt="button-icon"
                  />
                  Drop file here
                </CustomButton>
              </div>
            </label>
          </div>

          <div className="form-line">
            <label>
              Website Link / Email
              <InputField
                id="website-link"
                placeholder="Your company's website link"
              ></InputField>
            </label>
            <CardParagraph>
              Add a link that direct intending employee to your job website
            </CardParagraph>
          </div>

          <ButtonSpace />
          <CustomButton width="100%">
            <Link to="/jobs/payment">Proceed to payment</Link>
          </CustomButton>
          <SpaceLarge />
        </Container>
      </HeroContainer>
    </DetailsContainer>
  );
};

export default Details;
