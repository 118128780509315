import Axios from "axios";
// create an new axios http instance
const http = Axios.create({
  baseURL: "https://niyobootcamp-wtushxuzaa-lm.a.run.app/api/v1/", //TODO:// use environment variables instead....
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

http.defaults.headers.common.accept = "application/json";

// before a request is made start  anything
http.interceptors.request.use((config) => {
  return config;
});

// before a response is returned do anything
http.interceptors.response.use((response) => {
  return response;
});

const base_url = http.defaults.baseURL;

export { http, base_url };
  
  
const https = Axios.create({
  baseURL: "https://niyogroupapi-wtushxuzaa-lm.a.run.app/api/v1/", //TODO:// use environment variables instead....
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

https.defaults.headers.common.accept = "application/json";

// before a request is made start  anything
https.interceptors.request.use((config) => {
  return config;
});

// before a response is returned do anything
https.interceptors.response.use((response) => {
  return response;
});

const base_urls = https.defaults.baseURL;

export { https, base_urls };
