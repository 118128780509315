import FashionContainer from "./style";
import {
  HeroContainer,
  PreHeading,
  SpaceSmall,
  Heading,
  Paragraph,
  Container,
  CardParagraph,
  CustomButton,
} from "../../../styles/StyledComponents";
import {
  FashionBootcampsIndividual,
  FashionBootcampsComingSoon
} from "../../../constants";
import Upskilling from "../../../components/Upskilling";
import OurGrads from "../../../components/Grads";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const FashionPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <FashionContainer>
        <HeroContainer height={"auto"} className="hero-container">
          <div className="hero-left">
            <PreHeading>FASHION BOOTCAMPS</PreHeading>
            <SpaceSmall />
            <Heading>
              <span>Explore</span> our Bootcamps to see the various courses.
            </Heading>
            <SpaceSmall />
            <Paragraph>
              Are you looking for a pathway into having a career in tech, or a
              high impact industry? Learn with a growing community of 
              women in tech, passionate about changing the narrative too!
            </Paragraph>
          </div>
          <div className="hero-right">
            <div className="hero-image"></div>
          </div>
        </HeroContainer>

        <Container className="cards-section">
          <PreHeading>FASHION BOOTCAMPS</PreHeading>
          <SpaceSmall />
          <Heading>
            Start your journey in any of our <span>Fashion Bootcamps</span>
          </Heading>
          <SpaceSmall />
          <div className="card-container">
            {FashionBootcampsIndividual.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <Heading className="card-title" color={item.color}>
                    {item.title}
                  </Heading>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.description}
                  </CardParagraph>
                  <img src={item.rating} className="card-rating" alt="rating" />
                  <CardParagraph className="card-text" color={item.color}>
                    {item.schedule}
                  </CardParagraph>
                  <CustomButton
                    className="card-button"
                    border={"2px solid #E09D00"}
                    background={item.background}
                    color="#E09D00"
                  >
                    <Link to={item.path}>View Bootcamp</Link>{" "}
                  </CustomButton>
                  <SpaceSmall />
                </Container>
              );
            })}
          </div>

          <Heading className="coming-soon">Coming Soon</Heading>

          <div className="card-container ">
            {FashionBootcampsComingSoon.map((item, index) => {
              return (
                <Container
                  key={index}
                  className="individual-card coming-soon-cards"
                  style={{ backgroundColor: item.background }}
                >
                  <img
                    src={item.image}
                    className="card-image"
                    alt="background"
                  />
                  <Heading className="card-title" color={item.color}>
                    {item.title}
                  </Heading>
                  <CardParagraph className="card-text" color={item.color}>
                    {item.description}
                  </CardParagraph>
                  <img src={item.rating} className="card-rating" alt="rating" />
                  <CardParagraph className="card-text" color={item.color}>
                    {item.schedule}
                  </CardParagraph>
                  <CustomButton
                    className="card-button"
                    border={"2px solid #E09D00"}
                    background={item.background}
                    color="#E09D00"
                  >
                    <Link to={item.path}>{item.buttonInfo}</Link>{" "}
                  </CustomButton>
                  <SpaceSmall />
                </Container>
              );
            })}
          </div>

          <div className="mobile-swipe-container desktop-hidden">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {FashionBootcampsIndividual.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        />
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        <CustomButton
                          className="card-button"
                          border={"2px solid #E09D00"}
                          background={item.background}
                          color="#E09D00"
                        >
                          <Link to={item.path}>View Bootcamp</Link>{" "}
                        </CustomButton>
                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>

          <PreHeading className="desktop-hidden">COMING SOON</PreHeading>
          <div className="mobile-swipe-container desktop-hidden">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {FashionBootcampsComingSoon.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <Container
                        key={index}
                        className="individual-card"
                        style={{ backgroundColor: item.background }}
                      >
                        <img
                          src={item.image}
                          className="card-image"
                          alt="background"
                        />
                        <Heading className="card-title" color={item.color}>
                          {item.title}
                        </Heading>
                        <CardParagraph className="card-text" color={item.color}>
                          {item.description}
                        </CardParagraph>
                        <img
                          src={item.rating}
                          className="card-rating"
                          alt="rating"
                        />
                        <CardParagraph className="card-text" color={item.color}>
                          {item.schedule}
                        </CardParagraph>
                        <CustomButton
                          className="card-button"
                          border={"2px solid #E09D00"}
                          background={item.background}
                          color="#E09D00"
                        >
                          <Link to={item.path}>{item.buttonInfo}</Link>{" "}
                        </CustomButton>
                        <SpaceSmall />
                      </Container>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </Container>

        <Upskilling />

        <OurGrads />

        {/* <FAQS FAQList={BootcampFAQS} /> */}
      </FashionContainer>
    </>
  );
};

export default FashionPage;
