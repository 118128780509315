
import { createSlice } from '@reduxjs/toolkit'
import { createEligibleCode } from '../../api/eligibility'

const initialState = {
  loading: false,
  data: null,
  disable: false,
  success: false,
}

const eligibilitySlice = createSlice({
  name: 'eligibility',
  initialState,
  reducers: {
  },
  extraReducers: {
    [createEligibleCode.pending]: (state) => {
      state.loading = true
      state.disable = true
    },
      [createEligibleCode.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.success = true
      state.disable = false
    },
    [createEligibleCode.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.success = false
      state.disable = false
    },
  },
})

export default eligibilitySlice.reducer
