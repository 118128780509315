import {
  PreHeading,
  SpaceSmall,
  Heading,
  Container,
  SpaceMedium,
} from "../../styles/StyledComponents";

import { Collapse } from "antd";
const { Panel } = Collapse;

const FAQs = () => {
  const onChange = (key) => {
  };
  return (
    <>
      <Container>
        <PreHeading>SUPPORT</PreHeading>

        <Heading fontSize="40px">FAQs</Heading>

        <Collapse defaultActiveKey={["1"]} onChange={onChange}>
          <Panel
            header="I was part of a Niyo Bootcamp previously, can I also apply to a different one?"
            key="1"
          >
            <p>
              This is relative, we do not have a generic response because it
              could equate to duplication in funding for our publically funded
              bootcamps but not for bootcamps funded by our Employer Partners.
              Please email us at{" "}
              <a href="mailto:bootcamps.niyo.co">bootcamps@niyo.co</a> for a
              more specific response.
            </p>
          </Panel>

          <Panel
            header="I have lived in the UK for up to three years but I do not have access to public funds, am I eligible?"
            key="2"
          >
            <p>
            Yes, if you have lived in the UK for more than 3 years, you are eligible.
            </p>
          </Panel>
          <Panel
            header="I clicked to apply, but it says you are no longer accepting applications: "
            key="3"
          >
            <p>
              The reason would be that we are not recruiting at the time of your
              application. Kindly fill out our{" "}
              <a href="https://airtable.com/shrw6ZbgonNTa6POH">Interest Form</a>{" "}
              and we will notify you when we resume another round of
              recruitment. You can also follow us on our socials (LinkedIn,
              Instagram, and Twitter @Niyobootcamps) and subscribe to our
              newsletter HERE
            </p>
          </Panel>
          <Panel
            header="Are Niyo Bootcamps open in London or other locations - if not open yet"
            key="4"
          >
            <p>
              Please check each bootcamp description for the region it is being
              delivered within OR Please subscribe to our newsletter HERE and
              register your interest HERE to be the first to know when​ this
              happens.​ ​Be sure to follow us on LinkedIn and Instagram as well.
            </p>
          </Panel>
          <Panel header="Enquiry about relocating for bootcamp" key="5">
            <p>
              If you are looking to relocate for the duration of the boot camp,
              please note that you would need to show proof that you have done
              this by the bootcamp onboarding date. If you are selected to join
              the bootcamp or show that you will be willing to focus your job
              search on the region the bootcamp is being delivered within (e.g
              West Midlands), you must be willing to move to the region for a
              job role
            </p>
          </Panel>
          <Panel
            header="Are there Tuition fees for learning on a Niyo Bootcamp?"
            key="6"
          >
            <p>
              Our courses are fully funded and you will not be expected to pay a
              tuition fee if your application is successful. You can subscribe
              to our newsletter HERE and register your interest{" "}
              <a href="https://airtable.com/shrw6ZbgonNTa6POH">HERE</a> to be
              the first to know when we open applications for our next cohort.
            </p>
          </Panel>
          <Panel
            header="Hosting boot camps outside the UK/accepting applications outside UK"
            key="7"
          >
            <p>
              We don't accept applications outside the UK at this time. We are
              looking to expand our bootcamp delivery. You can subscribe to our
              newsletter HERE for announcements about bootcamps or{" "}
              <a href="https://chat.whatsapp.com/BikOghd2Wi490bHs3J8ENq">
                Join our WhatsApp community.
              </a>
              If you would like to partner in delivering bootcamps outside of
              the UK please contact{" "}
              <a mailto="partnerships@niyo.co">partnerships@niyo.co</a>
              Be sure to follow us on <b>Linkedin</b> and <b>Instagram</b> for
              any updates on future programmes.{" "}
            </p>
          </Panel>
          <Panel header="US Affiliates for Software Engineering" key="8">
            <p>
              At the moment we don't have a US Affiliate and our bootcamps
              (currently all fully funded) aren't open to candidates outside the
              UK. But, if you sign up to our newsletter HERE, register your
              interest HERE, and follow us on Linkedin and Instagram you will be
              the first to know any updates on future programmes. <br /> <br /> 
              
              Alternatively,
              you can look into similar programmes in the USA such as <a href="https://adadevelopersacademy.org/">Ada
              Developers Academy</a>. You can also see more programmes in the USA <a href="https://learntocodewith.me/posts/13-places-women-learn-code/">HERE</a>. <br /> <br />
              
              If you are based in the UK, then you may be eligible for our
              programmes depending on the scope of the funding in place. <br />
              
              If you
              are based in the UK but do not meet the eligibility requirements
              of our current opportunities, you can consider similar
              opportunities at other organisations: <br /><br />
              
              <li><a href="https://www.codeyourfuture.io/">CodeYourFuture</a></li>
              <li><a href="https://www.schoolofcode.co.uk/">School of Code</a></li>
              <li><a href="https://codefirstgirls.com/courses/nanodegree/">CodeFirstGirls</a></li>
              <li>Self-learning options such as<a href="https://firebrand.training/uk/courses?_gl=1*i9b1sp*_up*MQ..&gclid=CjwKCAjwlcaRBhBYEiwAK341jc2mhCHHvbPdcLqajPgmKGrUbEULC2fGbSov3F37xVUAOyv1ACXPbRoCboUQAvD_BwE">FireBrand training.</a></li>
              <br />
             For organisations for  women, you can check out
              <a href="https://codingblackfemales.com/" >Coding Black Females </a> (one of the organisations behind Black
              Codher) and <a href="https://www.blackgirlsintech.org/">Black Girls in Tech</a>. Others focussed on Black people
              in general: <a href="https://www.blackvalley.co.uk/" >Black Valley</a> and <a href="https://ukblacktech.com/services/" >UK Black Tech</a>.
            </p>
          </Panel>
          <Panel
            header="Applications are closed how do I register my interest?"
            key="9"
          >
            <p>Please fill our Interest form for future cohorts <a href="https://airtable.com/shrw6ZbgonNTa6POH">here </a></p>
          </Panel>
          <Panel header="Employability opportunities " key="10">
            <p>Please join our WhatsApp community <a href="https://chat.whatsapp.com/BikOghd2Wi490bHs3J8ENq">here and join</a> the <b>#jobboard channel</b>
</p>
          </Panel>

          <Panel header="Opportunities for men " key="11">
            <p>Here are some initiatives worth looking into:<br /><br />

            <li><a href="https://www.codeyourfuture.io/">CodeYourFuture</a></li>
            <li><a href="https://bootcamp.birmingham.ac.uk/coding/">University of Birmingham coding bootcamp (scholarships available)</a></li>
              <li><a href="https://www.schoolofcode.co.uk/">School of Code</a></li>
              


            </p>
            
          </Panel>

       
        </Collapse>
      </Container>
      <SpaceMedium />
    </>
  );
};
export default FAQs;
