import React, { useMemo, useState } from "react";
import RegistrationContainer from "../style";
import { Spin } from "antd";
import {
  Container,
  Heading,
  CardParagraph,
  ButtonSpace,
  InputField,
  SelectField,
  CustomButton,
  CustomDiv,
} from "../../../styles/StyledComponents";

import { useNavigate } from "react-router-dom";
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  FormTimelineImages,
  UploadButtons,
  MediaMethods,
  MyCheckBoxList,
  BootcampWindow,
  applyingPrior,
  HoursWorked,
} from "../../../constants";

import ImageUploading from "react-images-uploading";

import { useEffect } from "react";

const FurtherInformation = ({
  fieldData,
  setRegData,
  regData,
  bootcamp,
  values,
  handleChange,
  errors,
  error,
  touched,
  techInterest,
  setTechInterest,
  handleSubmit,
  loading,
  disable,
}) => {
  const navigate = useNavigate();

  const elgibileData = fieldData !== null ? fieldData : "";

  const [eligibile, setEligibile] = useState(elgibileData?.eligibleToWorkUk);

  const [files, setFiles] = useState(null);

  const maxNumber = 69;

  const onChangeImage = (imageList, addUpdateIndex) => {
    setRegData({ ...regData, workEligibilityProof: imageList[0].file });
    setFiles(imageList[0].file);
  };

  const onErrorImage = (errors, files) => {
    // toast.error("The maximum file upload is 5MB", {
    //   position: toast.POSITION.TOP_CENTER,
    // });
  };

  const [data, setData] = useState(
    MyCheckBoxList.sort((a, b) => a.order - b.order)
  );

  const isVerified = useMemo(() => {
    return data.every((d) => d.checked);
  }, [data]);

  useEffect(() => {
    setRegData({ ...regData, eligibleToWorkUk: elgibileData?.eligibleToWorkUk });
  },[])

  const Checkbox = ({ obj, onChange }) => {
    return (
      <>
        <input
          type="checkbox"
          id={`custom-checkbox-${obj.index}`}
          name={obj.name}
          checked={obj.checked}
          onChange={() => onChange({ ...obj, checked: !obj.checked })}
        />
        {obj.name}
      </>
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <RegistrationContainer className="accordion-item">
        <AccordionItemHeading className="accordion-heading">
          <AccordionItemButton className="accordion-click">
            <Container id="form-section-two" className="form-section-two">
              <Container className="form-container">
                <Container className="form-column form-column-1">
                  <img
                    src={FormTimelineImages[1]}
                    className="number"
                    alt="timeline"
                  />

                  <div className="timeline timeline-1"></div>
                </Container>

                <Container className="form-column form-column-2">
                  <Heading className="form-heading">
                    Additional Information
                  </Heading>
                  <CardParagraph></CardParagraph>
                </Container>

                <Container className="form-column form-column-3"></Container>
              </Container>
            </Container>
          </AccordionItemButton>
        </AccordionItemHeading>

        <AccordionItemPanel className="accordion-item-panel">
          <Container className="form-container">
            <Container className="form-column form-column-1">
              <div className="timeline-1"></div>
            </Container>

            <Container className="form-column form-column-2 errorHandle">
              {/* <label>
                Please upload a proof of work in uk
                <ImageUploading
                  // multiple
                  value={files1}
                  onChange={onChangeImage1}
                  maxNumber={maxNumber}
                  onError={onErrorImage1}
                  allowNonImageType={true}
                  maxFileSize={5000000}
                  dataURLKey="data_url"
                  // acceptType={["jpg"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="form-line form-button-row">
                      <CustomDiv
                        className="upload-button"
                        background="#fff"
                        color="#636969"
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <img
                          className="upload-button button-icon"
                          src={UploadButtons[0]}
                          alt="attach icon"
                        />
                        <i>Attach file</i>
                      </CustomDiv>
                      <p className="upload-paragraph">or</p>
                      <CustomDiv
                        // id="upload-button"
                        className="upload-button"
                        background="#fff"
                        color="#636969"
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <img
                          className="button-icon"
                          src={UploadButtons[1]}
                          alt="Drop File Icon"
                        />
                        <i>Drop file here</i>
                      </CustomDiv>
                      &nbsp;
                      {files1 !== null && (
                        <p className="upload-paragraph">
                          File Uploaded Succesfully
                        </p>
                      )}
                    </div>
                  )}
                </ImageUploading>
              </label> */}

              <>
{/*                 
                <label>
                  Are you eligible to work in the United Kingdom?
                  <SelectField
                    name="location"
                    onChange={(e) => {
                      setRegData({
                        ...regData,
                        eligibleToWorkUk: e.target.value,
                      });
                    }}
                  >
                    <option selected disabled value="">
                      Please select an option
                    </option>
                    <option value={eligibile || "yes"}>{eligibile || "yes"}</option>
                    <option value={eligibile || "no"}>{eligibile || "no"}</option>
                  </SelectField>
                </label> */}

              
              </>

              {/* <label>
                What best describes your employment status before you applied to
                this Bootcamp?
                <InputField
                  name="employmentDetails"
                  value={values.employmentDetails}
                  onChange={handleChange}
                  className={
                    errors.employmentDetails && touched.employmentDetails
                      ? "err"
                      : "input-field"
                  }
                  placeholder="What is your employment status?"
                ></InputField>
                {errors.employmentDetails && touched.employmentDetails && (
                  <h4>employmentDetails is Required</h4>
                )}
              </label> */}

              {/* <label>
                What is your estimated current salary (GBP)?   */}

              {/* </label> */}

              <label>
                What is your current salary type?
                <SelectField
                  name="currentSalaryType"
                  value={values.currentSalaryType}
                  onChange={handleChange}
                  className={
                    errors.currentSalaryType && touched.currentSalaryType
                      ? "err"
                      : ""
                  }
                >
                  <option selected disabled value="">
                    Please provide figure as either:
                  </option>
                  <option>weekly</option>
                  <option>yearly</option>
                  <option>hourly</option>
                </SelectField>
                {errors.currentSalaryType && touched.currentSalaryType && (
                  <h4>currentSalaryType is Required</h4>
                )}
              </label>

              <div>
                <label>
                  Attach Proof of Work eligibility.
                  <ImageUploading
                    // multiple
                    value={files}
                    onChange={onChangeImage}
                    maxNumber={maxNumber}
                    onError={onErrorImage}
                    allowNonImageType={true}
                    maxFileSize={5000000}
                    dataURLKey="data_url"
                  // acceptType={["jpg"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="form-line form-button-row">
                        <CustomDiv
                          className="upload-button"
                          background="#fff"
                          color="#636969"
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <img
                            className="upload-button button-icon"
                            src={UploadButtons[0]}
                            alt="attach icon"
                          />
                          <i>Attach file</i>
                        </CustomDiv>
                        <p className="upload-paragraph">or</p>
                        <CustomDiv
                          id="upload-button"
                          className="upload-button"
                          background="#fff"
                          color="#636969"
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <img
                            className="button-icon"
                            src={UploadButtons[1]}
                            alt="Drop File Icon"
                          />
                          <i>Drop file here</i>
                        </CustomDiv>
                        &nbsp;
                        {files !== null && (
                          <p className="upload-paragraph">
                            File Uploaded Succesfully
                          </p>
                        )}
                      </div>
                    )}
                  </ImageUploading>
                </label>
              </div>

              <div className="form-line form-double">
                {/* <label>
                  Prior to applying for the Skills Bootcamp, how many hours per
                  week did you usually work in your job(s)?
                  <InputField
                    name="hoursPerWeek"
                    value={values.hoursPerWeek}
                    onChange={handleChange}
                    className={
                      errors.hoursPerWeek && touched.hoursPerWeek
                        ? "err"
                        : "input-field"
                    }
                    placeholder="How many hours per week"
                  ></InputField>
                  {errors.hoursPerWeek && touched.hoursPerWeek && (
                    <h4>hoursPerWeek is Required</h4>
                  )}
                </label>

 */}

                <label>
                  Prior to applying for the Bootcamp, how many hours per
                  week do you usually work in your job(s)?
                  <SelectField
                    name="hoursPerWeek"
                    className={
                      errors.hoursPerWeek && touched.hoursPerWeek
                        ? 'err'
                        : 'entry'
                    }
                    value={values.hoursPerWeek}
                    onChange={handleChange}
                  // required
                  >
                    <option selected disabled value="">
                      Choose Hours
                    </option>
                    {HoursWorked?.map((item, index) => {
                      return (
                        <>
                          <option key={index} value={item}>
                            {item}
                          </option>
                        </>
                      )
                    })}
                  </SelectField>
                  {errors.hoursPerWeek && touched.hoursPerWeek && (
                    <h4>hoursPerWeek is Required</h4>
                  )}
                </label>

                <label>
                  What is your main job prior to applying for the Skills
                  Bootcamp? (If not currently employed what is your most recent
                  job). Please enter the JOB TITLE below
                  <InputField
                    name="jobTitle"
                    value={values.jobTitle}
                    onChange={handleChange}
                    className={
                      errors.jobTitle && touched.jobTitle
                        ? "err"
                        : "input-field"
                    }
                    placeholder="Please enter the JOB TITLE"
                  ></InputField>
                  {errors.jobTitle && touched.jobTitle && (
                    <h4>jobTitle is Required</h4>
                  )}
                </label>
              </div>

              <div className="form-line form-double">
                <label>
                  What industry did you mainly work for prior to applying for
                  the Skills Bootcamp?
                  <SelectField
                    name="industry"
                    value={values.industry}
                    onChange={handleChange}
                    className={errors.industry && touched.industry ? "err" : ""}
                  >
                    <option selected disabled value="">
                      Please select an option
                    </option>
                    {applyingPrior.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </SelectField>
                  {errors.industry && touched.industry && (
                    <h4>industry is Required</h4>
                  )}
                </label>

                <label>
                  Are you planning to continue working while on the Skills
                  Bootcamp?
                  <SelectField
                    name="continueWorking"
                    value={values.continueWorking}
                    onChange={handleChange}
                    className={
                      errors.continueWorking && touched.continueWorking
                        ? "err"
                        : ""
                    }
                  >
                    <option selected disabled value="">
                      Please select an option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </SelectField>
                  {errors.continueWorking && touched.continueWorking && (
                    <h4>continueWorking is Required</h4>
                  )}
                </label>
              </div>

              <div className="form-line">
                <label>
                  All Applicants/Learners may be asked to take part in
                  qualitative interviews and or surveys to understand your
                  experience of participating in our Bootcamps. This is
                  optional. You can choose to opt out at any time. Do you want
                  to participate?
                  <SelectField
                    name="qualitativeInterviews"
                    value={values.qualitativeInterviews}
                    onChange={handleChange}
                    className={
                      errors.qualitativeInterviews &&
                      touched.qualitativeInterviews
                        ? "err"
                        : ""
                    }
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </SelectField>
                  {errors.qualitativeInterviews &&
                    touched.qualitativeInterviews && (
                      <h4>qualitativeInterviews is Required</h4>
                    )}
                </label>
              </div>

              <div className="form-line form-double">
                <label>
                  Tell us why you are applying for this specific bootcamp
                  <InputField
                    name="reasonForApplying"
                    value={values.reasonForApplying}
                    onChange={handleChange}
                    className={
                      errors.reasonForApplying && touched.reasonForApplying
                        ? "err"
                        : "input-field"
                    }
                    placeholder="Why I'm applying"
                  ></InputField>
                  {errors.reasonForApplying && touched.reasonForApplying && (
                    <h4>reasonForApplying is Required</h4>
                  )}
                </label>

                <label>
                  How will learning on this Bootcamp help you to achieve your
                  career goals?
                  <InputField
                    name="achievingGoalsWithBootcamp"
                    value={values.achievingGoalsWithBootcamp}
                    onChange={handleChange}
                    className={
                      errors.achievingGoalsWithBootcamp &&
                      touched.achievingGoalsWithBootcamp
                        ? "err"
                        : "input-field"
                    }
                    placeholder="Achieving my goals"
                  ></InputField>
                  {errors.achievingGoalsWithBootcamp &&
                    touched.achievingGoalsWithBootcamp && (
                      <h4>achievingGoalsWithBootcamp is Required</h4>
                    )}
                </label>
              </div>

              <div className="form-line">
                <label>
                  Can you confirm you have received and read the documents in
                  the links below?
                  <div className="form-links-container">
                    <CardParagraph className="link-paragraph">
                      1.
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://drive.google.com/file/d/1gStDNnmrxFZpL5ww-4xaxSikDzxthJQc/view"
                      >
                        Department for Education Skills Bootcamp privacy notice
                      </a>
                    </CardParagraph>
                    <CardParagraph className="link-paragraph">
                      2.
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://drive.google.com/file/d/1yg6X31TQQ8LPztBRs3mkmuxtvBDZ4OfL/view?usp=sharing"
                      >
                        Privacy Notice Skills Bootcamp Q&A
                      </a>
                    </CardParagraph>
                    <CardParagraph className="link-paragraph">
                      3.
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://docs.google.com/document/d/1RJodG07oYT2w9gC7JAeupKpRx-1bVLqx/edit?usp=sharing&ouid=116390851948486792889&rtpof=true&sd=true"
                      >
                        Whistleblowing procedure
                      </a>
                    </CardParagraph>
                    <CardParagraph className="radio-line">
                      <input
                        type="checkbox"
                        value={values.readDocuments}
                        onChange={handleChange}
                        className="radio"
                        name="readDocuments"
                      />{" "}
                      Please confirm that you have received all the necessary
                      supporting materials
                    </CardParagraph>
                  </div>
                  {errors.readDocuments && touched.readDocuments && (
                    <h5>readDocuments is Required</h5>
                  )}
                </label>
              </div>

              <div className="form-line">
                <label>
                  Please Check the box below if you agree with our Data Sharing
                  statement
                  <div className="form-links-container">
                    <CardParagraph className="link-paragraph">
                      West Midlands Combined Authority (WMCA) and NIYO Group are
                      working together to provide the bootcamp you are applying
                      for.
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.niyo.co/"
                      >
                        Click here
                      </a>
                    </CardParagraph>
                    <CardParagraph className="radio-line">
                      <input
                        type="checkbox"
                        // value={regData.addToMailingList}
                        // onChange={(e)=> { setRegData({...regData, addToMailingList: true}) }}
                        className="radio"
                        // id="confirm-documents-read"
                      />{" "}
                      Please confirm that you have received all the necessary
                      supporting materials
                    </CardParagraph>
                  </div>
                </label>
              </div>

              <div className="form-line">
                <label>
                  Be added to our mailing list?
                  <div className="form-links-container">
                    <CardParagraph className="radio-line">
                      <input
                        type="checkbox"
                        value={values.addToMailingList}
                        onChange={handleChange}
                        className="radio"
                        name="addToMailingList"
                      />
                      You will opt-in for weekly update emails about the 
                      talents in the NIYO ecosystem.
                    </CardParagraph>
                  </div>
                  {errors.addToMailingList && touched.addToMailingList && (
                    <h5>addToMailingList is Required</h5>
                  )}
                </label>
              </div>

              <div className="form-line form-double">
                <label>
                  Where did you hear about this opportunity?
                  {regData.whereDidYouHearAbtBootCamp === "Others" ? (
                    <InputField
                      type="text"
                      autoFocus
                      placeholder="Specific Where in Particular"
                      className="entry"
                      value={regData.whereDidYouHearAbtBootCampOthers}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          whereDidYouHearAbtBootCampOthers: e.target.value,
                        });
                      }}
                    ></InputField>
                  ) : (
                    <SelectField
                      id="where-heard"
                      defaultValue="where heard"
                      value={regData.whereDidYouHearAbtBootCamp}
                      onChange={(e) => {
                        setRegData({
                          ...regData,
                          whereDidYouHearAbtBootCamp: e.target.value,
                        });
                      }}
                    >
                      <option selected>Select your option</option>
                      {MediaMethods.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </SelectField>
                  )}
                </label>

                <label>
                  Preferred Start Month
                  <SelectField
                    name="dataWindow"
                    value={values.dataWindow}
                    onChange={handleChange}
                    className={
                      errors.dataWindow && touched.dataWindow ? "err" : ""
                    }
                    // defaultValue="bootcamp window"
                  >
                    <option selected>Select your option</option>
                    {BootcampWindow.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </SelectField>
                  {errors.dataWindow && touched.dataWindow && (
                    <h5>start Month is Required</h5>
                  )}
                </label>
              </div>

              <div className="form-line">
                <CardParagraph className="radio-line">
                  <input
                    type="checkbox"
                    className="radio"
                    value={values.sendCopyResponse}
                    onChange={handleChange}
                    name="sendCopyResponse"
                  />
                  Email me a copy of my responses
                </CardParagraph>
                {errors.sendCopyResponse && (
                  <h5>sendCopyResponse is Required</h5>
                )}
              </div>
              {/* 
              <div className="form-line">
                <label>
                  Why are you interested in starting a Tech career
                  <div>
                    {data.map((obj, index) => (
                      <div>
                        <Checkbox
                          obj={obj}
                          onChange={(item) => {
                            item?.checked
                              ? setTechInterest([...techInterest, item.name])
                              : setTechInterest(
                                  techInterest.filter(
                                    (interest) => interest !== item.name
                                  )
                                );

                            setData(
                              data.map((d) =>
                                d.order === item.order ? item : d
                              )
                            );
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </label>
              </div> */}

              <div className="form-line">
                <label>
                  If you are successful, what day of the week will you prefer to
                  have your assessment center.
                  <br />
                  We shall use the most preferred day of the week and
                  communicate the same to all successful applicants
                  <SelectField
                    name="preferredAssessmentCenterDay"
                    value={values.preferredAssessmentCenterDay}
                    onChange={handleChange}
                    className={
                      errors.preferredAssessmentCenterDay &&
                      touched.preferredAssessmentCenterDay
                        ? "err"
                        : ""
                    }
                    // defaultValue="participate"
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    <option value="Wednessday">Wednessday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                  </SelectField>
                  {errors.preferredAssessmentCenterDay &&
                    touched.preferredAssessmentCenterDay && (
                      <h4>preferredAssessmentCenterDay is Required</h4>
                    )}
                </label>
              </div>

              <div className="button-container">
                <CustomButton
                  width="160px"
                  type="button"
                  className={`${disable ? "disabled" : "mobile-button"}`}
                  onClick={() => handleSubmit()}
                >
                  {loading ? <Spin spinning /> : "Submit"}
                </CustomButton>
              </div>
            </Container>

            {/* <Container className="form-column form-column-3"></Container> */}
          </Container>

          <ButtonSpace />
        </AccordionItemPanel>
      </RegistrationContainer>
    </>
  );
};

export default FurtherInformation;
