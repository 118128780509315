import { https } from "../utils/http.js";

class JobCareerService {
  constructor(https) {
    this.request = https;
  }

  async getAllJobCareers(
    { jobType, jobLocation, jobLevel }
  ) {
    if (jobType && jobLocation && jobLevel) {
      return await this.request.get(`careers?jobType=${jobType}&jobLevel=${jobLevel}&jobLocation=${jobLocation}`);
    }
    if (jobType && jobLocation) {
      return await this.request.get(`careers?jobType=${jobType}&jobLocation=${jobLocation}`); 
    }
    if (jobLocation  && jobLevel) {
      return await this.request.get(`careers?jobLocation=${jobLocation}&jobLevel=${jobLevel}`);
    }
    if (jobType && jobLevel) {
      return await this.request.get(`careers?jobType=${jobType}&jobLevel=${jobLevel}`);
    }
    if (jobLocation) {
      return await this.request.get(`careers?jobLocation=${jobLocation}`);
    }
    if (jobLevel) {
      return await this.request.get(`careers?jobLevel=${jobLevel}`);
    }
    if (jobType) {
      return await this.request.get(`careers?jobType=${jobType}`);
    }
    if (!jobType && !jobLocation && !jobLevel) {
      return await this.request.get(`careers`);
    }
  
  }

}

export default new JobCareerService(https);
