import styled from "styled-components";
import { partnersImages } from "../../constants";
import breakpointSizes from "../../constants/breakpointSizes";

export const PartnersContainer = styled.div`
  background-color: #fffcf4;

  .button-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    .white-arrow {
      width: 20px;
      margin: 0 0 0 4%;
    }
  }

  .partner-hero {
    height: auto;
    min-height: 100vh;

    .hero-boxes {
      display: flex;
      flex-direction: row;
      padding: 0;
      height: 90%;
      padding: 5% 0 0 0;

      .hero-left {
        width: 56%;
        padding: 0;

        .hero-text {
          width: 80%;
        }
      }

      .hero-right {
        width: 44%;
        padding: 0;
        margin: 0;

        .hero-image {
          background-image: url(${partnersImages.partnerHero});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          height: 500px;
          width: 100%;
          margin: 0 0 0 10%;
        }
      }
    }
  }

  .case-section {
    align-items: center;
    text-align: center;
  }

  .partner-cards {
    display: flex;

    .individual-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 33%;
      margin: 0 1%;
      padding: 0% 2%;

      .card-heading {
        padding: 14% 0 0 0;
        display: flex;
        flex-wrap: wrap;

        .first-half:empty,
        .second-half:empty {
          display: none;
        }

        .key-word {
          padding: 0 8px 0 0;
        }
      }

      .card-button {
        margin: 0 0 80px 0;
      }
    }
  }

  .work-with {
    text-align: center;

    .work-boxes {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      .individual-work-box {
        width: 30%;
        height: 250px;
        margin: 0 3% 2% 0;

        .work-logo {
          height: 50px;
          padding: 0 0 10px 0;
        }

        .work-title {
          font-size: 19px;
          line-height: 24px;
        }

        .work-text {
          padding: 5% 0 0 0;
        }
      }
    }
  }

  .offering-section {
    .offering-section-title {
      text-align: center;
    }

    .offering-cards {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      .individual-offering {
        width: 31%;
        min-height: 350px;
        border: 1px solid #fbaf00;
        margin: 0 2% 2% 0;
        padding: 2%;

        .offering-logo {
          width: 80px;
          padding: 0 0 0 5%;
        }

        .offering-logo-2 {
          margin: 8% 0 0 0;
        }

        .offering-title {
          font-size: 20px;
          line-height: 28px;
          padding: 0 0 0 5%;
        }

        li {
          padding: 0 0 14px 14px;
        }

        li::marker {
          color: #fbaf00;
          content: "■";
          font-size: 11px;
        }
      }
    }
  }

  .ready-section {
    display: flex;
    background-color: #070909;
    height: 300px;

    .ready-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55%;
    }

    .ready-right {
      width: 45%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .partners-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fffcf4;

    .partners-video {
      width: 850px;
      height: 400px;
      align-self: center;
    }

    .logo-container {
      padding: 0;

      .logo-individual {
        width: 160px;
        padding: 10%;
      }
    }
  }

  @media ${breakpointSizes.mobile} {
    .mobile-space {
      height: 40px;
    }

    .mobile-hidden {
      display: none;
    }

    .partner-hero {
      .hero-boxes {
        flex-direction: column;

        .hero-left {
          width: 100%;

          .hero-text {
            width: 100%;
          }
        }

        .hero-right {
          width: 100%;

          .hero-image {
            width: 100%;
            margin: 0;
          }
        }
      }
    }

    .case-section {
      text-align: left;

      .sc-bczRLJ,
      .jSFAQK {
        width: 100%;
      }
    }

    .partner-cards {
      flex-direction: column;

      .individual-card {
        width: 100%;
        padding: 0 4%;
        margin: 0 0 10% 0;

        .card-button {
          margin: 0 0 40px 0;
        }

        .heading-wrapper {
          h1 {
            padding: 0;
          }

          .first-half,
          .second-half,
          .key-word {
            font-size: 30px;
          }

          .first-half {
            padding: 0 2% 0 0;
          }
        }
      }
    }

    .work-with {
      .mobile-space {
        height: 40px;
      }

      .work-boxes {
        flex-direction: column;

        .individual-work-box {
          width: 100%;
          margin: 0 0 10% 0;
        }
      }

      .work-heading {
        text-align: left;
      }

      .partner-button {
        align-self: center;
      }
    }

    .offering-section {
      .offering-section-title {
        text-align: left;
        width: 100%;
        font-size: 28px;
        line-height: 32px;
      }

      .offering-cards {
        flex-direction: column;

        .individual-offering {
          width: 100%;
          min-height: 250px;
          margin: 0 0 10% 0;
        }
      }
    }

    .ready-section {
      flex-direction: column;
      text-align: left;

      .ready-left {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 0 20% 0;
      }

      .ready-right {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .partners-section {
      align-items: flex-start;

      br {
        display: none;
      }

      .partners-heading {
        text-align: left;
      }

      .partners-video {
        width: 100%;
        height: auto;
      }

      .partner-button {
        justify-content: left;
        align-items: left;
      }

      .marquee {
        height: 100px;
      }
    }
  }

  @media ${breakpointSizes.desktop} {
    .partner-hero {
      height: 100vh;

      .hero-boxes {
        padding: 5% 0 0 0%;

        .hero-left {
          width: 45%;
        }

        .hero-right {
          width: 55%;

          .hero-image {
            height: 600px;
            width: 100%;
            margin: 0;
          }
        }
      }
    }

    .partner-cards {
      .individual-card {
        .card-heading {
          width: 70%;
        }
      }
    }

    .work-with {
      .work-boxes {
        text-align: center;
        justify-content: center;

        .individual-work-box {
          width: 27%;
        }
      }
    }

    .ready-section {
      height: 400px;

      .ready-left {
        width: 40%;
      }

      .ready-right {
        width: 60%;
        justify-content: flex-end;
      }
    }

    .partners-section {
      .partners-video {
        width: 100%;
        height: 600px;
      }
    }
  }
`;

export const JoinContainer = styled.div`
  .join-title,
  .join-text,
  .join-submit,
  .join-button {
    text-align: left;
  }

  .wrapper {
    margin: 25px 0;
    .p-text {
      padding: 20px 0;
    }
  }
  .body {
    .join-text {
      text-align: left;
    }
  }

  .ant-modal-content {
    padding: 0 !important;
  }
  .form-double {
    div {
      margin-bottom: 20px;
    }
    input {
      width: 100%;
      text-align: left;
    }
  }
  .join-form {
    padding: 0 0px;
    .join-button {
      height: 60px;
      width: 400px;
      padding-left: 20px;
      font-family: "Regular";
      font-size: "16px";
    }

    .join-email {
      border: none;
      width: 100%;
      box-shadow: 2px 4px 14px rgba(99, 105, 105, 0.22);
    }
    .join-submit {
      text-align: center;
    }
  }

  @media ${breakpointSizes.mobile} {
    .body {
      & > div {
        display: block;
        gap: 20px;
      }
    }
    padding: 10%;
    text-align: center;
    .join-form {
      padding: 0;
      .join-button {
        height: 50px;
        width: 100%;
        text-align: left;
        font-family: "Regular";
        font-size: "16px";
      }
    }
    .form-double {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      input {
        width: 100%;
      }
    }
  }

  @media ${breakpointSizes.desktop} {
  }
`;
