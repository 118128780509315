import styled from "styled-components";
import { BootcampImages } from "../../../constants";
import breakpointSizes from "../../../constants/breakpointSizes";

const Web3Container = styled.div`
  background-color: #070909;
  .desktop-hidden {
    display: none;
  }

  .hero-heading,
  .hero-text,
  .start-heading {
    color: #fff;
  }

  .button-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    .white-arrow {
      width: 20px;
      margin: 0 0 0 4%;
    }
  }

  .hero-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: auto;
    background-image: none;
    color: #fff;

    .hero-left {
      width: 52%;
      margin: 0;
      padding: 5% 0 0 0;
    }

    .hero-right {
      width: 48%;
      margin: 0;
      padding: 2% 0 0 3%;
      display: flex;
      justify-content: center;

      .hero-image {
        background-image: url(${BootcampImages.web3Hero});
        background-size: cover;
        width: 500px;
        height: 540px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .cards-section {
    background-color: #070909;

    .web2-span {
      color: #fff;
    }

    .card-container {
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;

      .individual-card {
        padding: 0;
        width: 30%;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 5% 2% 0;

        .card-image {
          width: 100%;
        }

        .card-title {
          font-size: 16px;
          line-height: normal;
          padding: 2% 4%;
        }

        .card-text {
          font-size: 14px;
          padding: 0 4% 4% 4%;
        }

        .card-rating {
          height: 20px;
          width: 150px;
          padding: 0 4%;
        }

        .card-button {
          margin: 0 4% 4% 4%;
        }
      }

      .individual-card:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }

  .coming-soon {
    padding: 4% 0;
  }

  .upskilling {
    display: flex;

    .upskilling-left {
      width: 50%;
      padding: 5% 0;
      margin: 0;
    }

    .upskilling-right {
      width: 50%;
      padding: 5% 2%;
      margin: 0;
    }
  }

  .grads-section {
    .grads-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;

      .grad-card {
        margin: 0;
        width: 32%;
        height: auto;
        padding: 0;

        .grad-image {
          width: 100%;
          padding: 4% 0 0 0;
        }

        .grad-button {
          background-color: #070909;
          color: #fbaf00;
          text-align: left;
          font-size: 14px;
          width: 100%;
          justify-content: left;
          padding: 5% 0 0 0;
          margin: 0;
          font-family: bold;

          .grad-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fbaf00;
            width: 20px;
            margin: 0 0 0 2%;
          }
        }
      }
    }

    .grads-button-container {
      display: flex;
      justify-content: center;
    }
  }

  @media ${breakpointSizes.mobile} {
    .mobile-hidden {
      display: none;
    }

    .desktop-hidden {
      display: block;
    }

    .hero-container {
      flex-direction: column;

      .hero-left {
        width: 100%;
        padding: 0 0 20% 0;

        .hero-preheading {
          display: none;
        }

        .hero-button {
          width: 100%;
        }
      }

      .hero-right {
        width: 100%;
        padding: 0 0 20% 0;

        .hero-image {
          width: 100%;
          height: 400px;
        }
      }
    }

    .upskilling {
      flex-direction: column;

      .upskilling-left {
        width: 100%;
        padding: 20% 0 2% 0;
      }
      .upskilling-right {
        width: 100%;
        padding: 0 0 10% 0;
      }
    }

    .cards-section {
      .coming-soon,
      .card-container {
        display: none;
      }

      .top-heading {
        color: #fff;
      }

      .card-container {
        display: none;
      }

      .mobile-swipe-container {
        display: block;
        width: 100%;
        padding: 6% 0 0 0;

        .individual-card {
          width: 100%;
          height: 580px;

          .card-image {
            width: 100%;
          }

          .card-title {
            font-size: 22px;
            line-height: 26px;
            padding: 6% 0 4% 0;
          }

          .card-rating {
            width: 130px;
          }

          .card-text {
            padding: 6% 0 4% 0;
          }
        }
      }
    }

    .grads-section {
      .mobile-space {
        height: 40px;
      }

      .hidden-space {
        display: none;
      }

      .grads-list {
        flex-direction: column;

        .grad-card {
          width: 100%;
          padding: 10% 0 0 0;

          .grad-heading {
            font-size: 16px;
          }
        }
      }

      .grads-button-container {
        width: 100%;
        padding: 10% 0 0 0;

        .more-button {
          width: 100%;
        }
      }
    }
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export default Web3Container;
