import {
  ButtonSpace,
  CustomButton,
  Heading,
  HeroContainer,
  PreHeading,
  Paragraph,
  SpaceSmall,
  SpaceMedium,
  Container,
} from "../../../styles/StyledComponents";
import { PostJobContainer } from "./style";
import {
  JobPackagesPremium,
  JobPackagesBasic,
  PartnerBrands1,
  PartnerBrands2,
  JobFAQs,
} from "../../../constants";
import Marquee from "react-fast-marquee";
import FAQS from "../../../components/FAQS";
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import { useEffect } from "react";

const PostJob = ({ chosenPackage, setChosenPackage }) => {
  const purchasePackage = (e) => {
    const packageFound =
      JobPackagesBasic.find((item) => item.id === e.target.id) ||
      JobPackagesPremium.find((item) => item.id === e.target.id);

    setChosenPackage(packageFound);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <PostJobContainer>
        <HeroContainer className="hero-section">
          <PreHeading>POST A JOB</PreHeading>
          <SpaceSmall />
          <Heading>
            Find the right employee <br />
            for your brand
          </Heading>
          <SpaceSmall />
          <Paragraph>Choose the package that suits you.</Paragraph>
          <ButtonSpace />

          <Container className="tabs-container">
            <Tabs>
              <Tabs.TabPane
                tab="Premium Package"
                key="premiumTab"
                className="tab-pane"
              >
                <Container className="cards-container">
                  {JobPackagesPremium.map((item, index) => {
                    return (
                      <>
                        <div
                          className="individual-package"
                          key={index}
                          id={item.id}
                        >
                          <div className="card-heading">
                            <img
                              src={item.image}
                              className="job-tag"
                              alt="tag"
                            />
                            <Heading fontSize="20px" lineHeight="32px">
                              Package {item.number}
                            </Heading>
                          </div>

                          <Paragraph fontSize="18px">What you'll get</Paragraph>

                          <ul>
                            {item.list.map((listItem, index) => {
                              return <li key={index}>{listItem}</li>;
                            })}
                          </ul>
                          <SpaceSmall className="line" />

                          <Heading fontSize="34px">£{item.price}</Heading>

                          <SpaceSmall />
                          <Link to="/jobs/details">
                            <CustomButton
                              background="#FFF9EB"
                              color="#FBAF00"
                              onClick={purchasePackage}
                              id={item.id}
                            >
                              Purchase this package
                            </CustomButton>
                          </Link>
                          <SpaceSmall />
                        </div>
                      </>
                    );
                  })}
                </Container>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab="Basic Package"
                key="basicTab"
                className="tab-pane"
              >
                <Container className="cards-container">
                  {JobPackagesBasic.map((item, index) => {
                    return (
                      <>
                        <div className="individual-package" key={index}>
                          <div className="card-heading">
                            <img
                              src={item.image}
                              className="job-tag"
                              alt="tag"
                            />
                            <Heading fontSize="20px" lineHeight="32px">
                              Package {item.number}
                            </Heading>
                          </div>

                          <Paragraph fontSize="18px">What you'll get</Paragraph>

                          <ul>
                            {item.list.map((listItem, index) => {
                              return <li key={index}>{listItem}</li>;
                            })}
                          </ul>
                          <SpaceSmall className="line" />

                          <Heading fontSize="34px">£{item.price}</Heading>

                          <SpaceSmall />
                          <Link to="/jobs/details">
                            <CustomButton
                              background="#FFF9EB"
                              color="#FBAF00"
                              onClick={purchasePackage}
                              id={item.id}
                            >
                              Purchase this package
                            </CustomButton>
                          </Link>
                          <SpaceSmall />
                        </div>
                      </>
                    );
                  })}
                </Container>
              </Tabs.TabPane>
            </Tabs>
          </Container>

          <SpaceMedium />
        </HeroContainer>

        <SpaceSmall />

        <Container className="partners-container">
          <div className="partners-heading">
            <Heading fontSize="35px" lineHeight="38px">
              70+ Companies Have Successfully Employed Talent from our Job
              Platform.
            </Heading>
          </div>

          <Container className="logo-container">
            <Marquee speed="30" gradientWidth="0" pauseOnHover="true">
              {PartnerBrands1.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      src={item.image}
                      className="logo-individual"
                      alt="logo"
                    />
                  </div>
                );
              })}
            </Marquee>

            <Marquee
              direction="right"
              speed="30"
              gradientWidth="0"
              pauseOnHover="true"
            >
              {PartnerBrands2.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      src={item.image}
                      className="logo-individual"
                      alt="logo"
                    />
                  </div>
                );
              })}
            </Marquee>
          </Container>
        </Container>

        <div className="job-faqs">
          <FAQS FAQList={JobFAQs} />
        </div>
      </PostJobContainer>
    </>
  );
};

export default PostJob;
