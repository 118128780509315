import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

export const FooterContainer = styled.div`
  background-color: #140e00;
  height: auto;
  padding: 80px 0 40px 0;
  width: 100%;

  .footer-links {
    display: flex;
    justify-content: space-between;
    h4 {
      color: #fff;
      font-family: Medium;
    }

    .short-bio {
      width: 30%;

      .socials-icon {
        height: 30px;
        padding: 0 4% 0 0;
      }
    }

    .about,
    .connect,
    .company {
      width: 12%;
    }

    .bootcamps {
      width: 18%;
    }

    .links {
      li {
        list-style: none;
        font-size: 14px;
        margin-top: 25px;
        a {
          color: #fff;
          text-decoration: none;
          font-size: 14px;
          transition: 0.2s ease-in-out;
        }
        a:hover {
          color: #fbaf00;
        }
      }
    }
  }

  .copyright {
    color: #fff;
    text-align: center;
  }

  @media ${breakpointSizes.mobile} {
    .mobile-text {
      text-align: left;
    }

    .footer-links {
      flex-direction: column;
      width: 100%;

      .short-bio {
        width: 100%;
      }

      .links {
        width: 100%;

        h4 {
          padding: 20% 0 0 0;
          font-family: Regular;
        }

        li {
          font-family: Light;
        }
      }
    }
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export const HorizontalLine = styled.div`
  width: 65%;
  height: 1px;
  background-color: #313535;
  margin: 80px auto;
`;
