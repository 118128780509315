import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CommunityData, Logos } from "../../constants";
import { Container, CustomButton } from "../../styles/StyledComponents";
import { TopHeader } from "./style";
import { Modal, Button } from "antd";
import JoinFamily from "../../components/JoinFamily";
import styled from "styled-components";
import Community from "../Dropdown/Community";
import Companies from "../Dropdown/Companies";
import { FaAngleDown } from "react-icons/fa";
import MovingText from "react-moving-text";

const Bars = styled.img`
  display: none;
  width: 40px;
  height: 40px;

  @media screen and (max-width: 768px) {
    display: block;

    cursor: pointer;
  }
`;

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuComp, setOpenMenuComp] = useState(false);

  const handleOpenCommunity = () => {
    setOpenMenu(!openMenu);
  };

  const handleOpenCompanies = () => {
    setOpenMenuComp(!openMenuComp);
  };

  const handleMenuOne = () => {
    // do something
    setOpen(false);
  };

  const handleMenuTwo = () => {
    // do something
    setOpen(false);
  };

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const closeMenu = () => {
    setNavbar(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOK = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <TopHeader>
      <div className="info">
        <MovingText
          type="pulse"
          duration="1500ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="infinite"
          fillMode="none"
        >
          <h4>
            We are innovating how learning is done.{" "}
            <a href="https://niyolabs.com ">
              Sign up to the <b>future of education.</b> 👈👈
            </a>{" "}
          </h4>
        </MovingText>
      </div>

      <Container className={navbar ? "navbar active" : "navbar"}>
        <div className="logo">
          <Link to="/">
            <img src={Logos.coloredLogo} alt="Niyo bootcamp colored logo" />
          </Link>
        </div>

        <Bars src={Logos.hamburgerImage} onClick={showSidebar} />

        <div className="nav-links">
          <li>
            <Link to="/" onClick={() => closeMenu()}>
              Home
            </Link>
          </li>

          {/* <li onClick={showCommunity}>
            <Link to="">Community</Link>
          </li> */}

          <Community showModal={showModal} />

          <li>
            <Link to="/our-story" onClick={() => closeMenu()}>
              Our Story
            </Link>
          </li>

          <Companies />

          <Button type="primary" onClick={showModal} className="join-button">
            Join Niyo Family
          </Button>
          <Modal
            open={open}
            onOk={handleOK}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            className="join-modal"
            // bodyStyle={{ height: "100%" }}
            footer={null}
            centered
          >
            <JoinFamily />
          </Modal>

          <Link to="/bootcamps">
            <CustomButton className="nav-button">View Bootcamps</CustomButton>
          </Link>
        </div>
      </Container>

      <div
        className={sidebar ? "nav-menu active" : "nav-menu"}
        onClick={showSidebar}
      >
        <li>
          <Link to="/">Home</Link>
        </li>
        <li onClick={handleOpenCommunity}>
          Community <FaAngleDown />
        </li>

        {openMenu ? (
          <ul className="menu">
            {CommunityData.map((item, key) => {
              return (
                <>
                  <li className="menu-item" key={key}>
                    {key === 0 ? (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          showModal();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {item.buttonText}
                      </a>
                    ) : (
                      <a href={item.externalLink}>{item.buttonText}</a>
                    )}
                  </li>
                </>
              );
            })}
          </ul>
        ) : null}

        <li>
          <Link to="/our-story">Our Story</Link>
        </li>
        <li onClick={handleOpenCompanies}>
          Companies <FaAngleDown />
        </li>

        {openMenuComp ? (
          <ul className="menu">
            <li className="menu-item">
              <Link to="/partners">Partner With us</Link>
            </li>
            <li className="menu-item">
              <Link to="/jobs">Job Board</Link>
            </li>
          </ul>
        ) : null}

        <li>
          <CustomButton
            type="primary"
            onClick={showModal}
            className="hamburger-join button"
            background="#fff"
            color="#070909"
          >
            Join Niyo Family
          </CustomButton>
        </li>

        <li>
          <Link to="/bootcamps">
            <CustomButton>View Bootcamps</CustomButton>
          </Link>
        </li>
      </div>
    </TopHeader>
  );
};

export default Header;
