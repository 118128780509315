import styled from "styled-components";

export const PostJobContainer = styled.div`
  .hero-section {
    min-height: 100vh;
    height: auto;
    background-image: none !important;
    z-index: -10;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .tabs-container {
      width: 100%;

      .ant-tabs-nav-wrap {
        background-color: #fff;
        height: 80px;
        border-radius: 14px;
        box-shadow: 0px 10px 29px rgba(25, 26, 26, 0.09);
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-tabs-tab {
          background-color: #fff;
          color: #313535;
          height: 60px;
          font-family: "Medium";
          width: 190px;
          text-align: center;
          border-radius: 14px;
          display: flex;
          justify-content: center;
        }

        .ant-tabs-tab-active {
          color: #fff !important;
          background-color: #fbaf00;
          border: none;
          text-decoration: none;

          .ant-tabs-tab-btn {
            color: #fff !important;
          }
        }

        .ant-tabs-ink-bar {
          display: none;
        }
      }

      .tab-pane {
        .cards-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0;

          .individual-package {
            width: 30%;
            box-shadow: 0px 16px 41px rgba(251, 175, 0, 0.07);
            background-color: #fff;
            padding: 2%;
            margin: 0;

            .card-heading {
              display: flex;
              align-items: center;

              .job-tag {
                margin: 0 2% 0 0;
                height: 20px;
                width: 20px;
              }
            }

            .line {
              border-top: 1px solid #eaebeb;
              width: 100%;
            }

            ul {
              padding: 6% 0 0 2%;

              li {
                padding: 0 0 10px 5%;
                color: #636969;
              }

              li::marker {
                color: #fbaf00;
                content: "■";
                font-size: 11px;
              }
            }
          }
        }

        .hidden {
          display: none;
        }
      }
    }
  }

  .partners-container {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .partners-heading {
      width: 70%;
      margin: 0 0 5% 15%;
    }

    .logo-container {
      padding: 0;

      .logo-individual {
        width: 160px;
        padding: 10%;
      }
    }
  }

  .job-faqs {
  }
`;
