import styled from 'styled-components'
import breakpointSizes from '../../constants/breakpointSizes'

export const JoinContainer = styled.div`
  .join-title,
  .join-text,
  .join-submit,
  .join-button {
    text-align: left;
  }
 
  .wrapper {
    margin: 25px 0;
    .p-text {
      padding: 20px 0;
    }
  }
  .body {
 
    .join-text {
      text-align: left;
    }
  
  }

  .ant-modal-content {
    padding: 0 !important;
  }
  .form-double {
    
    div{
      margin-bottom:20px;
    }
    input {
      width: 100%;
      text-align: left;
    }
  }
  .join-form {
    padding: 0 50px;
    .join-button {
      height: 60px;
      width: 400px;
      padding-left:20px;
      font-family: 'Regular';
      font-size: '16px';
    }

    .join-email {
      border: none;
      width: 100%;
      box-shadow: 2px 4px 14px rgba(99, 105, 105, 0.22);
    }
    .join-submit{
      text-align: center;
    }
  }

  @media ${breakpointSizes.mobile} {
    .body {
      & > div {
        display: block;
        gap: 20px;
      }
    }
    padding: 10%;
    text-align: center;
    .join-form {
      padding:0;
      .join-button {
        height: 50px;
        width: 100%;
        text-align: left;
        font-family: 'Regular';
        font-size: '16px';
      }
    }
    .form-double {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      input {
        width: 100%;
      }
    }
  }


  @media ${breakpointSizes.desktop} {
  }
`
