import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect } from "react";

import {
  ButtonSpace,
  CardParagraph,
  Container,
  CustomButton,
  Heading,
  HeroContainer,
  Paragraph,
  PreHeading,
  SpaceLarge,
  SpaceMedium,
  SpaceSmall,
} from "../../styles/StyledComponents";
import { OurStoryContainer } from "./style";
import {
  upskillEvents,
  impactStats,
  bootcampTeam,
  newsArticles,
  ourStoryImages,
} from "../../constants";

const OurStory = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <OurStoryContainer>
      <HeroContainer className="story-hero">
        <PreHeading className="mobile-hidden">
          THE STORY OF NIYO BOOTCAMP
        </PreHeading>
        <SpaceSmall />
        <Heading>
          <span>Training Ground </span> for people{" "}
          <br className="mobile-hidden" />  in tech globally
        </Heading>
        <SpaceSmall />
        <Paragraph>
          “We upskill, we support, we get them <br className="mobile-hidden" />{" "}
          employed! - Feel empowered, be empowered”
        </Paragraph>
        <SpaceSmall />
        <div className="image-container mobile-hidden" alt="hero"></div>
      </HeroContainer>

      <SpaceLarge className="mobile-hidden" />
      <SpaceLarge className="mobile-hidden" />
      <SpaceLarge className="hidden-space" />

      <Container className="about-us">
        <Container className="about-left">
          <div className="about-img about-img-1 mobile-hidden"></div>
          <div className="about-img about-img-2"></div>
        </Container>
        <Container className="about-right">
          <Heading>About Us</Heading>
          <SpaceSmall />
          <Paragraph className="about-paragraph">
            We are Niyo Bootcamps, one of the brands under Niyo. Niyo Bootcamps
            is the training ground for the best people in tech and its
            intersections with other high impact industries. We run bootcamps
            focused on tech careers, entrepreneurship, and hair and beauty.
            <br />
            <br />
            Every individual we work with is equipped with the right spirit,
            the right attitude, the right knowledge, understanding, skills and
            wisdom so they can build products and services that change the face
            of culture.
          </Paragraph>
        </Container>
      </Container>

      <Container className="upskilling-section">
        <SpaceMedium />
        <PreHeading>WHAT WE DO</PreHeading>
        <SpaceSmall />
        <Heading>Upskilling people in Tech</Heading>
        <SpaceMedium className="mobile-hidden" />
        <Container className="upskill-cards">
          {upskillEvents.map((item, index) => {
            return (
              <>
                <div className="individual-upskill" key={index}>
                  <img src={item.logo} alt="logo" className="individual-logo" />
                  <Heading className="individual-heading">{item.title}</Heading>
                  <CardParagraph className="individual-paragraph">
                    {item.text}
                  </CardParagraph>
                </div>
              </>
            );
          })}
        </Container>
        <SpaceMedium className="mobile-hidden" />
      </Container>

      <Container className="impact-section">
        <PreHeading>OUR IMPACT & REACH</PreHeading>
        <SpaceSmall />
        <Heading>7000+ Individuals</Heading>
        <SpaceSmall className="mobile-hidden" />
        <Container className="impact-cards-container">
          {impactStats.map((item, index) => {
            return (
              <>
                <div className="individual-stat-card">
                  <img
                    src={item.logo}
                    className="stat-logo"
                    alt="logo"
                    key={index}
                  />
                  <Heading className="stat-heading">{item.number}</Heading>
                  <CardParagraph className="stat-text" fontSize="18px">
                    {item.impact}
                  </CardParagraph>
                </div>
              </>
            );
          })}
        </Container>
        <SpaceMedium />
      </Container>

      <Container className="bootcamp-team">
        <PreHeading>OUR TEAM</PreHeading>
        <SpaceSmall />
        <Heading>The Bootcamp Team</Heading>
        <SpaceMedium />
        <Container className="bootcamp-team-cards">
          {bootcampTeam.map((item, index) => {
            return (
              <>
                <div
                  className="team-member-card"
                  key={index}
                  style={{ backgroundImage: `url(${item.image})` }}
                >
                  <div className="member-bio">
                    <div>
                      <Heading
                        fontSize="18px"
                        lineHeight="22px"
                        className="team-name"
                      >
                        {item.name}
                      </Heading>
                      <CardParagraph className="team-title">
                        {item.title}
                      </CardParagraph>
                    </div>

                    <a href={item.linkedin} rel="noreferrer" target="_blank">
                      <img
                        src={ourStoryImages.linkedin}
                        alt="LinkedIn Logo"
                        className="linkedin-logo"
                      />
                    </a>
                  </div>
                </div>
              </>
            );
          })}
        </Container>
        <SpaceSmall />
      </Container>

      <HeroContainer className="news-container">
        <PreHeading>FEATURED UPDATES</PreHeading>
        <SpaceSmall />
        <Heading>Niyo in the News</Heading>
        <SpaceSmall />
        <Container className="cards-container">
          <Carousel
            swipeable={true}
            responsive={responsive}
            className="news-carousel"
          >
            {newsArticles.map((item, index) => {
              return (
                <>
                  <div className="news-card" key={index}>
                    <img src={item.logo} className="card-logo" alt="logo" />
                    <Heading className="card-headline">{item.headline}</Heading>
                    <SpaceSmall />
                    <CardParagraph className="card-paragraph">
                      {item.text}
                    </CardParagraph>
                    <ButtonSpace />
                    <CustomButton className="press-button">
                      Read More
                    </CustomButton>
                  </div>
                </>
              );
            })}
          </Carousel>
          <SpaceSmall />
        </Container>
      </HeroContainer>
    </OurStoryContainer>
  );
};

export default OurStory;
